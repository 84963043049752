import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@ezteach/_services/user.service';
import { User } from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberPublishingStateEnum,
  ChatLessonMemberRole
} from '@ezteach/api/models/chat-lesson-member-permisson';
import { ChatLessonMemberReactionArgs } from '@ezteach/api/models/lesson/chat-lesson-reaction';
import { ReactionTypeEnum } from '@ezteach/api/models/lesson/reaction-enum';
import { UserRole } from '@ezteach/group-lesson/group-lesson.component';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { ChatLessonMemberPublishingState } from '@ezteach/group-lesson/models/chat-lesson-member-publishing-state';
import { GroupLessonParticipantsOverlayService } from '@ezteach/group-lesson/services/group-lesson-participants-overlay/group-lesson-participants-overlay.service';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonPublishingStateService } from '@ezteach/group-lesson/services/group-lesson-publishing-state/group-lesson-publishing-state.service';
import { GroupLessonReactionService } from '@ezteach/group-lesson/services/group-lesson-reaction-service/group-lesson-reaction.service';
import { GroupLessonSignalrService } from '@ezteach/group-lesson/services/group-lesson-signalr-service/group-lesson-signalr-service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StreamManager } from 'openvidu-browser';
import { Subject, combineLatest, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { VideoViewType } from '../group-lesson-header/group-lesson-header.component';


enum StreamState {
  None,
  Video,
  Screen,
}

@UntilDestroy()
@Component({
  selector: 'ezteach-ov-video-merge',
  templateUrl: './ov-video.component.html',
  styleUrls: ['./ov-video.component.scss'],
})
export class OvVideoMergeComponent implements OnInit, AfterViewInit {
  @Input() height;
  @Input() position = 'absolute';
  @Input() moderator = false;
  @Input() owner = false;
  @Input() speakers: string[];
  @Input() mobileExtendedGrid = false;
  @Input() isMobile = false;
  @Input() isTooltipOpen = false;
  @Input() isOwnerVideo = false;
  @Input() view: VideoViewType;
  @Input() isSpeech: boolean;
  @Input() hasScrollBar: boolean;
  @Input() pinnedMember: ChatLessonMemberClient | undefined;
  @Input() away = false;
  @Input() lazyIniting = false;
  @Input() moderateActionIsVisible = false;
  @Input() memberRoleName: string = '';
  viewType = VideoViewType;

  isChatOpen = this.chatStatus;

  @ViewChild('videoElement') elementRef: ElementRef;
  @ViewChild('blockElement') blockRef: ElementRef;

  isFullScreen = false;
  isMuted = false;
  name: string;
  member: ChatLessonMember;
  videoDisabled = false;
  canProlong = false;
  lessonEnd = false;
  isIOS = false;
  isPortrait = false;

  loading = false;
  delay: number;
  isScreenSharing = false;

  currentUserHandOn = false;

  private _streamManager: StreamManager;
  streamManagerLoaded = false;
  public _memberClient: ChatLessonMemberClient;
  chatLessonMemberRole = ChatLessonMemberRole;
  virtualKeyboardDisplayed = false;
  user: User;

  readonly streamState$ = new Subject<StreamState>();
  readonly streamId$ = new Subject<string>();


  @ViewChild('participantHand') participantHand: ElementRef;

  constructor(
    private groupLessonPermissionService: GroupLessonPermissionService,
    public groupLessonService: GroupLessonService,
    public groupLessonPublishingStateService: GroupLessonPublishingStateService,
    private openViduService: OpenViduService,
    public groupLessonReactionService: GroupLessonReactionService,
    private groupLessonParticipantsOverlayService: GroupLessonParticipantsOverlayService,
    private groupLessonSignalrService: GroupLessonSignalrService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.user = this.userService.userData$.value;
    this.updatePermissionAndState(
      this.groupLessonPublishingStateService.membersState$.value,
      this.groupLessonPermissionService.allLessonMembers$.value,
    );
    this.subscribe();

    this.groupLessonService.timeDuration$.subscribe({
      complete: () => {
        this.lessonEnd = true;
      },
    });

    this.groupLessonService.canProlong$.pipe(untilDestroyed(this)).subscribe(v => {
      this.canProlong = v;
    });

    this.iOS();
    if (this.isOwnerVideo) {
      this.detectOrientation();
      fromEvent(window, 'orientationchange')
        .pipe(
          tap(() => {
            this.detectOrientation();
          }),
        )
        .subscribe();
    }

    fromEvent(window, 'fullscreenchange').subscribe(v => {
      if (!document.fullscreenElement) {
        this.closeFullScreen();
      }
    });

    this.openViduService.publishingRoleChange$
      .pipe(
        untilDestroyed(this),
        tap(() => (this.delay = 1000)),
      )
      .subscribe();

    this.openViduService.screenSharing$
      .pipe(
        untilDestroyed(this),
        filter(x => this._memberClient?.member?.memberId === this.openViduService.memberId
        ),
        tap(v => (this.isScreenSharing = v)),
      )
      .subscribe();

    this.groupLessonReactionService.reactions$
      .pipe(
        untilDestroyed(this),
        tap((x: ChatLessonMemberReactionArgs[]) => {
          this.updateReactions(x);
        }),
      )
      .subscribe();

    this.groupLessonService.virtualKeyboardDisplayed$
      .pipe(
        untilDestroyed(this),
        tap(state => (this.virtualKeyboardDisplayed = state)),
      )
      .subscribe();

    this.groupLessonSignalrService.onChatLessonMemberPublishingStateChanged
      .pipe(
        tap(x => {
          if (x.memberId === this._memberClient?.member?.memberId) {
            const video = x.publishingState.find(s => s.name === ChatLessonMemberPublishingStateEnum.Video);
            const screen = x.publishingState?.find(s => s.name === ChatLessonMemberPublishingStateEnum.Screen);

            if (!video && !screen) {
              this.streamState$.next(StreamState.None);
            }

            if (video || screen) {
              if (video) {
                this.streamState$.next(StreamState.Video);
              } else if (screen) {
                this.streamState$.next(StreamState.Screen);
              }
            }
          }
        }),
      )
      .subscribe();

    // у нас иногда бывает гонка между сигнал что кто-то врубил камеру и пришел стрим опенвиду
    // при этом мы должны отсеивать повторные состояния и повторные streamId
    // см https://ezteach.atlassian.net/jira/software/projects/ETS/boards/14?selectedIssue=ETS-2211
    const ovStreamState$ = this.streamState$.pipe(
      distinctUntilChanged()
    );

    const ovStreamId$ = this.streamId$.pipe(
      distinctUntilChanged(),
      filter((x) => x !== null)
    );

    combineLatest([ovStreamState$, ovStreamId$])
      .pipe(
        distinctUntilChanged(([streamStatePrev, ovStreamPrev], [streamState, ovStream]) => ovStreamPrev === ovStream && streamStatePrev === streamState),
        untilDestroyed(this)
      ).subscribe(([streamState, y1]) => {
        if (streamState !== StreamState.None) {
          this.loading = true;
          const timeoutID = setTimeout(() => {
            this.loading = false;
            clearTimeout(timeoutID);
          }, 1000);
        }
      });

    this.memberRoleName = this.getMemberRoleName(this._memberClient?.member?.role);
  }

  ngAfterViewInit(): void {
    if (this._streamManager && !!this.elementRef) {

      // console.log('_streamManager ', this._streamManager)
      this._streamManager.addVideoElement(this.elementRef.nativeElement);
      this.streamManagerLoaded = true;
    }

    fromEvent(this.elementRef.nativeElement, 'canplay')
      .pipe(untilDestroyed(this))
      .subscribe(_ => (this.loading = false));

    fromEvent(this.elementRef.nativeElement, 'abort')
      .pipe(untilDestroyed(this))
      .subscribe(_ => (this.loading = true));

    fromEvent(this.elementRef.nativeElement, 'emptied')
      .pipe(untilDestroyed(this))
      .subscribe(_ => (this.loading = false));
  }

  get chatStatus() {
    this.isChatOpen = this.groupLessonService.chatOpen$.value;
    return this.groupLessonService.chatOpen$.value;
  }

  @Input()
  set streamManager(streamManager: StreamManager) {
    // console.log('streamManager ', streamManager, this.openViduService, this._memberClient)

    if (streamManager) {
      this.openViduService.speakingWhenMuted$.next(false);
      this.openViduService.enableSpeechDetect$.next(true);

      streamManager.removeAllVideos();
    }

    if (streamManager?.stream?.connection?.stream?.streamId) {
      this.streamId$.next(streamManager?.stream?.connection?.stream?.streamId?.toString());
    }
    if ((!!this.elementRef && !this.delay) || this.videoDisabled) {
      if (streamManager) {
        streamManager && streamManager.addVideoElement(this.elementRef.nativeElement);
      }
    } else {
      const timeoutID = setTimeout(() => {
        if (streamManager) {
          streamManager.removeAllVideos();

          if (this.elementRef?.nativeElement) {
            streamManager?.addVideoElement(this.elementRef.nativeElement);
          } else {
            console.log('addVideoElement error')
          }

          clearTimeout(timeoutID);
        }

      }, this.delay);
    }

    this._streamManager = streamManager;
    this.streamManagerLoaded = true;
  }

  @Input()
  set memberClient(client: ChatLessonMemberClient) {
    if (!client) {
      return;
    }

    this._memberClient = client;


    if (client.stream) {
      console.log('client.stream ', client.stream)
      this.streamManager = client.stream;
    }

    this._memberClient.streamAdded$.subscribe(x => {
      if (x) {
        this.streamManager = x;
      }
    });
    this.setName();
    this.setUser();
    this.updatePermissionAndState(
      this.groupLessonPublishingStateService.membersState$.value,
      this.groupLessonPermissionService.allLessonMembers$.value,
    );
    this.updateReactions(this.groupLessonReactionService.reactions$.value);
  }

  private getMemberRoleName(role: ChatLessonMemberRole): string {
    let name = 'Ученик';
    switch (role) {
      case ChatLessonMemberRole.Owner:
        name = 'Преподаватель';
        break;
      case ChatLessonMemberRole.Moderator:
        name = 'Модератор';
        break;
    }
    return name;
  }

  isSpeaker(speakers) {
    if (this._streamManager && !this.isMuted) {
      return !this.lazyIniting && speakers?.includes(this._streamManager?.stream?.connection?.connectionId);
    }
  }

  subscribe() {
    this.groupLessonPermissionService.allLessonMembers$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.updatePermissionAndState(this.groupLessonPublishingStateService.membersState$.value, x);
          this.setUser();
        }),
      )
      .subscribe();

    this.groupLessonPublishingStateService.membersState$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.setUser();
          this.updatePermissionAndState(x, this.groupLessonPermissionService.allLessonMembers$.value);
        }),
      )
      .subscribe();

    this.groupLessonService.fullScreen$.pipe(untilDestroyed(this)).subscribe(v => {
      this.openFullScreen();
    });
  }

  /* От состояния публикаций и прав формирует статусы видео и аудио */
  updatePermissionAndState(publishingState: ChatLessonMemberPublishingState[], members: ChatLessonMember[]) {
    if (this._memberClient) {
      const memberId = this._memberClient?.member?.memberId;
      const userPublishingState = publishingState.find(x => x.memberId === +memberId);
      const chatLessonMember = members.find(x => x.memberId === +memberId);

      if (userPublishingState && chatLessonMember) {
        // console.log('xxxx updatePermissionAndState');
        const hasVideoPermission = chatLessonMember.publishingPermissions.indexOf(
          ChatLessonMemberPublishingPermission.Video,
        );
        const hasShareScreenPermission = chatLessonMember.publishingPermissions.indexOf(
          ChatLessonMemberPublishingPermission.Screen,
        );
        const hasAudioPermission = chatLessonMember.publishingPermissions.indexOf(
          ChatLessonMemberPublishingPermission.Audio,
        );
        const publishingStateAudio = userPublishingState.currentValue.findIndex(
          x => x.name === ChatLessonMemberPublishingStateEnum.Audio,
        );
        const publishingStateVideo = userPublishingState.currentValue.findIndex(
          x => x.name === ChatLessonMemberPublishingStateEnum.Video,
        );
        const publishingStateScreen = userPublishingState.currentValue.findIndex(
          x => x.name === ChatLessonMemberPublishingStateEnum.Screen,
        );

        if (this.isScreenSharing && hasShareScreenPermission === -1) {
          this.groupLessonService.stopShare(this._memberClient.member.role.toLowerCase() as UserRole);
        }

        this.videoDisabled =
          (hasShareScreenPermission === -1 && hasVideoPermission === -1) ||
          (publishingStateVideo === -1 && publishingStateScreen === -1);

        if (this.videoDisabled) {
          this.videoDisabled = !(this.isScreenSharing && this._memberClient.member.role === ChatLessonMemberRole.Owner);
        }
        this.isMuted = hasAudioPermission === -1 || publishingStateAudio === -1;
      }
    }
  }

  updateReactions(reactions: ChatLessonMemberReactionArgs[]) {
    if (this._memberClient) {
      this.currentUserHandOn =
        reactions.find(
          x => x.byMemberId === this._memberClient.member.memberId && x.type === ReactionTypeEnum.RaiseHand && x.show,
        ) !== undefined;
    }
  }

  setName() {
    if (this._memberClient) {
      this.name = this._memberClient?.member?.name;
    }
  }

  setUser() {
    if (this._memberClient) {
      const memberId = this._memberClient?.member?.memberId;
      this.member = this.groupLessonPermissionService.allLessonMembers$.value.find(x => x.memberId === +memberId);
    }
  }

  iOS() {
    this.isIOS =
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  openFullScreen() {
    this.isFullScreen = true;
    if (!this.isIOS) {
      if (
        (this.pinnedMember?.member?.memberId === this._memberClient?.member?.memberId &&
          this.view !== this.viewType.all) ||
        this.view === this.viewType.all ||
        this.isMobile
      ) {
        this.blockRef.nativeElement.requestFullscreen({ navigationUI: 'show' });
      }
    }
  }

  closeFullScreen() {
    this.isFullScreen = false;
  }

  detectOrientation() {
    if (!this.isIOS) {
      const orientation = !navigator.maxTouchPoints
        ? 'desktop'
        : !window.screen.orientation.angle
          ? 'portrait'
          : 'landscape';
      this.isPortrait = orientation === 'portrait';
    } else {
      this.isPortrait = window.orientation === 0;
    }
  }

  showProlongModal() {
    this.groupLessonService.isProlongModalVisible$.next(true);
  }

  showHandsUser() {
    const origin = this.participantHand;
    this.groupLessonParticipantsOverlayService.open(origin, this.isMobile, true, this.owner, this.isSpeech, {
      backdropClass: 'backdrop-without-background',
    });
  }
}
