import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import {
  GroupLessonParticipantsOverlayService
} from "@ezteach/group-lesson/services/group-lesson-participants-overlay/group-lesson-participants-overlay.service";
import { GroupLessonReactionService } from '@ezteach/group-lesson/services/group-lesson-reaction-service/group-lesson-reaction.service';
import { GroupLessonSettingsService } from '@ezteach/group-lesson/services/group-lesson-settings.service';
import { GroupLessonWaitService } from '@ezteach/group-lesson/services/group-lesson-wait/group-lesson-wait.service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Device } from 'openvidu-browser';
import { tap } from 'rxjs/operators';
import { GroupLessonSettingsComponent } from '../group-lesson-settings/group-lesson-settings.component';

export type MuteType = 'all' | 'none';

export enum AccessTypes {
  all = 'all',
  none = 'none',
}

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-moderate-dropdown',
  templateUrl: './group-lesson-moderate-dropdown.component.html',
  styleUrls: ['./group-lesson-moderate-dropdown.component.scss'],
})
export class GroupLessonModerateDropdownComponent implements OnInit {
  isMobile: boolean;
  isSpeech: boolean;
  @Input() lessonLink: string;
  @Input() inviteLink: string;
  @Input() canVideo: boolean;
  @Input() canAudio: boolean;
  @Input() canShare: boolean;
  @Input() isParticipantsOpen: boolean;
  @Input() userTotalCount: number = null;
  @Input() audioEnabled: boolean;
  @Input() videoEnabled: boolean;
  @Input() isOwner: boolean;
  @Input() shareActive: boolean;
  @Input() isChatOpen: boolean;
  @Input() publisherStateChangingValue: boolean;
  @Input() lazyIniting = false;
  @Output() onMuteChanged = new EventEmitter<AccessTypes>();
  @Output() onVideoStatusChanged = new EventEmitter<AccessTypes>();
  @Output() onAudioClicked = new EventEmitter();
  @Output() onVideoClicked = new EventEmitter();
  @Output() onShareStart = new EventEmitter();
  @Output() onShareStop = new EventEmitter();
  @Output() onHandToggleClicked = new EventEmitter();
  @ViewChild('participantButton') participantButton: ElementRef;

  buttonType = ButtonType;
  isSettingsOpen = false;
  facingMode = 'environment';

  menuOptions = [
    {
      title: 'только у меня',
      value: 'all',
    },
    {
      title: 'всем',
      value: 'none',
    },
  ];
  selectedOption = this.menuOptions[1].value;

  selectedMicrophone = 'none';
  selectedCamera = 'none';
  selectedAudioOutput = 'none';
  microphones: Device[] = [];
  cameras: Device[] = [];
  audioOutputs: MediaDeviceInfo[] = [];

  isOpen = false;
  overlayRef: OverlayRef;
  allMemberVideoEnabled: AccessTypes = AccessTypes.all;
  allMemberAudioEnabled: AccessTypes = AccessTypes.all;

  positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' }, 60, -6),
  ];

  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  readonly tooltipSettingsContent: string = this.translocoService.translate(`Настройки аудио/видео`);

  constructor(
    private groupLessonService: GroupLessonService,
    private openViduService: OpenViduService,
    private groupLessonWaitService: GroupLessonWaitService,
    private groupLessonSettingsService: GroupLessonSettingsService,
    private overlay: Overlay,
    public groupLessonReactionService: GroupLessonReactionService,
    private localStorageService: LocalStorageService,
    private groupLessonParticipantsOverlayService: GroupLessonParticipantsOverlayService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.mediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
      if (this.isMobile) {
        this.openViduService
          .getDevices()
          .pipe(
            untilDestroyed(this),
            tap(devices => {
              if (devices && devices.length > 0) {
                this.microphones = devices.filter(device => device.kind === 'audioinput');
                if (this.microphones.length === 0 || this.microphones[0].deviceId === '') {
                  this.microphones.push(this.openViduService.getNotFoundDevice());
                }
                this.cameras = devices.filter(device => device.kind === 'videoinput');
                if (this.cameras.length === 0 || this.cameras[0].deviceId === '') {
                  this.cameras.push(this.openViduService.getNotFoundDevice());
                }
              } else {
                this.microphones.push(this.openViduService.getNotFoundDevice());
                this.cameras.push(this.openViduService.getNotFoundDevice());
              }
              if (this.localStorageService.get('audioDeviceId')) {
                this.selectedMicrophone = this.localStorageService.get('audioDeviceId');
              } else {
                this.selectedMicrophone = this.microphones[0].deviceId;
              }
              if (this.localStorageService.get('videoDeviceId')) {
                this.selectedCamera = this.localStorageService.get('videoDeviceId');
              } else {
                this.selectedCamera = this.cameras[0].deviceId;
              }
            }),
          )
          .subscribe();

        this.openViduService
          .getAudioOutputDevices()
          .pipe(
            untilDestroyed(this),
            tap(devices => {
              if (devices && devices.length > 0) {
                this.audioOutputs = devices;
              } else {
                this.audioOutputs.push(this.openViduService.getNotFoundMediaDevice());
              }
              if (this.localStorageService.get('audioOutputDeviceId')) {
                this.selectedAudioOutput = this.localStorageService.get('audioOutputDeviceId');
              } else {
                this.selectedAudioOutput = this.audioOutputs[0].deviceId;
              }
            }),
          )
          .subscribe();
      }
    });

    this.groupLessonService.isSpeech.pipe(tap(v => (this.isSpeech = v))).subscribe();

    this.groupLessonService.lessonIsDestroyed
      .pipe(
        tap(x => {
          if (x && this.overlayRef) {
            this.overlayRef.detach();
          }
        }),
      )
      .subscribe();

    if (!this.isMobile) {
      this.openSettings();
    }

  }

  openSettings() {
    this.groupLessonSettingsService.popupIsOpened.next(true);
    const overlayGlobalStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: overlayGlobalStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const settingsPortal = new ComponentPortal(GroupLessonSettingsComponent);
    const settingsRef = this.overlayRef.attach(settingsPortal);

    settingsRef.instance.onCloseBtnClick
      .pipe(
        tap(() => {
          this.overlayRef.dispose();
        }),
      )
      .subscribe();
    this.overlayRef
      .backdropClick()
      .pipe(
        tap(() => {
          this.overlayRef.dispose();
        }),
      )
      .subscribe();

    this.isSettingsOpen = !this.isSettingsOpen;
  }

  openMenu() {
    this.isOpen = !this.isOpen;
    this.groupLessonService.setToolbarDropDownStatus(this.isOpen);
  }

  backdropClick($event) {
    this.isOpen = false;
    this.groupLessonService.setToolbarDropDownStatus(this.isOpen);
  }

  muteAllToggle(): void {
    if (this.allMemberAudioEnabled === AccessTypes.all) {
      this.allMemberAudioEnabled = AccessTypes.none;
    } else {
      this.allMemberAudioEnabled = AccessTypes.all;
    }

    this.onMuteChanged.emit(this.allMemberAudioEnabled);
  }

  isVideoEnabled() {
    return this.allMemberVideoEnabled === AccessTypes.all;
  }

  isAudioEnabled() {
    return this.allMemberAudioEnabled === AccessTypes.all;
  }

  handToggle() {
    this.onHandToggleClicked.emit();
  }

  startShare() {
    this.onShareStart.emit();
  }

  stopShare() {
    this.onShareStop.emit();
  }

  onVideoChangedClick($event) {
    if (this.allMemberVideoEnabled === AccessTypes.all) {
      this.allMemberVideoEnabled = AccessTypes.none;
    } else {
      this.allMemberVideoEnabled = AccessTypes.all;
    }
    this.onVideoStatusChanged.emit(this.allMemberVideoEnabled);
  }

  onSelectMicrophone(value: any) {
    this.openViduService
      .getAudioMediaTrack({ audioSource: value })
      .pipe(
        untilDestroyed(this),
        tap(mediaTrack => {
          if (mediaTrack) {
            this.groupLessonWaitService.audioMediaTrack$.next(mediaTrack);
            this.localStorageService.set('audioDeviceId', value);
          }
        }),
      )
      .subscribe();
  }

  onSelectCamera(value: any) {
    this.openViduService
      .getVideoMediaTrack({ videoSource: value })
      .pipe(
        untilDestroyed(this),
        tap(mediaTrack => {
          if (mediaTrack) {
            this.groupLessonWaitService.videoMediaTrack$.next(mediaTrack);
            this.groupLessonWaitService.setPublisherVideoResolutionByStreamTrack(mediaTrack);
            this.localStorageService.set('videoDeviceId', value);
          }
        }),
      )
      .subscribe();
  }

  onSelectAudioOutput(value: any) {
    if (value) {
      this.groupLessonWaitService.audioOuputDeviceChanged$.next(value);
    }
  }

  get usersCount() {
    return this.userTotalCount;
  }

  showUserList() {
    const origin = this.participantButton;
    this.groupLessonParticipantsOverlayService.open(origin, this.isMobile, false, this.isOwner, this.isSpeech, {
      backdropClass: 'backdrop-without-background',
    });
  }

  switchCamera() {
    this.facingMode = this.facingMode === 'environment' ? 'user' : 'environment';

    const options = {
      video: {
        facingMode: this.facingMode,
      },
    };
    navigator.mediaDevices.getUserMedia(options).then(mediaStream => {
      const mediaStreamTrack = mediaStream.getVideoTracks()[0];
      this.groupLessonWaitService.videoMediaTrack$.next(mediaStreamTrack);
      this.localStorageService.set('videoDeviceId', mediaStreamTrack.getSettings().deviceId);
    });
  }
}
