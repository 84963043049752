<div class="modal__header">
  <div class="modal__icon">
    <img src="/assets/img/svg/icon-smile-bad.svg" />
  </div>
</div>
<div class="modal__content modal__content--icon">
  <div
    class="modal__title"
    i18n
  >Вас удалили из звонка</div>
  <div
    class="modal__text"
    i18n
  >
    <p>К сожалению преподаватель решил исключить вас с занятия.</p>
  </div>
  <div class="modal__actions">
    <a
      class="btn btn--blue btn--middle"
      (click)="close()"
    >
      <span
        class="btn__label"
        i18n
      >Вернуться на платформу</span>
    </a>
  </div>
  <button
    class="modal__close"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>
