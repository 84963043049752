import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Discipline, NotificationStatusEnum, ScheduledLessonStatusEnum } from '@ezteach/api/models';
import { UserNotificationLesson } from '@ezteach/api/models/user-notification-lesson';
import { AddDurationPipe } from '@ezteach/shared/pipes/add-duration.pipe';

@Component({
  selector: 'ezteach-notification-lesson',
  templateUrl: './notification-lesson.component.html',
  styleUrls: ['./notification-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddDurationPipe],
})
export class NotificationLessonComponent {
  title: string;
  subtitle: string = '';
  isCanRejected: boolean;
  isCanAccepted: boolean;
  isAccepted: boolean;
  isExpired: boolean;
  date: string;
  durationMinutes: number;
  discipline: Discipline | null;
  quickLesson: boolean;
  readonly statusesArchive = [NotificationStatusEnum.Viewed, NotificationStatusEnum.Archived];
  private _item: UserNotificationLesson;
  get item() {
    return this._item;
  }
  @Input()
  set item(item: UserNotificationLesson) {
    this._item = item;
    this.getLessonNotificationData(item);
  }

  @Output() onDelete = new EventEmitter<void>();
  @Output() onReject = new EventEmitter<boolean>();
  @Output() onAccept = new EventEmitter<boolean>();

  constructor(private readonly cdr: ChangeDetectorRef, private readonly addDurationPipe: AddDurationPipe) {}

  private getLessonNotificationData(item: UserNotificationLesson) {
    if (item?.lesson) {
      this.title = this.getTitle(item.notificationTypeName);
      this.subtitle = this.getSubtitle(item.lesson?.statusId);
      this.isCanRejected = this.canRejected(item);
      this.isCanAccepted = this.canAccepted(item);
      this.isAccepted = this.accepted(item.lesson?.statusId);
      this.isExpired = this.expired(
        this.addDurationPipe.transform(item.lesson.startDate, item.lesson.durationMinutes, 'm'),
      );
      this.date = item.lesson.startDate;
      this.durationMinutes = item.lesson.durationMinutes;
      this.discipline = item.lesson.discipline;
      this.quickLesson = false;
    } else {
      this.title = item.title;
      this.isCanRejected = true;
      this.isCanAccepted = true;
      this.isExpired = this.expired(
        this.addDurationPipe.transform(item.quickLesson.createDate, item.quickLesson.durationMinutes, 'm'),
      );
      this.date = item.quickLesson.createDate;
      this.durationMinutes = item.quickLesson.durationMinutes;
      this.discipline = null;
      this.quickLesson = true;
    }
    this.cdr.detectChanges();
  }

  private getTitle(notificationTypeName: string) {
    switch (notificationTypeName) {
      case 'ScheduledLessonCreatedNotification':
        return 'Запрос на занятие';
      case 'ScheduledLessonCanceledNotification':
        return 'Занятие отменено';
      case 'ScheduledLessonAcceptedNotification':
        return 'Занятие подтверждено';
      default:
        return '';
    }
  }

  private getSubtitle(status?: ScheduledLessonStatusEnum): string {
    switch (status) {
      case ScheduledLessonStatusEnum.CanceledByStudent:
      case ScheduledLessonStatusEnum.CanceledByStudentGroupLesson:
        return 'Отменено учеником';
      case ScheduledLessonStatusEnum.CanceledByTutor:
      case ScheduledLessonStatusEnum.CanceledByTutorGroupLesson:
        return 'Отменено преподавателем';
      case ScheduledLessonStatusEnum.LessonFinished:
      case ScheduledLessonStatusEnum.GroupLessonFinished:
        return 'Занятие завершено';
      case ScheduledLessonStatusEnum.LessonStarted:
      case ScheduledLessonStatusEnum.GroupLessonStarted:
        return 'Занятие началось';
      case ScheduledLessonStatusEnum.Accepted:
        return 'Занятие подтверждено';
      default:
        return '';
    }
  }

  private canRejected(item: UserNotificationLesson): boolean {
    const status: ScheduledLessonStatusEnum = item.lesson?.statusId;
    return (
      status &&
      [
        ScheduledLessonStatusEnum.Initiated,
        ScheduledLessonStatusEnum.InitiatedGroupLesson,
        ScheduledLessonStatusEnum.Accepted,
      ].includes(status) &&
      !this.statusesArchive.includes(item.statusId)
    );
  }

  private canAccepted(item: UserNotificationLesson): boolean {
    const status: ScheduledLessonStatusEnum = item.lesson?.statusId;
    return (
      status &&
      [ScheduledLessonStatusEnum.Initiated, ScheduledLessonStatusEnum.InitiatedGroupLesson].includes(status) &&
      !this.statusesArchive.includes(item.statusId)
    );
  }

  private accepted(status?: ScheduledLessonStatusEnum): boolean {
    return status && status === ScheduledLessonStatusEnum.Accepted;
  }

  private expired(ended?: Date): boolean {
    return ended && new Date().getTime() > ended.getTime();
  }
}
