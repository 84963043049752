<div class="notification-item-wrapper">
  <div class="notification-item-header">
    <ng-container [ngSwitch]="color">
      <img
        class="notification-item-header__icon"
        *ngSwitchCase="'transparent'"
        src="assets/img/svg/notifications/icon-blue-bell.svg"
      />
      <img
        *ngSwitchCase="'red'"
        src="assets/img/svg/notifications/icon-red-bell.svg"
      />
      <img
        *ngSwitchCase="'green'"
        src="assets/img/svg/notifications/icon-green-bell.svg"
      />
    </ng-container>
    <div class="notification-item-header-title-wrapper">
      <div class="notification-item-header-title-wrapper__title">{{ title | transloco}}</div>
      <div
        *ngIf="subtitle"
        class="notification-item-header-title-wrapper__subtitle"
      >{{subtitle | transloco}}</div>
    </div>

    <button
      *ngIf="!needUserAction"
      ezteachAntiQuickClick
      (singleClick)="onClose.emit()"
      [debounceValue]="250"
      class="notification-item-header__close"
    >
      <svg-icon-sprite
        [src]="'icon-close'"
        [width]="'16px'"
        [height]="'16px'"
        [viewBox]="'0 0 27 26'"
      ></svg-icon-sprite>
    </button>
  </div>

  <ng-content></ng-content>
</div>
