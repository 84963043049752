<div class="modal__header">
  <div class="modal__icon">
    <img src="/assets/img/svg/icon-smile-bad.svg" />
  </div>
</div>
<div class="modal__content modal__content--icon">
  <div class="modal__title">{{ 'Что-то пошло не так' | transloco }}...</div>
  <div class="modal__text">
    {{ data.error }}. {{ 'Вы можете повторить попытку предварительно проверив данные вашей карты, указать другую карту
    или
    же добавить новую | transloco }}.
  </div>

  <div
    class="modal__cardlist"
    *ngIf="data.cards.length > 0"
  >
    <div class="profile_content__title">>{{ 'Ваши привязанные карты' | transloco }}</div>
    <div class="profile_cardlist">
      <div class="profile_cardlist__wrap">
        <div
          class="profile_card"
          *ngFor="let card of data.cards; let i = index"
        >
          <div class="profile_card__wrap">
            <div class="profile_card__name">{{ card.cardHolderName }}</div>
            <div class="profile_card__type">{{ card.paymentSystemName }}</div>
            <div class="profile_card__num">{{ card.maskedPan }}</div>
            <div class="profile_card__exp">{{ card.expirationFormatted }}</div>
          </div>
          <mat-radio-button
            class="bankcard"
            name="bankcard"
            value="{{ card.id }}"
            [checked]="i === 0"
          >{{ 'Указанная карта для оплаты' | transloco }}</mat-radio-button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal__actions">
    <mat-spinner
      diameter="30"
      class="loader"
      *ngIf="loading"
    ></mat-spinner>
    <a
      class="btn btn--blue-border btn--middle"
      (click)="addBankcard()"
      *ngIf="!loading && data.buttons.addCard"
    >
      <span class="btn__label">{{ 'Добавить новую карту' | transloco }}</span>
    </a>
    <a
      class="btn btn--blue btn--middle"
      (click)="pay(data.item.id)"
      *ngIf="!loading && data.buttons.retryPay"
    >
      <span class="btn__label">{{ 'Повторить попытку' | transloco }}</span>
    </a>
    <a
      class="btn btn--red-border btn--middle"
      (click)="cancel(data.item.id)"
      *ngIf="!loading && data.buttons.cancelLesson"
    >
      <span class="btn__label">{{ 'Отменить занятие' | transloco }}</span>
    </a>
  </div>
</div>
