<div
  style="text-align: center"
  *ngIf="!loaded"
>
  <mat-spinner
    class="loader"
    diameter="30"
  ></mat-spinner>
</div>

<div *ngIf="loaded">
  <div
    [formGroup]="form"
    class="profile_specs"
  >
    <div class="profile_content__title">{{ title | transloco}}:</div>
    <div class="profile__row">
      <mat-form-field>
        <input
          matInput
          type="text"
          [matAutocomplete]="auto"
          [formControl]="disciplineInput"
        />

        <mat-label>{{ 'Предмет' | transloco }} </mat-label>
        <mat-autocomplete
          autoActiveFirstOption
          [displayWith]="getDisciplineValue"
          #auto="matAutocomplete"
          (optionSelected)="addDiscipline($event)"
        >
          <mat-option
            *ngFor="let discipline of filteredDisciplines | async"
            [value]="discipline"
          >
            {{ discipline.name | transloco }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field><br />
    </div>
    <hr />

    <div *ngFor="let d of selectedDisciplines">
      <div class="discipline">
        <div class="discipline__title">{{ d.name | transloco }}</div>
        <div class="discipline__list">
          <label *ngFor="let specialization of d.specializations | filterSpecializationNames">
            <input
              (change)="changeSpecializationIds($event)"
              type="checkbox"
              [value]="specialization.id"
              [checked]="specializationIds.includes(specialization.id)"
            />
            {{ specialization.name | transloco }}
          </label>
        </div>
        <div class="discipline__delete">
          <a (click)="removeDiscipline(d.id)">{{ 'Удалить' | transloco }} </a>
        </div>
        <hr />
      </div>
    </div>
    <div class="profile_content__title">{{ 'Стоимость занятия' | transloco }}:</div>
    <div class="profile_content__cols">
      <div class="profile_content__col">
        <mat-form-field class="profile_content__input">
          <mat-label>{{ ' Быстрое занятие (за 5 минут)' | transloco }}</mat-label>
          <input
            matInput
            digitOnly
            [decimal]="true"
            mask="00099"
            [maxFractionPoints]="2"
            i18n-placeholder
            [placeholder]="('Быстрое занятие (за 5 минут)') | transloco"
            formControlName="instantLesson5MinuteRate"
          />
          <mat-error
            *ngIf="form.get('instantLesson5MinuteRate').errors && form.get('instantLesson5MinuteRate').hasError('lessonMinRate')"
          > {{ 'Минимальный размер суммы 170 рублей' | transloco }} </mat-error>
          <mat-error
            *ngIf="form.get('instantLesson5MinuteRate').errors && form.get('instantLesson5MinuteRate').hasError('lessonMaxRate')"
          > {{ 'Максимальный размер суммы 2500 рублей' | transloco }}</mat-error>
        </mat-form-field>
      </div>
      <div class="profile_content__col">
        <mat-form-field class="profile_content__input">
          <mat-label> {{ 'Классическое занятие (за 45 минут)' | transloco }}</mat-label>
          <input
            matInput
            digitOnly
            [decimal]="true"
            mask="00099"
            [maxFractionPoints]="2"
            [placeholder]="('Классическое занятие (за 45 минут)') | transloco"
            formControlName="scheduledLessonHourlyRate"
          />
          <mat-error *ngIf="
            form.get('scheduledLessonHourlyRate').errors && form.get('scheduledLessonHourlyRate').hasError('lessonMinRate')
          ">{{ 'Минимальный размер суммы 170 рублей' | transloco }}</mat-error>
          <mat-error *ngIf="
              form.get('scheduledLessonHourlyRate').errors && form.get('scheduledLessonHourlyRate').hasError('lessonMaxRate')
            ">{{ 'Максимальный размер суммы 25000 рублей' | transloco }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="profile_content__action">
      <button
        *ngIf="!(saving$ | async); else spinnerTemplate"
        (click)="setProfile()"
        class="btn btn--blue btn--submit"
        [disabled]="form.invalid || !specializationIds.length"
      >
        <span class="btn__label">{{ 'Сохранить изменения' | transloco }} </span>
      </button>
      <ng-template #spinnerTemplate>
        <mat-spinner
          class="loader"
          diameter="30"
        ></mat-spinner>
      </ng-template>
    </div>
  </div>
</div>