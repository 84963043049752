import { Pipe, PipeTransform } from '@angular/core';
import { Optional } from '@ezteach/utils';
import { StreamManager } from 'openvidu-browser';
import { decodeFromBase64, isBase64 } from 'src/utils/base64';

enum Roles {
  Moderator = 'MODERATOR',
  Publisher = 'PUBLISHER',
}

interface ConnectionData {
  userName: string;
  userRole: Roles;
}

const OPENVIDU_DATA_SPLITTER = '%/%';

function decodedNameFromBase64(name: string): string {
  if (isBase64(name)) {
    return decodeFromBase64(name);
  }

  return name;
}

function getConnectionData(data: string): ConnectionData {
  if (data.includes(OPENVIDU_DATA_SPLITTER)) {
    data = data.split(OPENVIDU_DATA_SPLITTER)[0];
  }
  const dataObj = JSON.parse(data)?.clientData;

  if (!!dataObj.userName?.toString()?.length) {
    dataObj.userName = decodedNameFromBase64(dataObj.userName);
  }
  return dataObj;
}

@Pipe({
  name: 'findModerator',
})
export class FindModeratorPipe implements PipeTransform {
  transform(subscriber: StreamManager[]): StreamManager {
    console.log(
      subscriber.find(sub =>
        Optional.of(sub.stream)
          .map(({ connection }) => connection)
          .map(({ data }) => data)
          .map(value => getConnectionData(value).userRole === Roles.Moderator)
          .orElse(false),
      ),
    );
    return subscriber.find(sub =>
      Optional.of(sub.stream)
        .map(({ connection }) => connection)
        .map(({ data }) => data)
        .map(value => getConnectionData(value).userRole === Roles.Moderator)
        .orElse(false),
    );
  }
}
