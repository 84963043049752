import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ButtonModule } from '@ezteach/_components/buttons/button.module';
import { PopupModule } from '@ezteach/_components/popups/popup.module';
import { ProlongModule } from '@ezteach/_components/prolong/prolong.module';
import { GroupLessonChatComponent } from '@ezteach/group-lesson/components/group-lesson-chat/group-lesson-chat.component';
import { MultiTypingAuthorPipe } from '@ezteach/group-lesson/components/group-lesson-chat/multi-typing-author.pipe';
import { GroupLessonHeaderComponent } from '@ezteach/group-lesson/components/group-lesson-header/group-lesson-header.component';
import { GroupLessonMemberMergeComponent } from '@ezteach/group-lesson/components/group-lesson-member/group-lesson-member.component';
import { GroupLessonMemberMergeModule } from '@ezteach/group-lesson/components/group-lesson-member/group-lesson-member.module';
import { GroupLessonOwnerMergeComponent } from '@ezteach/group-lesson/components/group-lesson-owner/group-lesson-owner.component';
import { GroupLessonOwnerModule } from '@ezteach/group-lesson/components/group-lesson-owner/group-lesson-owner.module';
import { GroupLessonParticipantsListComponent } from '@ezteach/group-lesson/components/group-lesson-participants-list/group-lesson-participants-list.component';
import {
  AddHomeworkPopupComponent
} from '@ezteach/group-lesson/components/group-lesson-toolbar/add-homework-popup/add-homework-popup.component';
import { GroupLessonToolbarComponent } from '@ezteach/group-lesson/components/group-lesson-toolbar/group-lesson-toolbar.component';
import { GroupLessonToolbarModule } from '@ezteach/group-lesson/components/group-lesson-toolbar/group-lesson-toolbar.module';
import { PinControlComponent } from '@ezteach/group-lesson/components/pin-control/pin-control.component';
import { VideoListComponent } from '@ezteach/group-lesson/components/video-list/video-list.component';
import { GroupLessonRoutingMergeModule } from '@ezteach/group-lesson/group-lesson.routing.module';
import { ChatMembersSortPipe } from '@ezteach/group-lesson/pipes/chat-members-sort.pipe';
import { TimeDeclarationPipe } from '@ezteach/group-lesson/pipes/time-declaration/time-declaration.pipe';
import { SharedModule } from '@ezteach/shared';
import { SharedDirectivesModule } from '@ezteach/shared/directives';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { WhiteboardAppModule } from '@ezteach/whiteboard/whiteboard-app.module';
import { TuiCarouselModule } from '@taiga-ui/kit';
import { NgCeilPipeModule, NgRangePipeModule } from 'angular-pipes';
import { AngularSplitModule } from 'angular-split';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GroupLessonChatFileComponent } from './components/group-lesson-chat/group-lesson-chat-file/group-lesson-chat-file.component';
import { GroupLessonDialogFirstLoadModule } from './components/group-lesson-dialog-first-load/group-lesson-dialog-first-load.module';
import { GroupLessonModerateDropdownComponent } from './components/group-lesson-moderate-dropdown/group-lesson-moderate-dropdown.component';
import { GroupLessonParticipantsReactionsComponent } from './components/group-lesson-participants-list/group-lesson-participants-reactions/group-lesson-participants-reactions.component';
import { GroupLessonSettingsComponent } from './components/group-lesson-settings/group-lesson-settings.component';
import { GroupLessonSubjectComponent } from './components/group-lesson-subject/group-lesson-subject.component';
import { GroupLessonMergeComponent } from './group-lesson.component';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    SharedModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    GroupLessonRoutingMergeModule,
    GroupLessonMemberMergeModule,
    GroupLessonOwnerModule,
    GroupLessonToolbarModule,
    InfiniteScrollModule,
    NgCeilPipeModule,
    NgRangePipeModule,
    GroupLessonDialogFirstLoadModule,
    PopupModule,
    ButtonModule,
    OverlayModule,
    SharedDirectivesModule,
    AngularSplitModule,
    TuiCarouselModule,
    WhiteboardAppModule,
    ProlongModule,
    TranslocoRootModule,
  ],
  exports: [GroupLessonRoutingMergeModule, TimeDeclarationPipe, GroupLessonChatComponent],
  declarations: [
    GroupLessonMergeComponent,
    GroupLessonOwnerMergeComponent,
    GroupLessonMemberMergeComponent,
    GroupLessonToolbarComponent,
    VideoListComponent,
    GroupLessonHeaderComponent,
    GroupLessonChatComponent,
    GroupLessonChatFileComponent,
    GroupLessonModerateDropdownComponent,
    MultiTypingAuthorPipe,
    TimeDeclarationPipe,
    ChatMembersSortPipe,
    GroupLessonParticipantsListComponent,
    PinControlComponent,
    GroupLessonParticipantsReactionsComponent,
    GroupLessonSettingsComponent,
    GroupLessonSubjectComponent,
    AddHomeworkPopupComponent,
  ],
  providers: [],
})
export class GroupLessonMergeModule { }
