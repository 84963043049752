import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { ChatLessonMemberPublishingStateEnum } from '@ezteach/api/models/chat-lesson-member-permisson';
import { PublisherSpeakingStatusEnum } from '@ezteach/api/models/publisher-speaking-status-enum';
import { environment } from '@ezteach/enviroments';
import { DeviceType } from '@ezteach/group-lesson/components/group-lesson-settings/group-lesson-settings.component';
import { GroupLessonWaitService } from '@ezteach/group-lesson/services/group-lesson-wait/group-lesson-wait.service';
import { ModalLessonMediaAccess } from '@ezteach/modals/media-access/modal-lesson-media-access.component';
import { ModalLessonMediaConstraints } from '@ezteach/modals/media-constraints/modal-lesson-media-constraints.component';
import { ModalLessonMediaInUse } from '@ezteach/modals/media-in-use/modal-lesson-media-in-use.component';
import { ModalScreenSharingAccess } from '@ezteach/modals/screen-sharing/modal-screen-sharing-access.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ConnectionEvent,
  Device,
  OpenVidu,
  OpenViduError,
  Publisher,
  PublisherProperties,
  PublisherSpeakingEvent,
  Session,
  SessionDisconnectedEvent,
  StreamEvent,
  StreamManager,
  Subscriber,
} from 'openvidu-browser';
import { BehaviorSubject, Observable, Subject, Subscription, from, interval } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { decodeFromBase64, encodeToBase64, isBase64 } from 'src/utils/base64';
import { UserRole, UserRoles } from '../group-lesson.component';
import { getStreamData } from '../helpers/stream.data';
import { SessionEvents } from '../models/ov.model';
import { GroupLessonMemberManagerService } from './group-lesson-member-manager.service';
import { GroupLessonPermissionService } from './group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonPublishingStateService } from './group-lesson-publishing-state/group-lesson-publishing-state.service';
import { GroupLessonSignalrService } from './group-lesson-signalr-service/group-lesson-signalr-service';

const OPENVIDU_DATA_SPLITTER = '%/%';
export const MEDIA_DEVICES_AVAILABLE = { video: true, audio: true };
export const MEDIA_DEVICES_UNAVAILABLE = { video: false, audio: false };

export type EasyLearnSubscribers = Publisher | StreamManager;

export type AfterInitCallBack = () => void;

export type publishType = 'video' | 'audio';

export interface IMediaDevicesConstraints {
  video: boolean;
  audio: boolean;
}

const connectionDisconnectedExceptionTimeout: number = 4000;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OpenViduService {
  role: UserRole;
  memberId: number;
  openVidu: OpenVidu;
  session: Session;
  publisher: Publisher; // Local
  screenPublisher: Publisher;
  subscribers: EasyLearnSubscribers[] = []; // Remotes
  owner: Publisher | StreamManager;
  speakerIds: string[] = [];
  members$ = new BehaviorSubject<EasyLearnSubscribers[]>([]);
  publisher$ = new BehaviorSubject<Publisher>(null);
  screenPublisher$ = new BehaviorSubject<Publisher>(null);
  owner$ = new BehaviorSubject<Publisher | StreamManager>(null);
  speakers$ = new BehaviorSubject<string[]>([]);
  bannedMembers$ = new BehaviorSubject<string[]>([]);
  connectionId = '';
  connectionId$ = new Subject<string>();

  audioEnabled = false;
  audioEnabled$ = new BehaviorSubject<boolean>(this.audioEnabled);
  videoEnabled = false;
  videoEnabled$ = new BehaviorSubject<boolean>(this.videoEnabled);
  shareActive = false;
  shareActive$ = new BehaviorSubject<boolean>(false);
  shareDisabled$ = new BehaviorSubject<boolean>(false);
  stopSharingBrowserAccessEvent$ = new Subject<boolean>();
  mediaDevicesConstraints$: BehaviorSubject<IMediaDevicesConstraints> = new BehaviorSubject(MEDIA_DEVICES_AVAILABLE);
  mediaDevicesAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  publishingRoleChange$: Subject<any> = new Subject<any>();
  screenSharing$: Subject<boolean> = new Subject<boolean>();
  speakingWhenMuted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  enableSpeechDetect$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  screenPublish$: Subject<boolean> = new Subject();
  publish$: Subject<any> = new Subject();

  subscription: Subscription = new Subscription();
  sessionEvents: string[] = [];
  publisherProperties: PublisherProperties;

  publisherStateChangingValue$ = new BehaviorSubject<boolean>(false);
  private featureSubscriberPublisherEnabled = true;
  private memberSubscriberPublisherEnabled = false;
  audioContext: AudioContext;
  rawStream: MediaStream;
  stream: MediaStreamAudioSourceNode;
  intervalDone: boolean;
  videoPermission = true;

  lazyVideoPublisher = false;
  lazyAudioPublisher = false;
  isLeavingSession = false;
  sessionInitiated = false;
  videoPemissionDisabled$ = new Subject();
  audioPemissionDisabled$ = new Subject();
  screenPemissionDisabled$ = new Subject();

  lazyIniting$ = new BehaviorSubject<boolean>(false);
  lazyInitingAudioSuccess$ = new Subject<boolean>();
  lazyInitingVideoSuccess$ = new Subject<boolean>();

  reconnecting$: Subject<any> = new Subject();
  reconnected$: Subject<any> = new Subject();
  networkDisconnect$: Subject<any> = new Subject();
  sessionReconnected$: Subject<any> = new Subject();
  private sessionConnected$ = new Subject<void>();

  isReconnecting: boolean = false;

  constructor(
    private groupLessonPermissionService: GroupLessonPermissionService,
    private router: Router,
    private dialog: MatDialog,
    private groupLessonWaitService: GroupLessonWaitService,
    private groupLessonPublishingStateService: GroupLessonPublishingStateService,
    private localStorageService: LocalStorageService,
    private platform: Platform,
    private groupLessonMemberManagerService: GroupLessonMemberManagerService,
    private groupLessonSignalrService: GroupLessonSignalrService,
  ) {

    // явно отключаю проверку на YaBrowser что бы openvidu работал в нем
    if (/YaBrowser/i.test(navigator.userAgent)) {
      (OpenVidu as any).prototype.checkSystemRequirements = () => true;
    }

    this.subscribePermissions();
    this.publisherProperties = {
      audioSource: undefined, // The source of audio. If undefined default microphone
      videoSource: undefined, // The source of video. If undefined default webcam
      publishAudio: this.audioEnabled, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: this.videoEnabled, // Whether you want to start publishing with your video enabled or not
      frameRate: 30, // The frame rate of your video
      insertMode: 'APPEND', // How the video is inserted in the target element 'video-container',
      mirror: false,
    };

    if (this.localStorageService.get('publisherVideoWidth') && this.localStorageService.get('publisherVideoHeight')) {
      this.setPublisherSettings({
        ...this.publisherProperties,
        resolution: `${this.localStorageService.get('publisherVideoWidth')}x${this.localStorageService.get(
          'publisherVideoHeight',
        )}`,
      });
    }

    this.publisher$.pipe(untilDestroyed(this)).subscribe(publisher => {
      this.publisher = publisher;
    });

    this.screenPublisher$.pipe(untilDestroyed(this)).subscribe(publisher => (this.screenPublisher = publisher));

    this.groupLessonWaitService.audioMediaTrack$
      .pipe(
        untilDestroyed(this),
        // запускает переключение track-ов если уже есть активный
        filter(_ => !this.lazyAudioPublisher),
      )
      .subscribe(audioTrack => {
        this.replaceTrackByPublisherType(audioTrack);
      });

    this.groupLessonWaitService.videoMediaTrack$
      .pipe(
        untilDestroyed(this),
        // запускает переключение track-ов если уже есть активный
        filter(_ => !this.lazyVideoPublisher),
      )
      .subscribe(videoTrack => {
        this.replaceTrackByPublisherType(videoTrack);
      });

    this.enableSpeechDetect$
      .pipe(
        untilDestroyed(this),
        debounceTime(5000),
        filter(v => !!this.audioContext),
        tap((v: boolean) => {
          if (v) {
            this.audioContext.resume();
          } else {
            this.audioContext.suspend();
          }
        }),
      )
      .subscribe();
  }

  async initSpeechRecognize(stream: MediaStream) {
    try {
      const analyzer = await this.createAudioProcessor();
      this.stream = this.audioContext.createMediaStreamSource(stream);
      this.stream.connect(analyzer);
      analyzer.connect(this.audioContext.destination);

      interval(1000)
        .pipe(
          untilDestroyed(this),
          tap(() => (this.intervalDone = !this.intervalDone)),
        )
        .subscribe();

      analyzer.port.onmessage = event => {
        this.detectVolume(event);

        if (this.speakingWhenMuted$.value) {
          this.audioContext.suspend();
        }
      };
    } catch (e) {
      console.log('Failed to initialize audio processing', e);
    }
  }

  detectVolume({ data }: MessageEvent): void {
    if (!this.intervalDone) {
      return;
    }

    const SMOOTHING_FACTOR = 0.8;
    let volume = 0;
    const input = data[0];

    if (input.length > 0) {
      const samples = input[0];
      let sum = 0;
      let rms = 0;

      for (let i = 0; i < samples.length; ++i) {
        sum += samples[i] * samples[i];
      }

      rms = Math.sqrt(sum / samples.length);
      volume = Math.max(rms, volume * SMOOTHING_FACTOR);
      if (volume > 0.15) {
        this.speakingWhenMuted$.next(true);
      }
    }
  }

  getSessionConnected(): Observable<void> {
    return this.sessionConnected$.asObservable();
  }

  async createAudioProcessor() {
    if (!this.audioContext) {
      try {
        this.audioContext = new AudioContext();
        await this.audioContext.audioWorklet.addModule('/assets/scripts/audioWorkletProcessor.js');
      } catch (e) {
        return null;
      }
    }
    return new AudioWorkletNode(this.audioContext, 'speech-detect');
  }

  joinSession(token: string, role: UserRole, isPublishing: boolean): void {
    this.sessionReconnected$.next();
    this.role = role;
    this.initPublisher(token, role, isPublishing);
  }

  publishAudio(switchMode: boolean) {
    if (
      this.publisher &&
      this.publisher.stream &&
      this.publisher.stream.getMediaStream() &&
      this.publisher.stream.getMediaStream().getAudioTracks().length > 0
    ) {
      console.log('this.publisher.stream.audioActive ', this.publisher.stream.audioActive)

      if (this.publisher && this.publisher.stream) {
        this.publisher.publishAudio(this.audioEnabled);
      }

      // если выполняются условия то отключаем паблишера от сессии
      if (!this.canSessionPublished() && this.getFeatureSubscriberPublisherEnabled()) {
        if (this.publisher.session) {
          switchMode = true;
          this.session.unpublish(this.publisher);
          this.publisher.session = undefined;
        }
      }
      const timeoutID = setTimeout(
        x => {
          this.publisherStateChangingValue$.next(false);
          clearTimeout(timeoutID);
        },
        switchMode ? 1000 : 10,
      );
    } else {
      const timeoutID = setTimeout(() => {
        this.publishAudio(switchMode);
        clearTimeout(timeoutID);
      }, 100);
    }
  }

  publishVideo(switchMode: boolean) {
    if (
      this.publisher &&
      this.publisher.stream &&
      this.publisher.stream.getMediaStream() &&
      this.publisher.stream.getMediaStream().getVideoTracks().length > 0
    ) {
      if (this.localStorageService.get('videoDeviceId') && this.localStorageService.get('videoDeviceId') !== 'none') {
        this.setPublisherSettings({
          ...this.publisherProperties,
          videoSource: this.localStorageService.get('videoDeviceId'),
        });
      }
      if (this.localStorageService.get('publisherVideoWidth') && this.localStorageService.get('publisherVideoHeight')) {
        this.setPublisherSettings({
          ...this.publisherProperties,
          resolution: `${this.localStorageService.get('publisherVideoWidth')}x${this.localStorageService.get(
            'publisherVideoHeight',
          )}`,
        });
      }

      this.publisher.publishVideo(this.videoEnabled).then(v => {
        this.publish$.next(this.videoEnabled && this.videoPermission);
      });
      // если выполняются условия то отключаем паблишера от сессии
      if (!this.canSessionPublished() && this.getFeatureSubscriberPublisherEnabled()) {
        if (this.publisher.session) {
          switchMode = true;
          this.session.unpublish(this.publisher);
          this.publisher.session = undefined;
        }
      }

      const timeoutID = setTimeout(
        x => {
          this.publisherStateChangingValue$.next(false);
          clearTimeout(timeoutID);
        },
        switchMode ? 1000 : 10,
      );
    } else {
      const timeoutID = setTimeout(() => {
        this.publishVideo(switchMode);
        clearTimeout(timeoutID);
      }, 100);
    }
  }

  sessionPublishVideoWithState() {
    if (this.getFeatureSubscriberPublisherEnabled()) {
      this.publisherStateChangingValue$.next(true);
    }
    this.setSessionPublisherAndPublish('video');
  }

  sessionPublishVideo() {
    this.setSessionPublisherAndPublish('video');
  }

  sessionPublishAudioWithState() {
    if (this.getFeatureSubscriberPublisherEnabled()) {
      this.publisherStateChangingValue$.next(true);
    }
    this.setSessionPublisherAndPublish('audio');
  }

  sessionPublishAudio() {
    this.setSessionPublisherAndPublish('audio');
  }

  setSessionPublisherAndPublish(publishType: publishType) {
    // this.getFeatureSubscriberPublisherEnabled() - тут не нужна проверка тк достаточно canSessionPublished и !this.publisher.session
    // тк если еще нет паблишера, то независимо от включения фичи нужно врубить камеру или звук
    if (this.canSessionPublished() && !this.publisher.session) {
      this.session.publish(this.publisher);
      this.owner$.next(this.publisher);
    }
    if (publishType === 'video') {
      this.publishVideo(this.videoEnabled);
    } else {
      this.publishAudio(this.audioEnabled)
    }
  }

  canSessionPublished() {
    return this.audioEnabled || this.videoEnabled;
  }

  async audioStatusChange(changePublishingStateCallBack?: any): Promise<void> {
    try {
      const newAudioState = !this.audioEnabled;
      console.log(`Changing audio state to: ${newAudioState}`);

      if (newAudioState) {
        this.speakingWhenMuted$.next(false);
        this.enableSpeechDetect$.next(true);
      } else {
        this.enableSpeechDetect$.next(false);
      }

      this.audioEnabled = newAudioState;
      this.groupLessonPermissionService.audioEnabled$.next(this.audioEnabled);
      this.audioEnabled$.next(this.audioEnabled);

      if (this.lazyAudioPublisher) {
        await this.lazyInit(ChatLessonMemberPublishingStateEnum.Audio, changePublishingStateCallBack);
      } else {
        await this.waitPublisherInit(() => {
          this.sessionPublishAudioWithState();
        });
      }

      if (this.screenPublisher) {
        await this.screenPublisher.publishAudio(this.audioEnabled);
      }

      console.log(`Audio state changed successfully to: ${this.audioEnabled}`);
    } catch (error) {
      this.audioEnabled = !this.audioEnabled;

      if (this.audioEnabled) {
        this.speakingWhenMuted$.next(false);
        this.enableSpeechDetect$.next(true);
      } else {
        this.enableSpeechDetect$.next(false);
      }

      this.groupLessonPermissionService.audioEnabled$.next(this.audioEnabled);
      this.audioEnabled$.next(this.audioEnabled);

      console.log(`Ошибка попытки включить или выключить микрофон `, this.audioEnabled, error);
    }
  }

  videoStatusChange() {
    this.videoEnabled = !this.videoEnabled;
    this.groupLessonPermissionService.videoEnabled$.next(this.videoEnabled);
    this.videoEnabled$.next(this.videoEnabled);

    if (this.lazyVideoPublisher || (this.screenPublisher && this.videoEnabled)) {
      this.lazyInit(ChatLessonMemberPublishingStateEnum.Video);
    } else {
      this.waitPublisherInit(this.sessionPublishVideoWithState);
    }
  }

  disableAudio() {
    this.audioEnabled = false;
    this.audioEnabled$.next(this.audioEnabled);
    this.waitPublisherInit(this.sessionPublishAudio);
    if (this.screenPublisher) {
      this.screenPublisher.publishAudio(this.audioEnabled);
    }
  }

  enableAudio() {
    if (
      this.groupLessonPermissionService.audioEnabled$.value &&
      this.mediaDevicesConstraints$.value.audio &&
      !this.audioEnabled
    ) {
      this.audioEnabled = true;
      this.audioEnabled$.next(this.audioEnabled);
      this.waitPublisherInit(this.sessionPublishAudio);
      if (this.screenPublisher) {
        this.screenPublisher.publishAudio(this.audioEnabled);
      }
    }
  }

  addDevicesToStorage(devices: Device[]): void {
    const cameras = devices.filter(device => device.kind === 'videoinput');
    if (cameras.length > 0 && this.isRequirementUpdating(DeviceType.video, devices)) {
      this.setDeviceToLocalStorage(DeviceType.video, cameras[0], ['id', 'label']);
    }

    const microphones = devices.filter(device => device.kind === 'audioinput');
    if (microphones.length > 0 && this.isRequirementUpdating(DeviceType.audio, devices)) {
      this.setDeviceToLocalStorage(DeviceType.audio, microphones[0], ['id']);
    }
  }

  isRequirementUpdating(type: DeviceType, devices: Device[]): boolean {
    const noneId = 'none';
    if (type === DeviceType.video) {
      const videoDeviceId = this.localStorageService.get('videoDeviceId');
      const videoDeviceLabel = this.localStorageService.get('videoDeviceLabel');
      return (
        !videoDeviceId ||
        videoDeviceId === noneId ||
        !videoDeviceLabel ||
        (videoDeviceId && devices?.findIndex(x => x.deviceId === videoDeviceId) === -1)
      );
    }

    const audioDeviceId = this.localStorageService.get('audioDeviceId');
    return (
      !audioDeviceId ||
      audioDeviceId === noneId ||
      (audioDeviceId && devices?.findIndex(x => x.deviceId === audioDeviceId) === -1)
    );
  }

  setDeviceToLocalStorage(type: DeviceType, device: Device, params: string[]): void {
    const deviceString = 'Device';
    params.forEach(p => {
      this.localStorageService.set(
        `${type}${deviceString}${this.capitalizeParam(p)}`,
        this.getDevicePropertyValue(device, p),
      );
    });
  }

  getDevicePropertyValue(device: Device, property: string): string {
    const fieldName = Object.keys(device).find(p => p.toLowerCase().includes(property));
    return device[fieldName];
  }

  capitalizeParam(param: string): string {
    const firstLetterCapitalize = param.charAt(0).toUpperCase();
    const subString = param.slice(1);
    return firstLetterCapitalize + subString;
  }

  async lazyInit(type: ChatLessonMemberPublishingStateEnum, changePublishingStateCallBack?: any) {
    this.lazyIniting$.next(true);

    let videoConstraint: boolean | {} = false;
    const videoEnabled: boolean | MediaTrackConstraints = !this.lazyVideoPublisher || this.videoEnabled;
    if (videoEnabled) {
      const videoSource = this.deviceSource('video');
      if (videoSource) {
        videoConstraint = { deviceId: videoSource };
      } else {
        videoConstraint = true;
      }
    }

    const available: MediaStreamConstraints = {
      video: videoConstraint,
      audio: !this.lazyAudioPublisher || this.audioEnabled,
    };

    navigator.mediaDevices
      .getUserMedia(available)
      .then(x => {
        if (this.videoEnabled) {
          this.groupLessonWaitService.setPublisherVideoResolutionByStream(x);
        }

        if (type === ChatLessonMemberPublishingStateEnum.Audio) {
          this.rawStream = x;
          this.initSpeechRecognize(x);
        }

        this.getDevices()
          .pipe(
            untilDestroyed(this),
            tap(devices => {
              if (devices && devices.length > 0) {
                this.addDevicesToStorage(devices);
              }

              // если паблишера еще нет то создаем его
              if (!this.publisher) {
                if (
                  (type === ChatLessonMemberPublishingStateEnum.Video ||
                    type === ChatLessonMemberPublishingStateEnum.Audio) &&
                  !this.shareActive$.value
                ) {
                  this.initCameraPublisher(this.session, this.role, true);
                }
              } else {
                if (!this.shareActive$.value) {
                  this.session.unpublish(this.publisher);
                  this.initCameraPublisher(this.session, this.role, true, true);
                }
              }

              if (type === ChatLessonMemberPublishingStateEnum.Video && this.shareActive$.value) {
                this.stopScreenSharingFromBrowserEvent(true);
              }

              if (type === ChatLessonMemberPublishingStateEnum.Audio && this.shareActive$.value) {
                this.session.unpublish(this.screenPublisher);
                this.initScreenPublisher(() => { });
              }
            }),
          )
          .subscribe();
      })
      .catch(e => {
        if (type === ChatLessonMemberPublishingStateEnum.Video) {
          this.videoPemissionDisabled$.next();
          if (this.videoEnabled) {
            this.videoEnabled = !this.videoEnabled;
          }
          this.groupLessonPermissionService.videoEnabled$.next(this.videoEnabled);
          this.videoEnabled$.next(this.videoEnabled);
        }
        if (type === ChatLessonMemberPublishingStateEnum.Audio) {
          this.audioPemissionDisabled$.next();
          if (this.audioEnabled) {
            this.audioEnabled = !this.audioEnabled;
          }
          this.groupLessonPermissionService.audioEnabled$.next(this.audioEnabled);
          this.audioEnabled$.next(this.audioEnabled);
        }

        if (type === ChatLessonMemberPublishingStateEnum.Screen) {
          this.screenPemissionDisabled$.next();
          this.screenSharing$.next(false);
        }

        this.lazyIniting$.next(false);
        this.showMediaConstraintsModal();
      })
      .finally(() => {
        if (changePublishingStateCallBack) {
          changePublishingStateCallBack()
        }
      });
  }

  disableVideo() {
    // пермишн отключает трансляцию
    this.videoEnabled = false;
    this.videoEnabled$.next(this.videoEnabled);
    this.waitPublisherInit(this.sessionPublishVideo);
  }

  enableVideo() {
    // пермишн разрешить видео ѝмотрит на состояние пользователѝ
    if (
      this.groupLessonPermissionService.videoEnabled$.value &&
      this.mediaDevicesConstraints$.value.video &&
      !this.videoEnabled
    ) {
      this.videoEnabled = true;
      this.videoEnabled$.next(this.videoEnabled);
      this.waitPublisherInit(this.sessionPublishVideo);
    }
  }

  publishAudioOutputDevice(audioOutputDeviceId: string) {
    if (audioOutputDeviceId) {
      document.querySelectorAll("[id*='remote-video']").forEach(el => {
        // @ts-ignore
        el.setSinkId(audioOutputDeviceId)
          .then(() => {
            console.log(`Success, audio output device attached: ${audioOutputDeviceId}`);
          })
          .catch(error => {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
              errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            console.error(errorMessage);
          });
      });
    }
  }

  public setFeatureSubscriberPublisherEnabled(value: boolean) {
    this.featureSubscriberPublisherEnabled = value;
  }

  public setMemberSubscriberPublisherEnabled(value: boolean) {
    this.memberSubscriberPublisherEnabled = value;
  }

  private getFeatureSubscriberPublisherEnabled(): boolean {
    return this.featureSubscriberPublisherEnabled && this.memberSubscriberPublisherEnabled;
  }

  waitPublisherInit(callbackFn: AfterInitCallBack) {
    if (this.publisher) {
      callbackFn.bind(this)();
    } else {
      const timeoutId = setTimeout(
        function () {
          //@ts-ignore //TODO: РАЗОБРАТЬСЯ И УБРАТЬ IGNORE
          this.waitPublisherInit(callbackFn.bind(this));
          clearTimeout(timeoutId);
        }.bind(this),
        100,
      );
    }
  }

  startShare(changePublishingStateCallBack: any) {
    this.initScreenPublisher(changePublishingStateCallBack);
  }

  stopShare(role: UserRole) {
    this.stopScreenPublisher(role);
  }

  // Переключение паблишер / ѝабѝкрайбер ѝ новым токеном
  updatePublisherSubscriber(token: string, role: UserRole, isPublishing: boolean, updatedPublisherEnd: () => void) {
    this.publishingRoleChange$.next();
    this.initPublisher(token, role, isPublishing, true, updatedPublisherEnd);
  }

  private initPublisher(
    token: string,
    role: UserRole,
    isPublishing: boolean,
    forceUpdate?: boolean,
    updatedPublisherEnd?: () => void,
  ) {
    if (!this.session || forceUpdate) {
      if (this.session) {
        if (this.session && (this.publisher || this.screenPublisher)) {
          try {
            this.session.unpublish(this.publisher || this.screenPublisher);
          } catch (err: any) {
            console.log('initPublisher session.unpublish', err)
          }

        }
        if (this.publisher?.stream) {
          try {
            this.publisher.stream.disposeWebRtcPeer();
            this.publisher.stream.disposeMediaStream();
          } catch {

          }

        }
        if (this.screenPublisher?.stream) {
          try {
            this.screenPublisher.stream.disposeWebRtcPeer();
            this.screenPublisher.stream.disposeMediaStream();
          } catch {

          }

        }
        this.publisher = undefined;
        this.screenPublisher = undefined;
      }

      const clientData = {
        clientData: {
          userName: encodeToBase64(this.groupLessonPermissionService.chatLessonMember$.value?.name ?? ''),
          memberId: this.groupLessonPermissionService.chatLessonMember$.value?.memberId,
          userRole: role,
        },
      };
      this.openVidu = this.createOpenVidu();
      if (this.session) {
        // переподключение к ѝеѝѝии
        this.unSubscribeStreamEvents();
        this.subscribers = [];

        const sessionSwitch = this.openVidu.initSession();
        this.subscribeStreamEvents(sessionSwitch, isPublishing);
        sessionSwitch.connect(token, clientData).then(x => {
          if (isPublishing) {
            this.initCameraPublisher(sessionSwitch, role, isPublishing);
          }
          this.session = sessionSwitch;

          if (updatedPublisherEnd) {
            updatedPublisherEnd();
          }
          this.sessionInitiated = true;
        });
      } else {
        // подключение к ѝеѝѝии
        this.session = this.openVidu.initSession();
        this.subscribeStreamEvents(this.session, isPublishing);
        // подключение к вебсокету
        this.session.connect(token, clientData).then(x => {
          const lazyVideoPublisher = this.shouldLazyInitCameraPublisher();
          const lazyAudioPublisher = this.shouldLazyInitAudioPublisher();

          if (isPublishing && (!lazyVideoPublisher || !lazyAudioPublisher)) {
            if (
              this.localStorageService.get('videoDeviceId') === 'none' ||
              this.localStorageService.get('audioDeviceId') === 'none') {
              this.getDevices()
                .toPromise()
                .then(devices => {
                  let microphones: Device[] = [];
                  let cameras: Device[] = [];
                  if (devices && devices.length > 0) {
                    microphones = devices.filter(device => device.kind === 'audioinput');
                    cameras = devices.filter(device => device.kind === 'videoinput');
                  }
                  if (cameras.length > 0 && this.localStorageService.get('videoDeviceId') === 'none') {
                    this.localStorageService.set('videoDeviceId', cameras[0].deviceId);
                  }
                  if (microphones.length > 0 && this.localStorageService.get('audioDeviceId') === 'none') {
                    this.localStorageService.set('audioDeviceId', microphones[0].deviceId);
                  }

                  this.initCameraPublisher(this.session, role, isPublishing);
                });
            } else {
              this.initCameraPublisher(this.session, role, isPublishing);
            }
          }

          this.sessionInitiated = true;
          this.sessionConnected$.next();
        });
      }
    }
  }

  // если нет устройств и они еще не были запрошены то лучше сделать ленивую инициализацию паблишера
  shouldLazyInitCameraPublisher() {
    if (
      !this.localStorageService.get('videoDeviceId') ||
      this.localStorageService.get('videoDeviceId') === 'none' ||
      !this.groupLessonWaitService.videoEnabled
    ) {
      this.lazyVideoPublisher = true;
      return true;
    }

    return false;
  }

  shouldLazyInitAudioPublisher() {
    if (
      !this.localStorageService.get('audioDeviceId') ||
      this.localStorageService.get('audioDeviceId') === 'none' ||
      !this.groupLessonWaitService.audioEnabled
    ) {
      this.lazyAudioPublisher = true;
      return true;
    }

    return false;
  }

  private initCameraPublisher(session: any, role: UserRole, isPublishing: boolean, republish = false) {
    this.screenSharing$.next(false);
    const withoutMediaAccess = this.mediaDevicesConstraints$.value === MEDIA_DEVICES_UNAVAILABLE;

    if (!isPublishing || withoutMediaAccess) {
      return;
    }
    if (!this.publisher || republish) {
      this.setPublisherSettings({
        ...this.publisherProperties,
        videoSource: (this.videoEnabled || !this.lazyVideoPublisher) && this.deviceSource('video'),
        audioSource: (!this.lazyAudioPublisher || this.audioEnabled) && this.deviceSource('audio'),
        publishAudio: this.audioEnabled,
        publishVideo: this.videoEnabled,
        resolution: `${this.localStorageService.get('publisherVideoWidth')}x${this.localStorageService.get(
          'publisherVideoHeight',
        )}`,
      });
      this.publisher$.next(this.openVidu.initPublisher(null, this.publisherProperties));
    }

    this.connectionId$.next(this.connectionId);
    this.screenPublisher$.next(null);

    this.subscribePublisherEvents(this.publisher);

    //  инициализация паблишера в сессию если включена фича
    if (
      (isPublishing && !this.getFeatureSubscriberPublisherEnabled()) ||
      ((this.audioEnabled || this.videoEnabled) && this.getFeatureSubscriberPublisherEnabled())
    ) {
      session.publish(this.publisher).then(() => {
        if (this.videoEnabled) {
          this.publish$.next(this.videoEnabled && this.videoPermission);
        }
        if (this.lazyIniting$.value) {
          // isAudioPublishing проверяет не было ли уже активно Audio,
          // чтобы повторно не дублировать запрос на бек с таким же состоянием
          const isAudioPublishing =
            this.groupLessonPublishingStateService
              .getMemberState(this.memberId)
              .filter(s => s.name === ChatLessonMemberPublishingStateEnum.Audio).length > 0;
          this.lazyIniting$.next(false);

          if (this.audioEnabled && !isAudioPublishing) {
            this.lazyInitingAudioSuccess$.next();
            this.lazyAudioPublisher = false;
          }
          if (this.videoEnabled) {
            this.lazyInitingVideoSuccess$.next();
            this.lazyVideoPublisher = false;
          }
        }
      });
    } else if (this.lazyIniting$.value) {
      this.lazyIniting$.next(false);
    }

    if (role === UserRoles.Owner) {
      this.owner$.next(this.publisher);
    } else {
      this.subscribers = this.subscribers.filter(
        sub => sub.stream.connection.connectionId !== this.publisher.stream.connection.connectionId,
      );
      this.subscribers.push(this.publisher);
      this.members$.next(this.subscribers);
    }
  }

  deviceSource(deviceType: 'audio' | 'video'): undefined | string {
    const sourceDeviceId = deviceType === 'video' ? 'videoDeviceId' : 'audioDeviceId';

    const source = this.localStorageService.get(sourceDeviceId);
    return source !== 'none' ? source : undefined;
  }

  private subscribePublisherEvents(publisher: Publisher) {
    if (this.publisher) {
      publisher.once('accessDenied', event => {
        navigator.mediaDevices.getUserMedia(this.mediaDevicesConstraints$.value).catch(e => {
          if (this.lazyIniting$.value) {
            this.lazyIniting$.next(false);
          }
          if (e?.message === 'Invalid constraint' || e?.message === 'Requested device not found') {
            this.mediaDevicesAvailable$.next(false);
          }
          if (e?.name === 'NotReadableError') {
            this.showMediaAlreadyUseWarningModal();
          } else {
            if (!this.screenPublisher) {
              this.mediaDevicesConstraints$.next(MEDIA_DEVICES_UNAVAILABLE);
            }
          }
        });
      });
      publisher.once('accessAllowed', event => {
        this.mediaDevicesConstraints$.next(MEDIA_DEVICES_AVAILABLE);
      });
    }
    if (this.screenPublisher) {
      publisher.once('accessAllowed', event => {
        this.delayForCreatedStream();
        this.screenPublisher.stream
          .getMediaStream()
          .getVideoTracks()[0]
          .addEventListener('ended', () => {
            this.stopScreenSharingFromBrowserEvent(true);
            this.delayForCreatedStream();
          });
        this.mediaDevicesConstraints$.next(MEDIA_DEVICES_AVAILABLE);
      });

      publisher.once('accessDenied', (event: OpenViduError) => {
        if (event.message.includes('system')) {
          this.showScreenSharingAccessModal();
        }

        this.screenSharing$.next(false);
        this.delayForCreatedStream();
        this.stopScreenSharingFromBrowserEvent(false, false);
      });
    }
  }

  devicesAvailable(type: 'audio' | 'video'): Promise<boolean> {
    const deviceType = type === 'audio' ? 'audioinput' : 'videoinput';
    this.openVidu = this.openVidu ?? this.createOpenVidu();
    return this.openVidu
      .getDevices()
      .then((devices: Device[]) =>
        devices
          .filter((deviceInfo: Device) => deviceInfo.kind === deviceType)
          .some((deviceInfo: Device) => deviceInfo.label.length > 0),
      );
  }

  async capturingScreen() {
    const getScreenData = await navigator.mediaDevices.getDisplayMedia({
      video: true,
    });
    return getScreenData;
  }

  private async initScreenPublisher(changePublishingStateCallBack: any) {
    if (this.screenPublisher && this.lazyAudioPublisher) {
      return;
    }

    // добавлено для Safari, так как требуется дополнительное подтверждение от пользователя.
    // необходимо чтобы запрашивалось до любого запроса media в методе
    let capturedScreenStream = null;
    if (this.platform.SAFARI) {
      const stream = await this.capturingScreen();
      capturedScreenStream = stream?.getVideoTracks()[0];
    }

    const audioAvailable: boolean = await this.devicesAvailable('audio');

    if (audioAvailable) {
      this.lazyAudioPublisher = false;
    }

    this.screenSharing$.next(true);

    const initSettings: PublisherProperties = {
      publishAudio: this.audioEnabled,
      audioSource: audioAvailable
        ? this.localStorageService.get('audioDeviceId') !== 'none'
          ? this.localStorageService.get('audioDeviceId')
          : undefined
        : false,
      videoSource: capturedScreenStream || 'screen',
      publishVideo: true,
      // @ts-ignore
      publishedOnce: true,
      mirror: false,
    };

    if (this.mediaDevicesConstraints$.value === MEDIA_DEVICES_AVAILABLE) {
      this.setPublisherSettings({
        ...this.publisherProperties,
        ...initSettings,
        publishAudio: this.audioEnabled,
        audioSource: audioAvailable
          ? this.localStorageService.get('audioDeviceId') !== 'none'
            ? this.localStorageService.get('audioDeviceId')
            : undefined
          : false,
      });
    } else {
      this.setPublisherSettings({
        ...initSettings,
      });
    }

    const initCallBack = e => {
      if (!e) {
        changePublishingStateCallBack(ChatLessonMemberPublishingStateEnum.Screen, true);

        if (this.mediaDevicesConstraints$.value === MEDIA_DEVICES_UNAVAILABLE) {
          return;
        }

        if (this.publisher) {
          try {
            this.session.unpublish(this.publisher);
          } catch (err: any) {
            console.log('initCallBack session.unpublish ', err)
          }

        }

        this.publisher$.next(null);
      }
    };
    this.screenPublisher$.next(this.openVidu.initPublisher(null, this.publisherProperties, initCallBack));
    this.connectionId$.next(this.connectionId);
    this.session.publish(this.screenPublisher).then(() => this.screenPublish$.next(true));
    this.shareActive$.next(true);
    this.shareDisabled$.next(true);
    if (this.role === UserRoles.Owner) {
      this.owner$.next(this.screenPublisher);
    }

    this.subscribePublisherEvents(this.screenPublisher);

    /*
    this.screenPublisher.stream.getMediaStream().removeTrack = () => {
      console.log('remove');
    };*/

    /*
    this.screenPublisher.stream.getMediaStream().addEventListener('inactive', () => {
      console.log('User pressed the "Stop sharing" button');
      // You can send a signal with Session.signal method to warn other participants
    });*/

    /* setInterval(x => {
      if (this.screenPublisher.stream.getMediaStream().getVideoTracks()[0]) {
        this.screenPublisher.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', () => {
          console.log('User pressed the "Stop sharing" button');
      });
      }
    }, 1000);
    */
  }

  private stopScreenSharingFromBrowserEvent(withInitCamera: boolean, withNotification = true) {
    this.stopScreenPublisher(this.role, withInitCamera, withNotification);
    this.stopSharingBrowserAccessEvent$.next(true);
  }

  private stopScreenPublisher(role: UserRole, withInitCamera = true, withNotification = true) {
    if (withInitCamera && this.screenPublisher) {
      this.session.unpublish(this.screenPublisher);
    }

    this.shareActive$.next(false);
    this.shareDisabled$.next(true);

    if (role === UserRoles.Owner) {
      this.owner$.next(this.publisher);
    } else {
      if (this.publisher) {
        this.subscribers.push(this.publisher);
        this.members$.next(this.subscribers);
      }
    }

    if (
      withInitCamera &&
      (!this.lazyAudioPublisher || !this.lazyVideoPublisher) &&
      (this.audioEnabled || this.videoEnabled)
    ) {
      this.initCameraPublisher(this.session, role, true);
    } else if (withInitCamera && this.videoEnabled) {
      this.initCameraPublisher(this.session, role, true);
    } else {
      this.screenSharing$.next(false);
    }
    this.delayForCreatedStream();
    this.screenPublisher$.next(null);

    // Session.unpublish возвращает resolved Promise через 2-4 секунды. Для более быстрого отображения
    // уведомления вызываем вручную. Используем setTimeout, потому что какое-то время система
    // считает что трансляция продолжается.
    if (withNotification) {
      const timeoutID = setTimeout(() => {
        this.screenPublish$.next(false);
        clearTimeout(timeoutID);
      }, 1000);
    }

    if (!this.audioEnabled) {
      this.lazyAudioPublisher = true;
    }

    if (!this.videoEnabled) {
      this.lazyVideoPublisher = true;
    }
  }

  delayForCreatedStream() {
    setTimeout(() => {
      this.shareDisabled$.next(false);
    }, 2000);
  }

  private decodedNameFromBase64(name: string): string {
    if (isBase64(name)) {
      return decodeFromBase64(name);
    }

    return name;
  }

  private getStreamDataObjFromEvent(event: StreamEvent) {
    let data = event.stream.connection.data;
    if (data.includes(OPENVIDU_DATA_SPLITTER)) {
      data = data.split(OPENVIDU_DATA_SPLITTER)[0];
      const dataObj = JSON.parse(data);

      if (!!dataObj?.clientData?.userName?.toString()?.length) {
        dataObj.clientData.userName = this.decodedNameFromBase64(dataObj.clientData.userName);
      }
      return dataObj;
    }

    return null;
  }

  public subscribeStreamEvents(session: Session, isPublishing?: boolean) {
    session.on(SessionEvents.streamCreated, (event: any) => {
      const subscriber: Subscriber = session.subscribe(event.stream, undefined);
      this.removeSubscriberIfExists(subscriber);

      const dataObj = this.getStreamDataObjFromEvent(event);
      if (dataObj) {
        if (dataObj?.clientData?.userRole === UserRoles.Owner && this.role !== UserRoles.Owner) {
          this.owner = subscriber;
          this.owner$.next(subscriber);
        } else if (dataObj?.clientData?.userRole !== UserRoles.Owner) {
          this.subscribers.push(subscriber);
          this.members$.next(this.subscribers);
        }
      }
    });
    this.sessionEvents.push(SessionEvents.streamCreated);

    session.on(SessionEvents.streamDestroyed, (event: StreamEvent) => {
      if (!event?.stream?.streamManager) {
        return;
      }

      const dataObj = this.getStreamDataObjFromEvent(event);
      if (dataObj) {
        if (dataObj?.clientData?.userRole !== UserRoles.Owner) {
          this.deleteSubscriber(event.stream.streamManager);
          this.members$.next(this.subscribers);
        }
      }

    });
    this.sessionEvents.push(SessionEvents.streamDestroyed);

    session.on(SessionEvents.connectionDestroyed, (event: ConnectionEvent) => {
      console.log("OTHER USER'S CONNECTION DESTROYED!", event, this.audioEnabled);
    });
    this.sessionEvents.push(SessionEvents.connectionDestroyed);

    session.on(SessionEvents.connectionCreated, (event: ConnectionEvent) => {
      console.log('connectionCreated', this.groupLessonMemberManagerService.memberClients$, event);

    });

    this.sessionEvents.push(SessionEvents.connectionCreated);

    session.on(SessionEvents.publisherStartSpeaking, (event: PublisherSpeakingEvent) => {
      const type = PublisherSpeakingStatusEnum.startSpeaking;
      this.speechDetection(event.connection.connectionId, type);
    });
    this.sessionEvents.push(SessionEvents.publisherStartSpeaking);

    session.on(SessionEvents.publisherStopSpeaking, (event: PublisherSpeakingEvent) => {
      const type = PublisherSpeakingStatusEnum.stopSpeaking;
      this.speechDetection(event.connection.connectionId, type);
    });
    this.sessionEvents.push(SessionEvents.publisherStopSpeaking);


    this.sessionEvents.push(SessionEvents.sessionDisconnected);
    session.on(SessionEvents.sessionDisconnected, (event: SessionDisconnectedEvent) => {
      console.log('event ', event)
      if (event.reason === 'forceDisconnectByUser') {
        this.router.navigate(['']);
      }

      if (event.reason === 'disconnect') {
        console.log('session disconnect', this.session, event)
      }

      if (event.reason === 'networkDisconnect', event) {
        console.log('networkDisconnect leaveSession')

        if (this.isReconnecting) {
          this.isReconnecting = false;
          this.leaveSessionWithRetry();
        }
      }
    });

    this.sessionEvents.push(SessionEvents.exception);

    session.on(SessionEvents.exception, (event: any) => {
      if (event.name === 'ICE_CONNECTION_FAILED') {
        console.log('ICE_CONNECTION_FAILED');
      }
      if (event.name === 'ICE_CONNECTION_DISCONNECTED') {
        this.isReconnecting = true;
        this.reconnecting$.next();
        console.log('ICE_CONNECTION_DISCONNECTED');
      }
    });

    session.on(SessionEvents.reconnecting, (event: any) => {
      console.log('reconnecting...')
      this.isReconnecting = true;
      this.reconnecting$.next();
    });

    this.sessionEvents.push(SessionEvents.reconnected);
    session.on(SessionEvents.reconnected, (event: any) => {
      console.log('reconected!')
      this.isReconnecting = false;
      this.reconnected$.next();
    });
  }

  public unSubscribeStreamEvents() {
    this.sessionEvents.forEach((x: any) => {
      this.session.off(x);
    });
    this.sessionEvents = [];
  }

  public stopAllMediaTracks(): void {
    const stopTrack = (track: MediaStreamTrack) => {
      track.stop();
      track.enabled = false;
    };

    if (this.publisher) {
      this.publisher.stream?.getMediaStream()?.getTracks().forEach(stopTrack);
    }
    if (this.screenPublisher) {
      this.screenPublisher.stream?.getMediaStream()?.getTracks().forEach(stopTrack);
    }
    if (this.rawStream) {
      this.rawStream.getTracks().forEach(stopTrack);
    }

    this.publisher?.stream
      ?.getMediaStream()
      ?.getVideoTracks()
      .forEach(x => {
        x.stop();
        x.enabled = false;
      });


    console.log('this.subscribers ', this.subscribers)
  }

  speechDetection(connectionId, type) {
    let speechIds = this.speakerIds;
    if (type === PublisherSpeakingStatusEnum.startSpeaking) {
      speechIds.push(connectionId);
      this.speakers$.next(speechIds);
    } else if (type === PublisherSpeakingStatusEnum.stopSpeaking) {
      speechIds = speechIds.filter(id => id !== connectionId);
      this.speakers$.next(speechIds);
    }
    this.speakerIds = speechIds;
  }

  public setPublisherSettings(publisherProperties: PublisherProperties) {
    this.publisherProperties = publisherProperties;
  }

  private deleteSubscriber(streamManager: StreamManager): void {
    const index = this.subscribers.indexOf(streamManager, 0);
    if (index > -1) {
      this.subscribers.splice(index, 1);
    }
  }

  private setCurrentPublishingState() {
    if (this.memberId) {
      const memberState = this.groupLessonPublishingStateService.getMemberState(this.memberId);
      if (memberState.findIndex(x => x.name === ChatLessonMemberPublishingStateEnum.Audio) !== -1) {
        this.audioEnabled = true;
      }
      if (memberState.findIndex(x => x.name === ChatLessonMemberPublishingStateEnum.Video) !== -1) {
        this.videoEnabled = true;
      }
    }
  }

  private subscribePermissions() {
    const videoSub = this.groupLessonPermissionService.videoPermission$.subscribe(x => {
      this.videoPermission = x;
      this.setCurrentPublishingState();
      if (!x) {
        this.disableVideo();
      } else if (x && this.videoEnabled) {
        this.enableVideo();
      }
    });
    this.subscription.add(videoSub);

    const audioSub = this.groupLessonPermissionService.audioPermission$.subscribe(x => {
      this.setCurrentPublishingState();
      if (!x) {
        this.disableAudio();
      } else if (x && this.audioEnabled) {
        this.enableAudio();
      }
    });
    this.subscription.add(audioSub);
  }

  private removeSubscriberIfExists(subscriber: Subscriber) {
    const data = getStreamData(subscriber);
    const index = this.subscribers.findIndex(x => getStreamData(x).memberId === data.memberId);
    if (index !== -1) {
      this.subscribers.splice(index, 1);
    }
  }

  banUser(member: StreamManager) {
    this.session.forceDisconnect(member.stream.connection);
  }

  showMediaAccessModal() {
    this.dialog.open(ModalLessonMediaAccess, {
      panelClass: 'modal-lesson-media-access',
      data: {},
    });
  }

  showMediaConstraintsModal() {
    this.dialog.open(ModalLessonMediaConstraints, {
      panelClass: 'modal-lesson-media-constraints',
      data: {},
    });
  }

  showMediaAlreadyUseWarningModal() {
    this.dialog.open(ModalLessonMediaInUse, {
      panelClass: 'modal-lesson-media-in-use',
      data: {},
    });
  }

  showScreenSharingAccessModal() {
    this.dialog.open(ModalScreenSharingAccess, {
      panelClass: 'screen-sharing-access',
      data: {},
    });
  }

  setMediaStateDefault() {
    this.videoEnabled$.next(false);
    this.audioEnabled$.next(false);
    this.shareActive$.next(false);
    this.shareDisabled$.next(false);
    this.videoEnabled = false;
    this.audioEnabled = false;
    this.shareActive = false;
  }

  async leaveSessionWithRetry() {
    const maxRetries = 10;
    let attempts = 0;

    const checkAndLeaveSession = async () => {
      const _isConnected = this.groupLessonSignalrService.isConnected();
      console.log('websocket is connected ', _isConnected);

      if (_isConnected) {
        // вебсокет доступен, выполняем leaveSession
        console.log('try leaveSession ', attempts + 1);
        this.isLeavingSession = false;
        attempts = 10;
        this.leaveSession();

      } else if (attempts < maxRetries) {
        // вебсокет недоступен, увеличиваем счетчик
        attempts++;
        setTimeout(checkAndLeaveSession, 1000);
      } else {
        console.error('Не удалось выполнить leaveSession: вебсокет недоступен');
      }
    };

    checkAndLeaveSession();
  }

  async leaveSession() {
    if (this.isLeavingSession) {
      console.log('Уже выполняется выход из сессии, пропускаем повторный вызов');
      return;
    }

    const _publisher = this.publisher || this.screenPublisher;

    console.log('leaveSession');

    this.isLeavingSession = true;

    this.subscription?.unsubscribe();


    if (_publisher) {
      try {
        _publisher.stream.disposeWebRtcPeer();
        await _publisher.stream.disposeMediaStream();
      } catch (err: any) {
        console.log('disposeMediaStream ', err)
      }
    }

    this.subscribers = [];
    this.members$.next([]);
    this.publisher$.next(null);
    this.screenPublisher$.next(null);
    this.shareActive$.next(false);
    this.screenSharing$.next(false);

    delete this.publisher;
    delete this.screenPublisher;
    delete this.session;
    delete this.openVidu;
    this.publisher = undefined;
    this.screenPublisher = undefined;


    this.isLeavingSession = false;
    this.sessionInitiated = false;
    console.log('reinit session')
    // запускает переинициализацию сессии
    this.networkDisconnect$.next();
  }

  getDevices(): Observable<Device[]> {
    this.openVidu = this.openVidu ?? this.createOpenVidu();

    const _d = this.openVidu.getDevices();

    return from(_d).pipe(map((devices: Device[]) => devices));
  }

  getAudioOutputDevices(): Observable<MediaDeviceInfo[]> {
    return from(navigator.mediaDevices.enumerateDevices()).pipe(
      map(devices => devices.filter(device => device.kind === 'audiooutput')),
    );
  }

  getNotFoundDevice(): Device {
    return { deviceId: 'none', label: 'Нет устройств для отображения' } as Device;
  }

  getNotFoundMediaDevice(): MediaDeviceInfo {
    return { deviceId: 'none', label: 'Нет устройств для отображения' } as MediaDeviceInfo;
  }

  getAudioMediaTrack(publisherProperties: PublisherProperties): Observable<MediaStreamTrack> {
    this.openVidu = this.openVidu ?? this.createOpenVidu();
    return from(this.openVidu.getUserMedia(publisherProperties)).pipe(
      map(mediaStream => mediaStream.getAudioTracks()?.[0]),
    );
  }

  getVideoMediaTrack(publisherProperties: PublisherProperties): Observable<MediaStreamTrack> {
    this.openVidu = this.openVidu ?? this.createOpenVidu();
    return from(this.openVidu.getUserMedia(publisherProperties)).pipe(
      map(mediaStream => mediaStream.getVideoTracks()?.[0]),
    );
  }

  replaceTrackByPublisherType(track: MediaStreamTrack) {
    if (!track) {
      return;
    }
    if (this.publisher || this.screenPublisher) {
      if (this.publisher) {
        this.replaceTrack(this.publisher, track);
      }

      if (this.screenPublisher && track.kind === 'audio') {
        this.replaceTrack(this.screenPublisher, track);
      }
    }
  }

  replaceTrack(publisher: Publisher, track: MediaStreamTrack) {
    if (publisher.videoReference) {
      publisher.videoReference.muted = true;
    }
    this.groupLessonWaitService.setPublisherVideoResolutionByStreamTrack(track);
    publisher
      .replaceTrack(track)
      .then(() => console.log('New track is being published'))
      .catch(error => console.error(error, 'Error replacing track'));
  }

  createOpenVidu() {
    const ov = new OpenVidu();
    ov.setAdvancedConfiguration({
      iceConnectionDisconnectedExceptionTimeout: connectionDisconnectedExceptionTimeout,
      forceMediaReconnectionAfterNetworkDrop: true,
      publisherSpeakingEventsOptions: {
        interval: 100, // Frequency of the polling of audio streams in ms (default 100)
        threshold: -65, // Threshold volume in dB (default -50)
      },
    });
    if (environment.production) {
      ov.enableProdMode();
    }
    return ov;
  }
}
