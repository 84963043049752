import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberPublishingStateEnum,
  ChatLessonMemberRole,
} from './chat-lesson-member-permisson';
import { User } from './user';

export interface ChatLessonMember {
  memberId?: number;
  avatarFileName?: string;
  name?: string;
  user?: User;
  ///Obsolete
  isTutor?: boolean;
  isOnline?: boolean;
  isDisconnected?: boolean;
  role?: ChatLessonMemberRole;
  publishingPermissions?: Array<ChatLessonMemberPublishingPermission>;
  // publishingState?: Array<ChatLessonMemberPublishingStateEnum>;
  publishingState?: ChatLessonMemberPublishingStateValue[];
  id?: number;

  videoAccess?: boolean;
  audioAccess?: boolean;
  shareScreenAccess?: boolean;
}

export interface ChatLessonMemberPublishingStateValue {
  name: ChatLessonMemberPublishingStateEnum;
  arg: string;
}

export interface RequestChangePublishingState extends ChatLessonMemberPublishingStateValue {
  isOn: boolean;
}

export interface RequestChangePublishingStateWithId {
  memberId: number | null;
  changeState: RequestChangePublishingState[];
}
