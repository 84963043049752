<ezteach-popup-with-bg
  [smiley]="smileyType.happy"
  [hasCloseBtn]="true"
  (onCloseBtnClick)="close()"
  [isDefaultWidth]="false"
>
  <div title> {{ 'Занятие завершено' | transloco }}!</div>
  <div buttons>
    <ezteach-btn (click)="close()">
      <div content> {{ 'Вернуться на доску' | transloco }} </div>
    </ezteach-btn>
  </div>
</ezteach-popup-with-bg>
