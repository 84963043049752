import { MediaQueryService } from '@ezteach/_services/media-query.service';
import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import * as _ from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { base36encode } from 'src/utils/base36';
import { v4 as uuidv4 } from 'uuid';
import { EmbeddedFileTypeEnum, ImagePreviewData, UploadedFile } from '../classes/objects-args';
import { Scene } from '../classes/scene';
import { Position, ShapeTypes, WhiteBoardMode } from '../classes/white-board';
import { ShapeColor, ShapeModifyType } from '../components/shape-tools/shape-tools.component';
import { WhiteBoardConvertors } from '../convertors/shape.convertors';
import { SceneBuilder } from '../scene/scene-builder';
import { WhiteboardStoreService } from '../services/whiteboard-store.service';
import { BaseImageSize } from '../utils/constants';
import { Coordinate, getCenterCoordinates } from '../utils/floating-overlay-position';
import { WhiteBoard } from '../whiteboard';
import { WhiteBoardEditShapedState } from '../whiteboard-state';
import { MemoShaper, SnapshotActon } from '../whiteboardhistory/memo-shaper';
import { flatPointsToXY, getNextFreeLinePoints } from './math/freedraw';
import { getBaseRectXY, rotateAroundCenter } from './math/rorate';
import {
  EllipseShape,
  FileShape,
  FreeLineShape,
  IShapeSnapShot,
  ImageShape,
  RectShape,
  StickerShape,
  TextFieldShape,
  TriangleShape,
  WhiteBoardShape,
} from './shape';
import { ShapeAttrsConfig, ShapeAttrsConfigStorage, ShapeTextAttrsConfig } from './shape-attrs-config';
import { ShapeFactoryBuilder } from './shape-factory-builder';
import { ShapeSceneQueue, ShapeSceneQueueActon } from './shape-scene-queue';
import { WhiteBoardLockedShapeState } from './shape-state';
import { ShapeTextEditor, TextAriaChangedEvent } from './shape-text-editor';
import { ShapeUtils } from './shape-utils';

declare var Hammer: any;

export interface DefaultShapeSize {
  attribute: string;
  value: number;
}

export interface DefaultShapeAttributesBundle {
  type: ShapeTypes;
  sizes: DefaultShapeSize[];
}

export enum DEFAULT_SHAPE_SIZES {
  rectangleWidth = 90,
  rectangleHeight = 90,
  ellipseRadiusX = 50,
  ellipseRadiusY = 50,
  triangleRadius = 60,
  textWidth = 120,
  textHeight = 30,
}

/*
 * Класс для управления фигурами на слое.
 */
export class ShapeManager {
  whiteBoard: WhiteBoard;
  shapeSceneQueue: ShapeSceneQueue = new ShapeSceneQueue();
  private _shapes: Map<string, WhiteBoardShape> = new Map<string, WhiteBoardShape>();
  private memoShaper: MemoShaper;
  private currentShape: ShapeTypes;
  shapes$: Subject<any> = new Subject<any>();
  private withShift = false;
  selectedShapes$ = new BehaviorSubject<WhiteBoardShape[] | null>(null);
  shapeDragStart$: Subject<WhiteBoardShape> = new Subject<WhiteBoardShape>();
  shapeDragEnd$: Subject<WhiteBoardShape> = new Subject<WhiteBoardShape>();
  readonly shapeTransformStart$: Subject<WhiteBoardShape> = new Subject<WhiteBoardShape>();
  readonly shapeTransformEnd$: Subject<WhiteBoardShape> = new Subject<WhiteBoardShape>();
  textEditing$ = new BehaviorSubject<boolean>(false);

  readonly subscriptions: Subscription = new Subscription();
  currentLockedId: string[] = [];

  private sceneRendered = false;
  eraserMode = false;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  isMobile = false;

  private lastShapeAttrsConfigStorage = new ShapeAttrsConfigStorage({});
  private lastStickerAttrsConfigStorage = new ShapeAttrsConfigStorage({});
  private readonly shapeTextEditor = new ShapeTextEditor();

  defaultShapeAttributesBundle: DefaultShapeAttributesBundle[] = [
    {
      type: ShapeTypes.rect,
      sizes: [
        { attribute: 'width', value: DEFAULT_SHAPE_SIZES.rectangleWidth },
        { attribute: 'height', value: DEFAULT_SHAPE_SIZES.rectangleHeight },
      ],
    },
    {
      // используем ellipse, а не circle, т.к. по умолчанию присваивается это сво-во
      type: ShapeTypes.ellipse,
      sizes: [
        { attribute: 'radiusX', value: DEFAULT_SHAPE_SIZES.ellipseRadiusX },
        { attribute: 'radiusY', value: DEFAULT_SHAPE_SIZES.ellipseRadiusY },
      ],
    },
    {
      type: ShapeTypes.triangle,
      sizes: [{ attribute: 'radius', value: DEFAULT_SHAPE_SIZES.triangleRadius }],
    },
    {
      type: ShapeTypes.text,
      sizes: [
        { attribute: 'width', value: DEFAULT_SHAPE_SIZES.textWidth },
        { attribute: 'height', value: DEFAULT_SHAPE_SIZES.textHeight },
      ],
    },
  ];

  minimalDrawingDistance: number = 2;

  constructor(readonly whiteboardStoreService: WhiteboardStoreService) {
    this.memoShaper = new MemoShaper(this);
    this.subscribeTextEditor();

    const match$ = this.mediaQueryService.match$
      .pipe(
        tap(x => {
          this.isMobile = x;
        }),
      )
      .subscribe();
    this.subscriptions.add(match$);
  }

  public getMemoShaper(): MemoShaper {
    return this.memoShaper;
  }

  get shapes(): Map<string, WhiteBoardShape> {
    return this._shapes;
  }

  setWhiteBoard(whiteBoard: WhiteBoard) {
    this.whiteBoard = whiteBoard;
  }

  setCurrentShape(shapeType: ShapeTypes) {
    this.currentShape = shapeType;
  }

  initTouchShaper() {
    let originalAttrs = {
      x: 100,
      y: 100,
      draggable: true,
      drawBorder: true,
      rotation: 0,
    };
    this.tauchGroup = new Konva.Group(originalAttrs);
    this.whiteBoard.mainLayerGet().add(this.tauchGroup);
  }

  getCurrentShape(): ShapeTypes {
    return this.currentShape;
  }

  /*
   * Рисование начинается при от стартовой точки - которая берется при нажатии мыши
   */
  positionStart: Position;
  startShape: any;
  overlayShape: Shape;
  beginDraw(position: Position) {
    this.positionStart = position;

    var width = position.x - this.positionStart.x + 1;
    var height = position.y - this.positionStart.y + 1;
    const radius = Math.sqrt(width ** 2 + height ** 2);
    const points =
      this.currentShape === ShapeTypes.freeline
        ? this.startShape !== null && this.startShape != undefined
          ? getNextFreeLinePoints({ x: 0, y: 0 }, this.startShape.points())
          : [0.5, 0.5, 0, 0]
        : [];
    const shapeFactory = new ShapeFactoryBuilder(this.positionStart.x, this.positionStart.y)
      .withRadius(radius)
      .withHeight(height)
      .withWidth(width)
      .withScaleX(1)
      .withScaleY(1)
      .withOpacity(1)
      .withRadiusX(Math.abs(width))
      .withRadiusY(Math.abs(height))
      .withPoints(points)
      .withStrokeWidth(1)
      .buildFactory(this.currentShape);

    const shape = shapeFactory.createShape();

    if (this.startShape) {
      this.startShape.destroy();
    }
    this.startShape = shape;
    this.whiteBoard.mainLayerGet().add(shape);
    this.whiteBoard.mainLayerGet().draw();

    if (this.currentShape === ShapeTypes.text) {
      this.drawRectOverlay(this.positionStart.x, this.positionStart.y, width);
    }
  }

  /*
   * Если состояние нажата мышь и просиходит ее движение стираем предыдущую версию фигуры и рисуем с новыми точками
   */
  draw(position) {
    if (!this.positionStart) {
      return;
    }

    // координаты для квадратных фигур
    let width = position.x - this.positionStart.x;
    let height = position.y - this.positionStart.y;
    let x = this.positionStart.x;
    let y = this.positionStart.y;

    let radius = Math.sqrt((position.x - this.positionStart.x) ** 2 + (position.y - this.positionStart.y) ** 2);

    const points =
      this.currentShape === ShapeTypes.freeline
        ? this.startShape !== null && this.startShape != undefined
          ? getNextFreeLinePoints({ x: position.x - x, y: position.y - y }, this.startShape.points())
          : [0, 0, 0, 0]
        : [];

    // считаем координаты для овала/круга
    if (this.currentShape === ShapeTypes.ellipse) {
      radius = radius / 2;

      const xm = (position.x + this.positionStart.x) / 2;
      const ym = (position.y + this.positionStart.y) / 2;

      x = xm;
      y = ym;
      width = position.x - xm;
      height = position.y - ym;

      if (this.withShift) {
        // корректировка при зажатой клавише shift
        const size = Math.max(Math.abs(width), Math.abs(height));
        const difW = Math.abs(width) - size;
        const difH = Math.abs(height) - size;
        x += width >= 0 ? -difW : difW;
        y += height >= 0 ? -difH : difH;
        width = height = size;
      }
    }

    if (this.currentShape === ShapeTypes.sticker) {
      if (width < 0) {
        width = 0;
      }
      if (height < 0) {
        height = 0;
      }
    }

    const shapeFactory = new ShapeFactoryBuilder(x, y)
      .withRadius(radius)
      .withHeight(height)
      .withWidth(width)
      .withScaleX(1)
      .withScaleY(1)
      .withOpacity(1)
      .withRadiusX(Math.abs(width))
      .withRadiusY(Math.abs(height))
      .withPoints(points)
      .withStrokeWidth(1)
      .buildFactory(this.currentShape);

    const shape = shapeFactory.createShape();

    if (this.startShape) {
      this.startShape.destroy();
    }
    this.startShape = shape;
    this.whiteBoard.mainLayerGet().add(shape);

    if (this.currentShape === ShapeTypes.text) {
      this.drawRectOverlay(this.positionStart.x, this.positionStart.y, width);
    }
  }

  updateShapeByLastAttrs(shape: WhiteBoardShape, lastAttrs: ShapeAttrsConfig) {
    if (lastAttrs?.fill) {
      shape.setFillColor({ id: 0, code: lastAttrs?.fill });
    }
    if (lastAttrs?.stroke && this.isLastShapeAttrAvailable(this.currentShape, 'stroke')) {
      shape.setStrokeColor({ id: 0, code: lastAttrs?.stroke });
    }
    if (lastAttrs?.strokeWidth && this.isLastShapeAttrAvailable(this.currentShape, 'strokeWidth')) {
      shape.setStrokeWidth(lastAttrs?.strokeWidth);
    }
    if (lastAttrs?.opacity && this.isLastShapeAttrAvailable(this.currentShape, 'opacity')) {
      shape.setOpacity(lastAttrs?.opacity, false);
    }
  }

  updateStickerByLastAttrs(shape: WhiteBoardShape, lastAttrs: ShapeAttrsConfig) {
    if (lastAttrs?.fill) {
      shape.setFillColor({ id: 0, code: lastAttrs?.fill });
    }
  }

  /*
   * Рисование фигуры завершается
   */
  endDraw(position: Position) {
    this.checkAddingShapeByClick();
    const shape = this.buildWbShape(this.currentShape);
    shape.setKonvajsShape(this.startShape);
    shape.subscribeOnEvents();
    shape.id = this.getId(this.currentShape);
    shape.konvajsShape.setAttr('ezId', shape.id);
    shape.konvajsShape.setAttr('type', this.currentShape);

    if (this.currentShape === ShapeTypes.sticker) {
      const lastStickerAttrs = this.lastStickerAttrsConfigStorage.getConfig();
      this.updateStickerByLastAttrs(shape, lastStickerAttrs);
    } else {
      const lastAttrs = this.currentShape !== ShapeTypes.text ? this.lastShapeAttrsConfigStorage.getConfig() : null;
      this.updateShapeByLastAttrs(shape, lastAttrs);
    }

    this._shapes.set(shape.id, shape);
    this.positionStart = null;
    this.startShape = null;
    this.shapes$.next(Array.from(this._shapes.values()));
    shape.toReadonlyState();
    if (this.currentShape !== ShapeTypes.text) {
      this.addSnapshot(shape, shape.createHistorySnapshot(), SnapshotActon.addShape);
    }

    if (this.currentShape !== ShapeTypes.freeline) {
      this.toWhiteBoardReadonlyState();
    }

    shape.konvajsShape.setAttr('z', shape.konvajsShape.zIndex());

    this.whiteBoard.onObjectsCreated$.next(shape.konvajsShape);

    const subscription = shape.notifyAttrsChanged$.subscribe(x => {
      this.whiteBoard.onObjectsUpdated$.next([x]);
    });

    this.subscriptions.add(subscription);

    if (this.currentShape === ShapeTypes.text) {
      this.overlayShape.destroy();
      this.editTextProperties(shape);
    }
  }

  isLastShapeAttrAvailable<T extends keyof ShapeAttrsConfig>(shapeType: ShapeTypes, attrName: T) {
    if (shapeType === ShapeTypes.sticker && attrName === 'stroke') {
      return false;
    }
    if (shapeType === ShapeTypes.sticker && attrName === 'opacity') {
      return false;
    }

    return true;
  }

  checkAddingShapeByClick(): void {
    const attributes = this.shapeAttributeValuesForCompare();
    if (attributes.length === 0) {
      return;
    }
    const lessThanMinimal = this.shapeAttributesLessMinimal(attributes, this.minimalDrawingDistance);
    if (lessThanMinimal) {
      this.applyDefaultAttrValue();
    }
  }

  shapeAttributeValuesForCompare(): number[] {
    const bundleByShapeType = this.defaultShapeAttributesBundle.find(
      bundle => bundle.type === this.startShape.attrs.type,
    );

    if (!bundleByShapeType) {
      return [];
    }

    const attributes = bundleByShapeType.sizes.map(size => size.attribute);
    return attributes.map(attr => this.startShape.attrs[attr]);
  }

  shapeAttributesLessMinimal(attrs: number[], minimal: number): boolean {
    return attrs.some(attr => attr < minimal);
  }

  applyDefaultAttrValue() {
    const shapeBundle = this.defaultShapeAttributesBundle.filter(
      bundle => bundle.type === this.startShape.attrs.type,
    )[0];
    shapeBundle.sizes.forEach(size => {
      this.startShape.attrs[size.attribute] = size.value;
    });
  }

  drawRectOverlay(x, y, width) {
    const overlayFactory = new ShapeFactoryBuilder(x, y)
      .withHeight(25)
      .withWidth(width)
      .withScaleX(1)
      .withScaleY(1)
      .withOpacity(1)
      .withStrokeWidth(1)
      .buildFactory(ShapeTypes.rect);

    const overlayShape = overlayFactory.createShape();
    if (this.overlayShape) {
      this.overlayShape.destroy();
    }
    this.overlayShape = overlayShape as Shape;
    this.whiteBoard.mainLayerGet().add(overlayShape);
    this.whiteBoard.mainLayerGet().draw();
  }

  selectionRectangle: any;
  selectPositionStart: Position;
  transform: Konva.Transformer;
  multiSelect: boolean;
  multiSelectShapeQty: number = 0;
  multiSelectCurrentTransformQty: number = 0;
  fromTouchStartSelect = false;
  fromTouchStartEnd = false;
  startSelect(position, multiSelect: boolean, fromTouch: boolean) {
    this.fromTouchStartEnd = false;
    if (this.fromTouchStartSelect) {
      return;
    }
    if (fromTouch) {
      this.fromTouchStartSelect = true;
    }
    this.multiSelect = multiSelect;
    if (this.selectionRectangle) {
      this.selectionRectangle.destroy();
      this.selectionRectangle = null;
    }

    if (this.transform) {
      this.setNodesStateToReadonly(this.transform);
      this.transform.nodes([]);
      const transforms = this.whiteBoard
        .mainLayerGet()
        .children.filter(x => x.constructor.name === Konva.Transform.name);
      transforms.forEach(element => {
        element.destroy();
      });
    }
    this.selectPositionStart = position;
    this.transform = this.createTransform();
    this.whiteBoard.mainLayerGet().add(this.transform);
    const shapeFactory = new ShapeFactoryBuilder(this.selectPositionStart.x, this.selectPositionStart.y)
      .withFill('rgba(154, 189, 247, 0.5)')
      .withHeight(2)
      .withWidth(2)
      .withScaleX(1)
      .withScaleY(1)
      .withOpacity(1)
      .buildFactory(ShapeTypes.rect);

    this.selectionRectangle = shapeFactory.createShape();
    this.whiteBoard.mainLayerGet().add(this.selectionRectangle);
  }

  /*
   * Процесс выделения
   */
  select(position) {
    if (!this.selectPositionStart) {
      return;
    }
    if (this.fromTouchStartEnd) {
      return;
    }

    var width = position.x - this.selectPositionStart.x;
    var height = position.y - this.selectPositionStart.y;

    const shapeFactory = new ShapeFactoryBuilder(this.selectPositionStart.x, this.selectPositionStart.y)
      .withHeight(height)
      .withWidth(width)
      .withStroke('#5292fd')
      .withFill('rgba(154, 189, 247, 0.5)')
      .withScaleX(1)
      .withScaleY(1)
      .withOpacity(1)
      .buildFactory(ShapeTypes.rect);
    const rect = shapeFactory.createShape();
    if (this.selectionRectangle) {
      this.selectionRectangle.destroy();
    }
    this.selectionRectangle = rect;
    this.whiteBoard.mainLayerGet().add(rect);
    this.whiteBoard.mainLayerGet().draw();
  }

  /*
   * После того как заканчиваем выделять - нужно найти области пересечений и добавить формы в transform
   * Если такие формы не найдуться, то очистить transform
   */
  endSelect() {
    if (this.fromTouchStartEnd) {
      return;
    }

    this.fromTouchStartEnd = this.fromTouchStartSelect;

    this.selectPositionStart = null;
    if (!this.selectionRectangle) {
      this.clearTransform();
      return;
    }

    if (this.currentLockedId.length > 0) {
      this.whiteBoard.onObjectsUnLocked$.next([...this.currentLockedId]);
      this.currentLockedId = [];
    }

    const shapes = this.getUnlockerShapes();
    const selected = shapes.filter(({ konvajsShape }) => this.isHaveIntersection(konvajsShape));
    try {
      if (this.transform && selected.length > 0) {
        const sortedByIndex = _.sortBy(selected, x => x.konvajsShape.index);
        const reversed = sortedByIndex.reverse();
        const shape = this.multiSelect ? selected : [reversed[0]];

        // Запрещено выделение
        if (!this.permitStudentSelect(shape)) {
          this.selectionRectangle?.destroy();
          this.selectionRectangle = null;
          return;
        }

        this.selectShapes(shape);

        this.currentLockedId = shape.filter(s => !this.isDownloadingShape(s)).map(x => x.konvajsShape.getAttr('ezId'));

        this.whiteBoard.shapeTauched$.next(true);

        this.rotationStateCheck(shape);
      }
      this.transform.setAttr('ezId', uuidv4());
    } catch (e) {
      // console.log(e);
      this.clearTransform();
    }

    // если найдено 0 фигур
    if (selected.length === 0) {
    } else {
      // нужно переключить состояние доски на "трансмормируем объект"
      // в этом случае не нужно отслеживать выделение пока есть редактируемые объекты
      // сбрасывать режим редактирования при клике за пределами текущего transform.
    }

    this.selectionRectangle?.destroy();
    this.selectionRectangle = null;
    this.multiSelect = null;
  }

  createTransform(): Konva.Transformer {
    const transform = new Konva.Transformer({
      shouldOverdrawWholeArea: true,
      anchorStroke: '#3d3d3d',
      anchorSize: this.isMobile ? 14 : 8,
      anchorStrokeWidth: 0.5,
      flipEnabled: false,
    });
    transform.ignoreStroke(true);

    return transform;
  }

  selectShapes(shapes: WhiteBoardShape[]) {
    if (!this.transform) {
      this.transform = this.createTransform();
      this.transform.setAttr('ezId', uuidv4());
      this.whiteBoard.mainLayerGet().add(this.transform);
    }
    this.transform.nodes(shapes.map(s => s.konvajsShape));
    this.multiSelectShapeQty = shapes.length;
    this.selectedShapes$.next(shapes);
    shapes.forEach(x => x.toMouseState());
    if (this.canEditShape()) {
      this.whiteBoard.setState(new WhiteBoardEditShapedState());
    } else {
      shapes.forEach(x => x.konvajsShape.draggable(false));
      this.transform.enabledAnchors(['']);
      this.transform.rotateEnabled(false);
    }

    if (shapes.length === 1) {
      this.singleShapeSelect(shapes[0]);
    }
  }

  singleShapeSelect(shape: WhiteBoardShape): void {
    if (shape.konvajsShape.attrs.type === ShapeTypes.text) {
      this.transform.enabledAnchors(['middle-left', 'middle-right', 'bottom-center']);
    }
    if (shape.konvajsShape.attrs.type === ShapeTypes.image && this.canEditShape()) {
      this.transform.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right']);
    }
    if (shape.konvajsShape.attrs.type === ShapeTypes.sticker) {
      this.transform.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right']);
      this.transform.padding(5);
    }
    if (shape.shouldCancelEvents) {
      this.transform.on('transform', () => this.transform.stopTransform());
    }
  }

  /** Находится ли выделенная фигура в данной точке координат */
  isSelectedShapeUnderCoordinate(coordinate: Coordinate): boolean {
    const coordinateBox = { ...coordinate, width: 1, height: 1 };
    const shapes = this.transform?.nodes() ?? [];
    return shapes.some(shape => Konva.Util.haveIntersection(coordinateBox, shape.getClientRect()));
  }

  canEditShape() {
    return this.whiteBoard.whiteBoardMode !== WhiteBoardMode.inside || this.whiteBoard.isOwner;
  }

  permitStudentSelect(shapes: WhiteBoardShape[]): boolean {
    if (this.canEditShape()) {
      return true;
    }

    return this.isStudentSelectDownloadingShape(shapes);
  }

  isStudentSelectDownloadingShape(shapes: WhiteBoardShape[]): boolean {
    return shapes.length === 1 && this.isDownloadingShape(shapes[0]);
  }

  isDownloadingShape(shape: WhiteBoardShape): boolean {
    return shape.constructor.name === FileShape.name || shape.constructor.name === ImageShape.name;
  }

  rotationStateCheck(shape: WhiteBoardShape[]) {
    const hasSticker = shape.some(s => s.konvajsShape.attrs.type === 'sticker');
    if (hasSticker) {
      this.transform.rotateEnabled(false);
    }
  }

  forceSelect(x: number, y: number) {
    this.startSelect({ x: x, y: y }, false, false);
    this.endSelect();
  }

  selectReadOnlyFileDownload(e) {
    if (
      !this.whiteBoard.isOwner &&
      this.whiteBoard.whiteBoardMode === WhiteBoardMode.inside &&
      (e.target.constructor.name === Konva.Image.name ||
        e.target.attrs.type === 'file' ||
        e.target.attrs.type === 'image')
    ) {
      const x = this.whiteBoard.whiteBoardState.withOffsetX(e.evt.clientX);
      const y = this.whiteBoard.whiteBoardState.withOffsetY(e.evt.clientY);
      this.forceSelect(x, y);
    }
  }

  /*
   * Удаляет transform объект с доски
   */
  clearTransform() {
    if (this.transform) {
      this.setNodesStateToReadonly(this.transform);
      this.transform.destroy();
      this.transform = null;
    }
    this.whiteBoard.onObjectsUnLocked$.next([...this.currentLockedId]);
    this.currentLockedId = [];
    this.selectedShapes$.next(null);
    this.multiSelectCurrentTransformQty = 0;
    this.multiSelectShapeQty = 0;
    this.fromTouchStartSelect = false;
    this.fromTouchStartEnd = false;
    this.whiteBoard.shapeTauched$.next(false);
    this.closeTextEditor();
  }

  setNodesStateToReadonly(transform: Konva.Transformer) {
    for (let index = 0; index < transform.nodes().length; index++) {
      const node = transform.nodes()[index];
      const ezId = node?.getAttr('ezId');
      if (ezId) {
        const shape = this._shapes.get(ezId);
        shape?.toReadonlyState();
      }
    }
  }

  // группа-обертка для тач фигур
  tauchGroup = null;
  // текущая выбранная фигура
  tauchSelected: WhiteBoardShape = null;
  // слепок атрибутов до редактирования при помощи тача
  tauchSelectedAttrs = null;
  // смещение по x y для оригинальной координаты без поворота - см доку
  dxdy = null;
  // оригинальная координата x фигуры с учетом смещения на градус
  originalX = null;
  // оригинальная координата y фигуры с учетом смещения на градус
  originalY = null;
  // начало координаты группы-обертки для тач фигур
  startGroupX = 0;
  startGroupY = 0;
  /*
   * Начало выделения для тач устройств
   */
  startShapeTouch(position, ev: TouchEvent) {
    // нужно сделать фантовный квадрат для пересечений
    const shapeFactory = new ShapeFactoryBuilder(position.x - 20, position.y - 20)
      .withHeight(2)
      .withWidth(2)
      .withScaleX(1)
      .withScaleY(1)
      .withOpacity(1)
      .withFill('rgba(0,0,0,0)')
      .withStroke('rgba(0,0,0,0)')
      .buildFactory(ShapeTypes.rect);
    const rect = shapeFactory.createShape();
    this.whiteBoard.mainLayerGet().add(rect);
    this.whiteBoard.mainLayerGet().draw();

    // найти пересечения с фигурами
    var box = rect.getClientRect();
    const shapes = this.getUnlockerShapes();
    var selected = shapes.filter(shape => Konva.Util.haveIntersection(box, shape.konvajsShape.getClientRect()));

    // добавить их в хамер события
    if (selected && selected.length === 1) {
      if (
        this.tauchSelected &&
        this.tauchSelected.konvajsShape.getAttr('ezId') === selected[0].konvajsShape.getAttr('ezId')
      ) {
        return;
      }
      if (this.tauchSelected) {
        this.removeTouchSelect();
      }

      const target = selected[0];
      this.tauchSelected = selected[0];
      this.tauchSelected.toTouchState();

      var oldRotation = this.tauchSelected.konvajsShape.attrs.rotation ?? 0;
      this.tauchSelectedAttrs = Object.assign({}, this.tauchSelected.konvajsShape.attrs);
      // оригинальная коорлината для вращения только для квадратов
      if (this.tauchSelected.konvajsShape.attrs.rotation && target.konvajsShape.attrs.type === 'rect') {
        this.dxdy = getBaseRectXY(this.tauchSelected.konvajsShape);
      }

      let groupX = 0;
      let groupY = 0;
      this.originalX = this.tauchSelected.konvajsShape.attrs.x + (this.dxdy?.dx ?? 0);
      this.originalY = this.tauchSelected.konvajsShape.attrs.y + (this.dxdy?.dy ?? 0);
      if (target.konvajsShape.attrs.type === 'rect') {
        groupX = target.konvajsShape.attrs.width / 2 + (this.tauchSelected.konvajsShape.attrs.x + (this.dxdy?.dx ?? 0));
        groupY =
          target.konvajsShape.attrs.height / 2 + (this.tauchSelected.konvajsShape.attrs.y + (this.dxdy?.dy ?? 0));
      }
      if (target.konvajsShape.attrs.type === 'ellipse') {
        groupX = this.tauchSelected.konvajsShape.attrs.x;
        groupY = this.tauchSelected.konvajsShape.attrs.y;
      }
      if (target.konvajsShape.attrs.type === 'triangle') {
        groupX = this.tauchSelected.konvajsShape.attrs.x;
        groupY = this.tauchSelected.konvajsShape.attrs.y;
      }

      this.startGroupX = groupX;
      this.startGroupY = groupY;

      if (this.tauchSelected.konvajsShape.attrs.rotation) {
        rotateAroundCenter(this.tauchSelected.konvajsShape, 0);
      }
      this.tauchGroup.position(groupX, groupY);
      this.tauchGroup.setX(groupX);
      this.tauchGroup.setY(groupY);
      this.tauchGroup.setRotation(oldRotation);
      if (this.tauchSelected.konvajsShape.attrs.scaleX) {
        //this.tauchGroup.setScaleX( this.tauchSelected.konvajsShape.attrs.scaleX);
      }
      if (this.tauchSelected.konvajsShape.attrs.scaleY) {
        //this.tauchGroup.setScaleY( this.tauchSelected.konvajsShape.attrs.scaleY);
      }

      // для того что бы крутить фигуру вокруг центра нужно сделать манипцляцию
      let offsetX = 0;
      let offsetY = 0;
      if (target.konvajsShape.attrs.type === 'rect') {
        offsetX = target.konvajsShape.attrs.width / 2;
        offsetY = target.konvajsShape.attrs.height / 2;
      }
      if (target.konvajsShape.attrs.type === 'ellipse') {
        offsetX = target.konvajsShape.attrs.radiusX / 2;
        offsetY = target.konvajsShape.attrs.radiusY / 2;
      }
      if (target.konvajsShape.attrs.type === 'triangle') {
        offsetX = target.konvajsShape.attrs.radius / 2;
        offsetY = target.konvajsShape.attrs.radius / 2;
      }
      target.konvajsShape.setOffsetX(offsetX);
      target.konvajsShape.setOffsetY(offsetY);
      this.tauchSelected.konvajsShape.setScaleX(1);
      this.tauchSelected.konvajsShape.setScaleY(1);
      let baseX = 0;
      let baseY = 0;
      if (target.konvajsShape.attrs.type === 'ellipse') {
        baseX = offsetX; // + target.konvajsShape.attrs.radiusX;
        baseY = offsetY; // + target.konvajsShape.attrs.radiusY;
      }
      if (target.konvajsShape.attrs.type === 'triangle') {
        baseX = offsetX; // + target.konvajsShape.attrs.radiusX;
        baseY = offsetY; // + target.konvajsShape.attrs.radiusY;
      }
      target.konvajsShape.setX(baseX);
      target.konvajsShape.setY(baseY);
      this.tauchGroup.add(target.konvajsShape);
      const hammertime = new Hammer(this.tauchGroup, { domEvents: true });
      hammertime.get('rotate').set({ enable: true });

      this.tauchGroup.on('rotatestart', ev => {
        oldRotation = ev.evt.gesture.rotation;
        this.tauchGroup.stopDrag();
        this.tauchGroup.draggable(false);
      });

      this.tauchGroup.on('rotate', ev => {
        let delta = oldRotation - ev.evt.gesture.rotation;
        this.tauchGroup.rotate(-delta / 100);
        // this.tauchGroup.scaleX(startScaleX * ev.evt.gesture.scale);
        // this.tauchGroup.scaleY(startScaleY * ev.evt.gesture.scale);
      });

      this.tauchGroup.on('rotateend rotatecancel', ev => {
        this.tauchGroup.draggable(true);
      });
    } else {
      if (this.tauchSelected) {
        this.removeTouchSelect();
      }
    }
  }

  removeTouchSelect() {
    this.tauchSelected.toReadonlyState();
    // const offX = this.tauchSelected.konvajsShape.attrs.offsetX;
    // const offY = this.tauchSelected.konvajsShape.attrs.offsetY;
    this.tauchSelected.konvajsShape.setOffsetX(0);
    this.tauchSelected.konvajsShape.setOffsetY(0);
    this.tauchSelected.konvajsShape.moveTo(this.whiteBoard.mainLayerGet());

    // необходимо востановить нужную точку из группы
    let x = 0;
    let y = 0;
    if (this.tauchSelected.konvajsShape.attrs.type === 'rect') {
      x = this.originalX;
      y = this.originalY;
    }
    if (this.tauchSelected.konvajsShape.attrs.type === 'ellipse') {
      x = this.originalX;
      y = this.originalY;
    }
    if (this.tauchSelected.konvajsShape.attrs.type === 'triangle') {
      x = this.originalX;
      y = this.originalY;
    }

    // перемещаем фигуру из групповой коорднаты в свою с учетом перемещений
    const deltaX = this.tauchGroup.attrs.x - this.startGroupX;
    const deltaY = this.tauchGroup.attrs.y - this.startGroupY;
    if (this.tauchGroup.attrs.scaleX && this.tauchGroup.attrs.scaleX !== 1) {
      //x = x - offX;
    }
    if (this.tauchGroup.attrs.scaleY && this.tauchGroup.attrs.scaleY !== 1) {
      //y = y - offY;
    }
    this.tauchSelected.konvajsShape.position({ x: x + deltaX, y: y + deltaY });
    // правильно вращаем rotateAroundCenter
    if (this.tauchGroup.attrs.rotation && this.tauchGroup.attrs.rotation !== this.tauchSelectedAttrs.rotation) {
      let rorate = this.tauchGroup.attrs.rotation;
      if (!!this.tauchSelectedAttrs.rotation) {
        rorate = rorate - this.tauchSelectedAttrs.rotation;
      }
      if (this.tauchSelected.konvajsShape.attrs.type === 'rect') {
        rotateAroundCenter(this.tauchSelected.konvajsShape, rorate);
      } else {
        this.tauchSelected.konvajsShape.setRotation(this.tauchGroup.attrs.rotation);
      }
    } else if (this.tauchSelectedAttrs.rotation) {
      // если вращения не было нужно вернуть исходное
      if (this.tauchSelected.konvajsShape.attrs.type === 'rect') {
        rotateAroundCenter(this.tauchSelected.konvajsShape, this.tauchSelectedAttrs.rotation);
      } else {
        this.tauchSelected.konvajsShape.setRotation(this.tauchGroup.attrs.rotation);
      }
    }
    // this.tauchSelected.konvajsShape.scaleX(this.tauchGroup.attrs.scaleX ?? 1);
    // this.tauchSelected.konvajsShape.scaleY(this.tauchGroup.attrs.scaleY ?? 1);

    // разблокируем фигуру
    const ids = [this.tauchSelected.konvajsShape.getAttr('ezId')];
    this.whiteBoard.onObjectsUnLocked$.next(ids);
    this.currentLockedId = [];
    this.tauchSelected.notifyAttrsChanged$.next(this.tauchSelected.konvajsShape);

    // https://stackoverflow.com/questions/53402234/how-to-detach-a-shape-from-a-group
    this.tauchGroup.removeChildren();
    this.whiteBoard.mainLayerGet().draw();
    this.tauchSelected = null;
  }

  endShapeTouch(e, ev: TouchEvent) { }

  removeShape(shape: WhiteBoardShape, hideContextMenu = true, withSnapshot: boolean = true, clearTransform = true) {
    if (shape) {
      if (withSnapshot) {
        this.addSnapshot(shape, shape.createHistorySnapshot(), SnapshotActon.removeShape, false);
      }

      this.whiteBoard.onObjectsDeleted$.next([shape.konvajsShape.getAttr('ezId')]);
      shape.konvajsShape.destroy();
      this._shapes.delete(shape.konvajsShape.attrs.ezId);
      this.shapes$.next(Array.from(this._shapes.values()));
      if (clearTransform) {
        this.clearTransform();
      }
    }
    if (hideContextMenu) {
      this.whiteBoard.hideContextMenu();
    }
  }

  copyShapes(): void {
    const shapes =
      this.transform
        ?.nodes()
        ?.map(n => n as Shape)
        ?.map(s => WhiteBoardConvertors.convertShapeToCreateObjectDescription(s)) ?? [];
    if (this.canEditShape() && shapes.length > 0) {
      this.whiteboardStoreService.addShapesToBuffer(shapes);
    }
  }

  pasteShapes(coordinate: Coordinate): void {
    const shapes = this.whiteboardStoreService.getShapesFromBuffer();
    if (this.canEditShape() && shapes.length > 0) {
      const attributes = shapes.map(s => WhiteBoardConvertors.convertCreateObjectDescriptionToShape(s)) ?? [];
      const wbShapes: WhiteBoardShape[] = [];
      // вычисляем центр x/y у всех фигур
      const center = getCenterCoordinates(attributes);

      for (const source of attributes) {
        // Вычисляем смещение фигур, относительно центра
        const offsetX = center.x - source.x;
        const offsetY = center.y - source.y;

        // Меняем ezId, x, y
        const attr = {
          ...source,
          ezId: this.getId(source.type),
          x: coordinate.x - offsetX,
          y: coordinate.y - offsetY,
        };
        const shape = this.addShape(attr, true);
        this.whiteBoard.onObjectsCreated$.next(shape.konvajsShape);
        // добавляем фигуру для выделения
        wbShapes.push(shape);
      }
      // выделяем вставленные фигуры
      this.selectShapes(wbShapes);
    }
  }

  turnOnRemoveFreeDrawMode() {
    if (this.eraserMode) {
      const removeShapeTimeout = setTimeout(() => {
        const eraserFollower = this.whiteBoard.stage.findOne('#eraserFollower');
        if (eraserFollower) {
          const eraserFollowerRect = eraserFollower.getClientRect();

          this.whiteBoard.mainLayerGet().children.map(shape => {
            if (shape === eraserFollower || shape.attrs.type !== ShapeTypes.freeline) {
              return;
            }
            if (Konva.Util.haveIntersection(shape.getClientRect(), eraserFollowerRect)) {
              const shapeObj = this.getUnlockerShapes().find(
                currentShape => currentShape.konvajsShape.attrs.ezId === shape.attrs.ezId,
              );
              if (shapeObj) {
                this.removeShape(shapeObj, false);
              }
            }
          });
        }

        clearTimeout(removeShapeTimeout);
      }, 10);
    }
  }

  setEraserMode(value: boolean) {
    this.eraserMode = value;
  }

  restoreShape(oldShape: WhiteBoardShape, shapeSnapShot: IShapeSnapShot, withSnapShot = true) {
    const shapeType = oldShape.konvajsShape.attrs.type as ShapeTypes;
    let shapeFactory = new ShapeFactoryBuilder(shapeSnapShot.getX(), shapeSnapShot.getY())
      .withRadius(shapeSnapShot.getRadius())
      .withHeight(shapeSnapShot.getH())
      .withWidth(shapeSnapShot.getW())
      .withScaleX(shapeSnapShot.getScaleX())
      .withScaleY(shapeSnapShot.getScaleY())
      .withOpacity(shapeSnapShot.getOpacity())
      .withRadiusX(Math.abs(shapeSnapShot.getRadiusX()))
      .withRadiusY(Math.abs(shapeSnapShot.getRadiusY()))
      .withFill(shapeSnapShot.getFill())
      .withStroke(shapeSnapShot.getStroke())
      .withStrokeWidth(shapeSnapShot.getStrokeWidth())
      .withPoints(shapeSnapShot.getPoints())
      .withText(shapeSnapShot.getText())
      .withFileId(shapeSnapShot.getFileId())
      .withFileDisplayName(shapeSnapShot.getFileDisplayName());

    if (shapeType === ShapeTypes.text) {
      shapeFactory = shapeFactory.withTextAttrs(ShapeUtils.textAttrsFromTextSnapshot(shapeSnapShot));
    }

    const builder = shapeFactory.buildFactory(shapeType);
    const konvaShape = builder.createShape();
    konvaShape.setAttr('z', oldShape.konvajsShape.attrs.z);
    const shape = this.buildWbShape(shapeType);
    shape.setKonvajsShape(konvaShape);
    shape.subscribeOnEvents();
    shape.id = oldShape.konvajsShape.attrs.ezId ?? oldShape.id;
    shape.konvajsShape.setAttr('ezId', shape.id);
    shape.konvajsShape.setAttr('type', shapeType);

    this.memoShaper.updateShape(shape);
    this.whiteBoard.mainLayerGet().add(konvaShape);
    this.whiteBoard.mainLayerGet().draw();
    this._shapes.set(shape.id, shape);
    this.shapes$.next(Array.from(this._shapes.values()));

    if (withSnapShot) {
      this.addSnapshot(shape, shape.createHistorySnapshot(), SnapshotActon.addShape, false);
    }

    const subscription = shape.notifyAttrsChanged$.subscribe(x => {
      this.whiteBoard.onObjectsUpdated$.next([x]);
    });

    this.subscriptions.add(subscription);

    if (shapeType === ShapeTypes.image && shapeSnapShot.getFileId()) {
      this.whiteBoard.loadPreview$.next({
        shapeId: shape.konvajsShape.getAttr('ezId'),
        fileId: shapeSnapShot.getFileId(),
        width: shapeSnapShot.getW(),
      });
    }

    this.whiteBoard.onObjectsCreated$.next(shape.konvajsShape);
  }

  addShapeOrQueue(data: any) {
    if (!this.sceneRendered) {
      this.shapeSceneQueue.enqueue({
        data: data,
        action: ShapeSceneQueueActon.addShape,
      });
      return;
    }

    this.addShape(data);
  }

  addShape(data: any, withSnapshot = true) {
    const shape = this.buildWbShape(data.type);
    const shapeFactory = new ShapeFactoryBuilder(data.x, data.y)
      .withRadius(data.radius)
      .withHeight(data.height)
      .withWidth(data.width)
      .withScaleX(data.scaleX)
      .withScaleY(data.scaleY)
      .withRotation(data.rotation)
      .withOpacity(data.opacity)
      .withRadiusX(Math.abs(data.radiusX))
      .withRadiusY(Math.abs(data.radiusY))
      .withFill(data.fill ?? 'rgba(0,0,255)')
      .withStroke(data.stroke)
      .withPoints(data.points)
      .withStrokeWidth(data.strokeWidth ?? 1)
      .withText(data.text)
      .withFileId(data.fileId)
      .withFileDisplayName(data.fileDisplayName)
      .withSrcPrevice(data.srcPreview)
      .withTextAttrs(data.textAttrs)
      .buildFactory(data.type);

    const shapeConva = shapeFactory.createShape();
    shape.setKonvajsShape(shapeConva);
    shape.subscribeOnEvents();
    shape.id = data.ezId;
    this._shapes.set(data.ezId, shape);
    shape.konvajsShape.setAttr('ezId', data.ezId);
    shape.konvajsShape.setAttr('type', data.type);
    shape.setTextAttrs(data.textAttrs);
    this.shapes$.next(Array.from(this._shapes.values()));
    shape.toReadonlyState();
    if (withSnapshot) {
      this.addSnapshot(shape, shape.createHistorySnapshot(), SnapshotActon.addShape);
    }

    this.whiteBoard.mainLayerGet().add(shapeConva);
    this.whiteBoard.mainLayerGet().draw();

    shape.konvajsShape.setAttr('z', data.z);
    shape.konvajsShape.zIndex(data.z);

    const subscription = shape.notifyAttrsChanged$.subscribe(x => {
      this.whiteBoard.onObjectsUpdated$.next([x]);
    });

    this.subscriptions.add(subscription);
    if (!data.srcPreview && data.type === ShapeTypes.image) {
      this.whiteBoard.loadPreview$.next({
        shapeId: shape.konvajsShape.getAttr('ezId'),
        fileId: data.fileId,
        width: data.width,
      });
    }

    return shape;
  }

  lockShapes(ids: string[]) {
    ids.forEach(x => {
      if (this._shapes.has(x)) {
        const shape = this._shapes.get(x);
        shape.toWhiteBoardLockedShapeState();
      }
    });
  }

  lockShapesOrQueue(ids: string[]) {
    if (!this.sceneRendered) {
      ids.forEach(x => {
        this.shapeSceneQueue.enqueue({
          data: { ezId: x },
          action: ShapeSceneQueueActon.lock,
        });
      });
      return;
    }

    this.lockShapes(ids);
  }

  unlockShapesOrQueue(ids: string[]) {
    if (!this.sceneRendered) {
      ids.forEach(x => {
        this.shapeSceneQueue.enqueue({
          data: { ezId: x },
          action: ShapeSceneQueueActon.unlock,
        });
      });
      return;
    }

    this.unlockShapes(ids);
  }

  unlockShapes(ids: string[]) {
    ids.forEach(x => {
      if (this._shapes.has(x)) {
        const shape = this._shapes.get(x);
        shape.toReadonlyState();
      }
    });
  }

  buildSceneQueue() {
    SceneBuilder.build(this.shapeSceneQueue, this);
  }

  renderScene(scene: Scene | any) {
    console.warn('scene', scene);
    for (let i = 0; i < scene.scene.objects.length; i++) {
      const obj = scene.scene.objects[i];
      const attr = WhiteBoardConvertors.convertCreateObjectDescriptionToShape(obj);
      this.addShape(attr, false);
      console.warn('addShape', attr);
    }
    // eventLoop выполняет функции в очереди быстрей чем в канвасе появляются фигуры которые надо заблокировать
    const timeoutId = setTimeout(x => {
      const lockedIds = scene.scene.objects.filter(x => x.locked).map(x => x.oId);
      console.warn('lockedIds', lockedIds);
      if (lockedIds.length > 0) {
        this.lockShapes(lockedIds);
        console.warn('lockShapes done');
      }
      this.sceneRendered = true;
      this.buildSceneQueue();
      clearTimeout(timeoutId);
    }, 5);
  }

  updateShapeOrQueue(data: any) {
    if (!this.sceneRendered) {
      data?.objects.forEach(attrs => {
        const args = WhiteBoardConvertors.convertCreateObjectDescriptionToShape(attrs);
        this.shapeSceneQueue.enqueue({
          data: args,
          action: ShapeSceneQueueActon.editShape,
        });
      });

      return;
    }

    this.updateShapes(data);
  }

  updateShapes(x: any) {
    x?.objects.forEach(attrs => {
      const args = WhiteBoardConvertors.convertCreateObjectDescriptionToShape(attrs);
      this.updateShape(args);
    });
  }

  updateShape(args: any) {
    if (this._shapes.has(args.ezId)) {
      const shape = this._shapes.get(args.ezId);
      if (args.type === ShapeTypes.image) {
        const imageShape = shape as ImageShape;
        imageShape.updateImageSizes(args.width, args.height, true);
        shape.setAttrs(args);
      } else {
        // Порядок важен: сначала setTextAttrs, потом setText
        if (args.textAttrs) shape.setTextAttrs(args.textAttrs, false);
        shape.setAttrs(args);
        shape.setFillColor({ id: 0, code: args.fill }, false);
        shape.setStrokeColor({ id: 0, code: args.stroke }, false);
        shape.setStrokeWidth(args.strokeWidth, false);
      }
    }
  }

  /*
   * Если  WhiteBoard меняет свое состояние, то нужно регулровать состояния остальных объектов,  чтобы реагировать должным образом.
   * Для этого используем toWhiteBoardMoveState toWhiteBoardReadonlyState toWhiteBoardMouseState.
   */
  toWhiteBoardMoveState() {
    this.getUnlockerShapes().forEach(v => {
      v.toWhiteBoardMoveState();
    });
    this.clearTransform();
  }

  toWhiteBoardReadonlyState() {
    this.getUnlockerShapes().forEach(v => {
      if (v.whiteBoardShapeState.constructor.name !== WhiteBoardLockedShapeState.name) {
        v.toReadonlyState();
      }
    });
  }

  toWhiteBoardMouseState() {
    this.getUnlockerShapes().forEach(v => {
      v.toMouseState();
    });
  }

  toWhiteBoardShapeState() {
    this.getUnlockerShapes().forEach(v => {
      v.toReadonlyState();
    });
    this.clearTransform();
  }

  getUnlockerShapes() {
    return Array.from(this._shapes.values()).filter(
      x => x.whiteBoardShapeState.constructor.name !== WhiteBoardLockedShapeState.name,
    );
  }

  addSnapshot(
    shape: WhiteBoardShape,
    snapshot: IShapeSnapShot,
    snapshotActon: SnapshotActon,
    clearRedo: boolean = true,
  ) {
    let transformId = null;
    if (this.transform && this.multiSelectShapeQty > 1) {
      transformId = this.transform.getAttr('ezId');
      if (this.multiSelectCurrentTransformQty + 1 === this.multiSelectShapeQty) {
        this.multiSelectCurrentTransformQty = 0;
        this.transform.setAttr('ezId', uuidv4());
      } else {
        this.multiSelectCurrentTransformQty++;
      }
    }

    this.memoShaper.addSnapshot(shape, snapshot, snapshotActon, clearRedo, transformId);
  }

  undo() {
    this.memoShaper.undo();
    this.clearTransform();
  }

  redo() {
    this.memoShaper.redo();
    this.clearTransform();
  }

  moveFront() {
    if (this.transform) {
      for (let index = 0; index < this.transform.nodes().length; index++) {
        const element = this.transform.nodes()[index];
        if (element.index + 2 >= this.whiteBoard.mainLayerGet().children.length) {
          return;
        }
        element.moveToTop();
        this.transform.zIndex(element.zIndex());
        this.whiteBoard.onObjectsUpdated$.next([element]);
      }
    }
    this.changeShapesZIndex();
  }

  moveBack() {
    if (this.transform) {
      for (let index = 0; index < this.transform.nodes().length; index++) {
        const element = this.transform.nodes()[index];
        if (element.index === 0) {
          return;
        }
        element.moveToBottom();
        this.whiteBoard.onObjectsUpdated$.next([element]);
      }
    }
    this.changeShapesZIndex();
  }

  changeShapesZIndex() {
    const updatedChildren = this.whiteBoard
      .mainLayerGet()
      .children // Добавляем фильтр, чтобы не изменять индекс у TauchGroup и Transformer
      .filter(shape => !(shape instanceof Konva.Transformer) && shape.attrs?.ezId)
      .map(child => {
        child.setAttr('z', child.zIndex());
        return child;
      });
    this.whiteBoard.onObjectsUpdated$.next(updatedChildren);
  }

  public setShift(value: boolean) {
    this.withShift = value;
  }

  /*
   * Ищем выбранные фигруы и меняем цвет
   */
  setColor(color: ShapeColor, type: ShapeModifyType) {
    if (this.transform) {
      for (let index = 0; index < this.transform.nodes().length; index++) {
        const node = this.transform.nodes()[index];
        const ezId = node?.getAttr('ezId');
        if (ezId) {
          const shape = this._shapes.get(ezId);
          if (type === 'fill') {
            shape?.setFillColor(color);
            (shape.konvajsShape.attrs.type === ShapeTypes.sticker
              ? this.lastStickerAttrsConfigStorage
              : this.lastShapeAttrsConfigStorage
            ).setConfigProp('fill', color.code);
          }
          if (type === 'stroke') {
            shape?.setStrokeColor(color);
          }
        }
      }
      if (type === 'stroke') {
        this.lastShapeAttrsConfigStorage.setConfigProp('stroke', color.code);
      }
    }
  }

  editTextProperties(shape: WhiteBoardShape) {
    if (this.transform || shape) {
      // Инициируем выделение фигуры, если она не выделена после создания
      if (!this.transform && shape) {
        this.startSelect({ x: shape.konvajsShape.x(), y: shape.konvajsShape.y() }, false, false);
        this.endSelect();
      }

      if (!shape) {
        const node = this.transform?.nodes()[0];
        const ezId = node?.getAttr('ezId');
        shape = this._shapes.get(ezId);
      }
      this.shapeTextEditor.open(shape, this.whiteBoard.stage);
      this.shapeTextEditor.updateTextAriaFormatAttrs(shape.getTextAttrs());
      this.textEditing$.next(true);
      const currentLockedId = shape.konvajsShape.getAttr('ezId');
      this.whiteBoard.onObjectsLocked$.next([currentLockedId]);
    }
  }

  closeTextEditor() {
    if (this.shapeTextEditor.isEdit) {
      const currentLockedId = this.shapeTextEditor?.currentShape.konvajsShape.getAttr('ezId');
      this.whiteBoard.onObjectsUnLocked$.next([currentLockedId]);
      this.shapeTextEditor.close();
    }
  }

  updateTextAriaPosition() {
    if (this.shapeTextEditor.isEdit) {
      this.shapeTextEditor.updateTextAreaPosition(this.whiteBoard.stage);
    }
  }

  updateTextAriaRotation() {
    if (this.shapeTextEditor.isEdit) {
      this.shapeTextEditor.updateTextAreaRotation();
    }
  }

  updateTextAriaWidth() {
    if (this.shapeTextEditor.isEdit) {
      this.shapeTextEditor.updateTextAriaWidth();
      // Обновляем position при изменении ширины
      this.shapeTextEditor.updateTextAreaPosition(this.whiteBoard.stage);
    }
  }

  updateTextAriaHeight() {
    if (this.shapeTextEditor.isEdit) {
      this.shapeTextEditor.updateTextAriaHeight();
      // Обновляем position при изменении высоты
      this.shapeTextEditor.updateTextAreaPosition(this.whiteBoard.stage);
    }
  }

  updateTextAriaFormatAttrs(attrs: ShapeTextAttrsConfig) {
    if (!this.transform) {
      return;
    }
    if (this.shapeTextEditor.isEdit) {
      this.shapeTextEditor.updateTextAriaFormatAttrs(attrs);
    } else {
      for (let index = 0; index < this.transform.nodes().length; index++) {
        const node = this.transform.nodes()[index];
        const ezId = node?.getAttr('ezId');
        if (ezId) {
          const shape = this._shapes.get(ezId);
          shape?.setTextAttrs(attrs, true, true);
        }
      }
    }
  }

  editTextTransformShape() {
    if (this.transform) {
      const shapeWithText = this.transform
        .nodes()
        .find(x => x.attrs.type === ShapeTypes.sticker || x.attrs.type === ShapeTypes.text);
      if (shapeWithText) {
        const ezId = shapeWithText.getAttr('ezId');
        const wbShape = this._shapes.get(ezId);
        this.editTextProperties(wbShape);
      }
    }
  }

  setOpacity(opacity: number) {
    if (this.transform) {
      for (let index = 0; index < this.transform.nodes().length; index++) {
        const node = this.transform.nodes()[index];
        const ezId = node?.getAttr('ezId');
        if (ezId) {
          const shape = this._shapes.get(ezId);
          shape?.setOpacity(opacity);
        }
      }
      this.lastShapeAttrsConfigStorage.setConfigProp('opacity', opacity);
    }
  }

  setStrokeWidth(strokeWidth: number) {
    if (this.transform) {
      for (let index = 0; index < this.transform.nodes().length; index++) {
        const node = this.transform.nodes()[index];
        const ezId = node?.getAttr('ezId');
        if (ezId) {
          const shape = this._shapes.get(ezId);
          shape?.setStrokeWidth(strokeWidth);
        }
      }
      this.lastShapeAttrsConfigStorage.setConfigProp('strokeWidth', strokeWidth);
    }
  }

  buildWbShape(shapeTypes: ShapeTypes) {
    switch (shapeTypes) {
      case ShapeTypes.rect:
        return new RectShape(this);
      case ShapeTypes.triangle:
        return new TriangleShape(this);
      case ShapeTypes.ellipse:
        return new EllipseShape(this);
      case ShapeTypes.freeline:
        return new FreeLineShape(this);
      case ShapeTypes.text:
        return new TextFieldShape(this);
      case ShapeTypes.sticker:
        return new StickerShape(this);
      case ShapeTypes.file:
        return new FileShape(this);
      case ShapeTypes.image:
        return new ImageShape(this);
    }
  }

  getId(shapeName: string) {
    const random1 = this.getRandomIntInclusive(1, 256);
    const random2 = this.getRandomIntInclusive(1, 256);
    const num1 = random1.toString(2);
    const num2 = random2.toString(2);
    const random2Byte = Number(num1 + num2);
    return (
      this.whiteBoard?.wId +
      base36encode(this.whiteBoard?.currentUserId ?? 2) +
      base36encode(Math.round(new Date().getTime() / 1000)) +
      base36encode(random2Byte) +
      `_${shapeName}`
    );
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  subscribeTextEditor() {
    const opened$ = this.shapeTextEditor.opened$
      .pipe(
        tap((x: TextAriaChangedEvent) => {
          const shape = this._shapes.get(x.shapeId);
          if (shape) {
            shape.konvajsShape.hide();
          }
        }),
      )
      .subscribe();
    this.subscriptions.add(opened$);

    const closed$ = this.shapeTextEditor.closed$
      .pipe(
        tap((x: TextAriaChangedEvent) => {
          const shape = this._shapes.get(x.shapeId);
          if (!x.text.trim()) {
            this.removeShape(shape);
            return;
          }
          if (shape) {
            shape.setText(x.text);

            const equalityTextAttrs = Object.entries(x.attrs).every(([key, value]) => {
              const fontWeightKey = 'fontWeight';
              const textAlignKey = 'textAlign';
              const ableToCompare =
                !!shape.konvajsShape.attrs[key] ||
                (shape.konvajsShape.attrs[key] === '' && key !== fontWeightKey && key !== textAlignKey);
              if (ableToCompare) {
                return shape.konvajsShape.attrs[key] === value;
              }
              if (key === fontWeightKey) {
                return shape.konvajsShape.attrs.fontVariant === value;
              }
              if (key === textAlignKey) {
                return shape.konvajsShape.attrs.align === value;
              }
              return false;
            });

            if (!equalityTextAttrs) {
              shape.setTextAttrs(x.attrs, true, true);
            }

            shape.konvajsShape.show();
          }
        }),
      )
      .subscribe();
    this.subscriptions.add(closed$);

    const changed$ = this.shapeTextEditor.changed$
      .pipe(
        tap((x: TextAriaChangedEvent) => {
          const shape = this._shapes.get(x.shapeId);
          if (shape) {
            if (x.height) {
              shape.setHeight(x.height);
            }
          }
        }),
      )
      .subscribe();
    this.subscriptions.add(changed$);
  }

  addFile(data: UploadedFile) {
    const qty = Array.from(this._shapes.values()).filter(x => x.konvajsShape.attrs.type === ShapeTypes.file).length;
    let width = 100;
    let height = 100;

    if (data.type === EmbeddedFileTypeEnum.Picture) {
      width = BaseImageSize;
      const per = width / data.width;
      height = data.height * per;
    }

    const shape = this.addShape({
      type: data.type === EmbeddedFileTypeEnum.Document ? ShapeTypes.file : ShapeTypes.image,
      x: 600 + 120 * qty,
      y: 300,
      width: width,
      height: height,
      fileId: data.fileId,
      fileDisplayName: data.fileDisplayName,
      srcPreview: data.src,
      opacity: 1,
      scaleX: 1,
      scaleY: 1,
      ezId: this.getId(ShapeTypes.file),
    });
    this.whiteBoard.onObjectsCreated$.next(shape.konvajsShape);
  }

  addPreview(data: ImagePreviewData) {
    if (this._shapes.has(data.shapeId)) {
      const shape = this._shapes.get(data.shapeId);
      shape.addImage(data);
    } else {
      console.error('addPreview: shape not found');
    }
  }

  private isHaveIntersection(shape: Konva.Shape): boolean {
    const box: DOMRect = this.selectionRectangle.getClientRect();

    const shapeClientRect = shape.getClientRect();
    const isCrossing = Konva.Util.haveIntersection(box, shapeClientRect);
    const isPointClick = box.height <= 3 && box.width <= 3;

    if (isCrossing && shape.attrs?.points && isPointClick) {
      const strokeWidth = 15;

      const boundaryBox = {
        xMin: box.x - strokeWidth,
        xMax: box.x + strokeWidth,
        yMin: box.y - strokeWidth,
        yMax: box.y + strokeWidth,
      };
      const pointsEntries = flatPointsToXY(shape.attrs.points);
      return pointsEntries.some(([x, y]) => {
        x += shape.attrs.x;
        y += shape.attrs.y;
        return x > boundaryBox.xMin && x < boundaryBox.xMax && y > boundaryBox.yMin && y < boundaryBox.yMax;
      });
    }

    return isCrossing;
  }

  destroy() {
    const shapes = Array.from(this._shapes.values());
    shapes.forEach(x => {
      x.destroy();
    });
    this.subscriptions.unsubscribe();
  }
}
