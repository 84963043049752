import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper
      stepProgressText="2 из 6"
      [isSkippedToLastStep]="false"
    >
      <h1 class="ezteach-tutorial-tooltip__heading">Запись на занятие</h1>
      <p class="ezteach-tutorial-tooltip__info">
        Запись возможна в зависимости от свободных слотов в графике преподавателя. Также в профиле преподавателя можно
        отправить сообщение.
      </p>
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step2TooltipComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
