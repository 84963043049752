import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { ShapeTypes } from '../classes/white-board';
import { BaseImageSize } from '../utils/constants';

/*
 * Фабрика для создания форм
 */
export interface ShapeFactory {
  createShape(): Shape | Konva.Group;
}
/*
 * Фабрика квадарта
 */
export class RectFactory implements ShapeFactory {
  constructor(
    private x,
    private y,
    private width,
    private height,
    private fill,
    private stroke,
    private strokeWidth,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
  ) { }

  createShape() {
    return new Konva.Rect({
      type: ShapeTypes.rect,
      x: this.x,
      y: this.y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      width: this.width,
      height: this.height,
      stroke: this.stroke ?? '#212121',
      fill: this.fill,
      strokeWidth: this.strokeWidth ?? 1,
      lineCap: 'round',
      lineJoin: 'round',
      draggable: false,
      strokeScaleEnabled: false,
      rotation: this.rotation,
      opacity: this.opacity,
    });
  }
}

/*
 * Фабрика треугольника
 */
export class TriangleFactory implements ShapeFactory {
  constructor(
    private x,
    private y,
    private radius,
    private fill,
    private stroke,
    private strokeWidth,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
  ) { }
  createShape() {
    return new Konva.RegularPolygon({
      type: ShapeTypes.triangle,
      x: this.x,
      y: this.y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      sides: 3,
      radius: this.radius,
      fill: this.fill,
      stroke: this.stroke ?? '#212121',
      strokeWidth: this.strokeWidth ?? 2,
      draggable: false,
      strokeScaleEnabled: false,
      rotation: this.rotation,
      opacity: this.opacity,
    });
  }
}

/*
 * Фабрика элипса
 */
export class ElllipseFactory implements ShapeFactory {
  constructor(
    private x,
    private y,
    private radiusX,
    private radiusY,
    private fill,
    private stroke,
    private strokeWidth,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
  ) { }

  createShape() {
    return new Konva.Ellipse({
      type: ShapeTypes.ellipse,
      x: this.x,
      y: this.y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      radiusX: this.radiusX,
      radiusY: this.radiusY,
      fill: this.fill,
      stroke: this.stroke ?? '#212121',
      strokeWidth: this.strokeWidth ?? 2,
      draggable: false,
      strokeScaleEnabled: false,
      rotation: this.rotation,
      opacity: this.opacity,
    });
  }
}

/*
 * Фабрика кривой
 */
export class FreeLineFactory implements ShapeFactory {
  constructor(
    private points: number[],
    private stroke: string,
    private strokeWidth: number,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
    private x,
    private y,
  ) { }

  createShape() {
    return new Konva.Line({
      type: ShapeTypes.freeline,
      x: this.x,
      y: this.y,
      points: this.points,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      stroke: this.stroke ?? '#212121',
      strokeWidth: this.strokeWidth ?? 3,
      hitStrokeWidth: 20,
      lineCap: 'round',
      globalCompositeOperation: 'source-over',
      rotation: this.rotation,
      opacity: this.opacity,
    });
  }
}

/*
 * Фабрика текстового поля
 */
export class TextFieldFactory implements ShapeFactory {
  constructor(
    private x,
    private y,
    private text,
    private width,
    private height,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
    private textAttrs,
  ) {
    this.opacity = !!opacity ? opacity : 1;
  }

  createShape() {
    const initialValues = {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'normal',
      textDecoration: '',
      textAlign: 'left',
    };

    const textArea = new Konva.Text({
      text: this.text,
      type: ShapeTypes.text,
      x: this.x,
      y: this.y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      width: this.width,
      height: this.height,
      fontSize: this.textAttrs?.fontSize ?? 12,
      align: this.textAttrs?.textAlign ?? initialValues.textAlign,
      fontFamily: this.textAttrs?.fontFamily ?? initialValues.fontFamily,
      fontStyle: this.textAttrs?.fontStyle ?? initialValues.fontStyle,
      textDecoration: this.textAttrs?.textDecoration ?? initialValues.textDecoration,
      fontVariant: this.textAttrs?.fontWeight ?? initialValues.fontWeight,
      rotation: this.rotation,
      opacity: this.opacity,
      textAttrs: this.textAttrs,
    });

    /*textArea.add(
      new Konva.Tag({
        fill: this.fill,
        lineJoin: 'round'
      })
    )

    textArea.add(
      new Konva.Text({
        text: this.text,
        width: this.width,
        height: this.height
      })
    )*/
    return textArea;
  }
}
/*
 * Фабрика стикера
 */
export class StickerFactory implements ShapeFactory {
  constructor(
    private x,
    private y,
    private width,
    private fill,
    private text,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
    private stroke,
    private strokeWidth,
  ) {
    this.opacity = !!opacity ? opacity : 1;
  }

  createShape() {
    const originalAttrs = {
      x: this.x,
      y: this.y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      draggable: true,
      rotation: this.rotation,
      opacity: this.opacity,
    };

    const group = new Konva.Group(originalAttrs);

    const rect = new Konva.Rect({
      width: this.width,
      height: this.width,
      fill: this.fill ? this.fill : '#ffff00',
      cornerRadius: 5,
      shadowBlur: 10,
      shadowColor: 'grey',
      type: 'sticker',
      stroke: this.stroke,
      strokeWidth: this.strokeWidth ?? 0,
    });
    group.add(rect);

    const defaultText = 'Текст заметки...';
    const text = new Konva.Text({
      text: this.text || defaultText,
      width: this.width,
      height: this.width,
      align: 'center',
      verticalAlign: 'middle',
    });

    group.add(text);

    return group;
  }
}

/*
 * Фабрика файла
 */
export class FileFactory implements ShapeFactory {
  constructor(
    private x,
    private y,
    private width,
    private fileId,
    private fileDisplayName,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
  ) {
    this.width = !!width ? width : 100;
    this.scaleX = !!scaleX ? scaleX : 1;
    this.scaleY = !!scaleY ? scaleY : 1;
    this.opacity = !!opacity ? opacity : 1;
  }

  createShape() {
    const originalAttrs = {
      x: this.x,
      y: this.y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      draggable: true,
      rotation: this.rotation,
      opacity: this.opacity,
    };

    const group = new Konva.Group(originalAttrs);
    const rect = new Konva.Rect({
      width: this.width,
      height: this.width,
      fill: '#FFFFFF',
      offsetX: 5,
      offsetY: 5,
      cornerRadius: 5,
      shadowBlur: 10,
      shadowColor: 'grey',
      type: 'file',
    });
    group.add(rect);

    const text = new Konva.Text({
      text: this.fileDisplayName,
      y: this.width - 40,
      width: this.width,
      align: 'center',
      offsetX: 5,
      offsetY: 5,
    });

    let path = 'assets/img/svg/whiteboard/files/pdf.svg';
    const fid = this.fileId.split('.');
    if (fid.length > 1) {
      switch (fid[1]) {
        case 'pdf':
          path = 'assets/img/svg/whiteboard/files/pdf.svg';
          break;
        case 'docx':
          path = 'assets/img/svg/whiteboard/files/docx.svg';
          break;
        case 'xlsx':
          path = 'assets/img/svg/whiteboard/files/xlsx.svg';
          break;
        default:
          break;
      }
    }

    const imageObj = new Image();
    imageObj.onload = () => {
      const img = new Konva.Image({
        x: 20,
        y: 0,
        image: imageObj,
        width: 50,
        height: 50,
      });

      group.add(img);
    };
    imageObj.src = path;

    group.setAttr('fileId', this.fileId);
    group.setAttr('fileDisplayName', this.fileDisplayName);
    group.add(text);

    return group;
  }
}
export class ImageFactory implements ShapeFactory {
  constructor(
    private x,
    private y,
    private width,
    private height,
    private fileId,
    private srcPreview: string,
    private scaleX,
    private scaleY,
    private rotation,
    private opacity,
  ) {
    this.width = !!width ? width : BaseImageSize;
    this.height = !!height ? height : BaseImageSize;
    this.scaleX = !!scaleX ? scaleX : 1;
    this.scaleY = !!scaleY ? scaleY : 1;
    this.opacity = !!opacity ? opacity : 1;
  }

  createShape(): Group | Shape<ShapeConfig> {
    const originalAttrs = {
      x: this.x,
      y: this.y,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      draggable: true,
      rotation: this.rotation,
      opacity: this.opacity,
      width: this.width,
      height: this.height,
    };

    const group = new Konva.Group(originalAttrs);

    const rect = new Konva.Rect({
      width: this.width,
      height: this.height,
      fill: '#FFFFFF',
      offsetX: 1,
      offsetY: 1,
      cornerRadius: 5,
      shadowBlur: 10,
      shadowColor: 'grey',
      type: 'file',
    });
    group.add(rect);
    if (this.srcPreview) {
      const imageObj = new Image();
      imageObj.onload = () => {
        const img = new Konva.Image({
          x: 0,
          y: 0,
          image: imageObj,
          width: this.width,
          height: this.height,
        });

        group.add(img);
      };
      imageObj.src = this.srcPreview;
    }

    group.setAttr('fileId', this.fileId);

    return group;
  }
}
