import { Component, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import * as _ from 'lodash';
import { LessonsService } from '../../api/services/lessons.service';

@Component({
  selector: 'modal-reclamation',
  templateUrl: 'modal-reclamation.component.html',
})
export class ModalReclamation {
  load = false;
  reclamationControl = new UntypedFormControl();
  reclamationArray: UntypedFormArray;
  selectedReclamation: [number];
  message;
  reclamations = [];
  reclamationLists: { value: number; name: string; selected }[] = [
    { value: 1, name: this.translocoService.translate('Преподаватель вел себя некорректно'), selected: false },
    { value: 2, name: this.translocoService.translate('Преподаватель опоздал'), selected: false },
    { value: 3, name: this.translocoService.translate('Занятие не по теме'), selected: false },
    { value: 3, name: this.translocoService.translate('Плохое объяснение материала'), selected: false },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModalReclamation, ModalReclamationResult>,
    private lessonsService: LessonsService,
    private translocoService: TranslocoService,
  ) {
    this.reclamationArray = this.createReclamation();
    this.load = true;
  }

  createReclamation() {
    const arr = this.reclamationLists.map(reclamation => new UntypedFormControl(reclamation.selected || false));

    return new UntypedFormArray(arr);
  }

  send(id) {
    this.reclamations = _.filter(
      _.map(this.reclamationArray.value, (dur, i) => dur.value && this.reclamationLists[i].value),
      reclamation => {
        if (reclamation !== false) {
          return reclamation;
        }
      },
    );

    this.lessonsService
      .apiV1LessonsLessonIdReclamationsPost({
        lessonId: id,
        body: {
          ChatLessonId: id,
          Description: this.message,
          ReclamationItemIds: this.reclamations,
        },
      })
      .subscribe(response => {});

    this.dialogRef.close('finish');
  }

  finish() {
    this.dialogRef.close('finish');
  }

  close() {
    this.dialogRef.close('close');
  }
}

export type ModalReclamationResult = 'finish' | 'close';
