import Konva from 'konva';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { ImagePreviewData } from '../classes/objects-args';
import { VisualSceneObject } from '../classes/scene';
import { ShapeTypes, WhiteBoardMode } from '../classes/white-board';
import { ShapeColor } from '../components/shape-tools/shape-tools.component';
import { WhiteBoardConvertors } from '../convertors/shape.convertors';
import { BaseImageSize, QualityImagePreviewRate } from '../utils/constants';
import { WhiteBoardMouseState, WhiteBoardMovedState } from '../whiteboard-state';
import { SnapshotActon } from '../whiteboardhistory/memo-shaper';
import { ShapeTextAttrsConfig } from './shape-attrs-config';
import { ShapeManager } from './shape-manager';
import {
  WhiteBoardLockedShapeState,
  WhiteBoardMoveShapeState,
  WhiteBoardShapeMouseState,
  WhiteBoardShapeReadonlyState,
  WhiteBoardShapeState,
  WhiteBoardShapeTouchState,
} from './shape-state';

interface ShapeWithAttrs {
  type: string;
  attrs: string[];
}

/*
 * Класс описывающий базовые сущности и методы Shape.
 */
export abstract class WhiteBoardShape {
  /* Идентификатор формы */
  id: string;
  /* Ссылка на форму объекта konvajs */
  konvajsShape: any;
  /* Состояние Shape */
  whiteBoardShapeState: WhiteBoardShapeState;
  beforeLockOpacity = 1;
  beforeTouchStroke = null;
  beforeTouchStrokeWidth = null;
  protected readonly shapeManager: ShapeManager;
  subscriptions: Subscription = new Subscription();
  /* Срабатывает если свойства фигуры изменены */
  readonly notifyAttrsChanged$ = new Subject<any>();
  readonly lockShape$ = new Subject<boolean>();
  constructor(shapeManager: ShapeManager) {
    this.shapeManager = shapeManager;
    this.subscribe();
  }
  /*
   * Устанавливает объект konvajs
   */
  setKonvajsShape(konvajsShape: any) {
    this.konvajsShape = konvajsShape;
  }
  /*
   * Устанавливает состояние Shape
   */
  setState(whiteBoardShapeState: WhiteBoardShapeState) {
    this.whiteBoardShapeState = whiteBoardShapeState;
    this.whiteBoardShapeState.setShape(this);
  }
  public getShapeManager(): ShapeManager {
    return this.shapeManager;
  }
  public setStrokeColor(color: ShapeColor, notify: boolean = true): void {
    this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    if (this.konvajsShape.setStroke !== undefined) {
      this.konvajsShape.setStroke(color?.code);
    }
    this.notifyAttrs(notify);
  }
  public setFillColor(color: ShapeColor, notify: boolean = true): void {
    this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    if (this.konvajsShape.setFill !== undefined) {
      this.konvajsShape.setFill(color?.code);
    }
    this.notifyAttrs(notify);
  }

  public setOpacity(opacity: number, notify: boolean = true): void {
    this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    this.konvajsShape.setOpacity(opacity);
    this.notifyAttrs(notify);
  }
  public setStrokeWidth(strokeWidth: number, notify: boolean = true): void {
    this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    if (this.konvajsShape.setStrokeWidth !== undefined) {
      this.konvajsShape.setStrokeWidth(strokeWidth);
    }
    this.notifyAttrs(notify);
  }

  private notifyAttrs(notify: boolean) {
    if (notify) {
      this.notifyAttrsChanged$.next(this.konvajsShape);
      this.lockShape$.next(true);
    }
  }

  public setText(text: string): void { }

  public setHeight(height: number): void { }

  public setAttrs(attrs: any) {
    this.konvajsShape.setAttrs(attrs);
    this.konvajsShape.zIndex(attrs.z);
    if ((this.whiteBoardShapeState as any).constructor.name === WhiteBoardLockedShapeState.name) {
      const { opacity } = attrs;
      this.beforeLockOpacity = opacity;
      this.konvajsShape.setOpacity(0.2);
      this.konvajsShape.draggable(false);
    }
    if ((this.shapeManager.whiteBoard.whiteBoardState as any).constructor.name === WhiteBoardMovedState.name) {
      this.konvajsShape.draggable(false);
    }
  }

  public setTextAttrs(attrs: ShapeTextAttrsConfig, notify: boolean = true, withSnapshot = false) {
    if (withSnapshot) {
      this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    }

    if (this.konvajsShape instanceof Konva.Text) {
      this.konvajsShape.fontFamily(attrs.fontFamily);
      this.konvajsShape.fontSize(attrs.fontSize);
      this.konvajsShape.fontStyle(attrs.fontStyle);
      this.konvajsShape.fontVariant(attrs.fontWeight);
      this.konvajsShape.textDecoration(attrs.textDecoration);
      this.konvajsShape.align(attrs.textAlign);
      if (notify) {
        this.notifyAttrsChanged$.next(this.konvajsShape);
      }
    }
    if (attrs?.fontSize) {
      this.konvajsShape.attrs.fontSize = attrs.fontSize;
    }
  }

  public getTextAttrs(): ShapeTextAttrsConfig {
    if (this.konvajsShape instanceof Konva.Text) {
      const konva = this.konvajsShape as Konva.Text;
      const attrs = WhiteBoardConvertors.convertToShapeTextAttrs(konva);
      return attrs;
    }
    return null;
  }

  public addImage(data: ImagePreviewData) {
    const width = data.width / QualityImagePreviewRate ?? BaseImageSize;
    const height = data.height / QualityImagePreviewRate ?? BaseImageSize;
    const imageObj = new Image();
    imageObj.onload = () => {
      let imageShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Image.name) as Konva.Image;
      const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name) as Konva.Rect;
      if (imageShape) {
        imageShape.image(imageObj);
      } else {
        imageShape = new Konva.Image({ x: 0, y: 0, image: imageObj });
        this.konvajsShape.add(imageShape);
      }
      imageShape.setAttrs({ width, height });
      rectShape?.setAttrs({ width, height });
      this.konvajsShape.setAttrs({ width, height });
      imageObj.width = width;
      imageObj.height = height;
    };
    imageObj.src = data.src;
  }

  public abstract subscribeOnEvents(): void;
  public abstract createHistorySnapshot(): IShapeSnapShot;
  public abstract restoreHistorySnapshot(value: IShapeSnapShot): void;
  /*
   * Переключает состояние Shape в режим чтение
   */
  public toReadonlyState(): void {
    this.konvajsShape.draggable(false);
    if ((this.whiteBoardShapeState as any).constructor.name === WhiteBoardShapeTouchState.name) {
      this.konvajsShape.setStrokeWidth(this.beforeTouchStrokeWidth);
      this.konvajsShape.setStroke(this.beforeTouchStroke);
    }
    if ((this.whiteBoardShapeState as any).constructor.name === WhiteBoardLockedShapeState.name) {
      this.konvajsShape.setOpacity(this.beforeLockOpacity);
    }
    this.setState(new WhiteBoardShapeReadonlyState());
  }
  /*
   * Переключает состояние Shape в работа с мышью
   */
  public toMouseState(): void {
    this.konvajsShape.draggable(true);
    this.setState(new WhiteBoardShapeMouseState());
  }

  public toWhiteBoardMoveState(): void {
    this.konvajsShape.draggable(false);
    this.setState(new WhiteBoardMoveShapeState());
  }

  public toWhiteBoardLockedShapeState(): void {
    const { opacity } = this.konvajsShape.attrs;
    this.beforeLockOpacity = opacity;
    this.konvajsShape.setOpacity(0.2);
    this.konvajsShape.draggable(false);
    this.setState(new WhiteBoardLockedShapeState());
  }

  public toTouchState() {
    this.beforeTouchStroke = this.konvajsShape.attrs.stroke;
    this.beforeTouchStrokeWidth = this.konvajsShape.attrs.strokeWidth;
    this.konvajsShape.setStroke('#678AFF');
    this.konvajsShape.setStrokeWidth(5);
    this.setState(new WhiteBoardShapeTouchState());
  }

  public get shouldCancelEvents() {
    return (
      !this.shapeManager.whiteBoard.isOwner && this.shapeManager.whiteBoard.whiteBoardMode === WhiteBoardMode.inside
    );
  }

  private subscribe() {
    const shapeLock = this.lockShape$.pipe(
      distinctUntilChanged(),
      filter(l => !!l),
    );
    const shapeUnlock = this.lockShape$.pipe(
      filter(l => !!l),
      debounceTime(1000),
      tap(() => this.lockShape$.next(false)),
    );

    const lock = shapeLock.subscribe(() => {
      const currentLockedId = this.konvajsShape.getAttr('ezId');
      this.getShapeManager().whiteBoard.onObjectsLocked$.next([currentLockedId]);
    });
    const unlock = shapeUnlock.subscribe(() => {
      const currentLockedId = this.konvajsShape.getAttr('ezId');
      this.getShapeManager().whiteBoard.onObjectsUnLocked$.next([currentLockedId]);
    });

    this.subscriptions.add(lock);
    this.subscriptions.add(unlock);
  }

  public destroy() {
    this.subscriptions.unsubscribe();
  }
}

export abstract class WhiteBoardShapeGroup extends WhiteBoardShape {
  protected attrsByShapes: ShapeWithAttrs[];

  protected constructor(shapeManager: ShapeManager) {
    super(shapeManager);
  }

  private pickAttrs(keys: string[], attrs: VisualSceneObject): {} {
    return keys.reduce((accum, current) => {
      accum[current] = attrs[current];
      return accum;
    }, {});
  }

  private getShapeByType(groupShape: Konva.Group, type: string): Konva.Group | Konva.Shape {
    if (groupShape.constructor.name === type) {
      return groupShape;
    }

    return groupShape.children.find(shape => shape.constructor.name === type);
  }

  private getAttrsList(type: string): string[] {
    return this.attrsByShapes.filter(x => x.type === type).map(s => s.attrs)[0];
  }

  setAttrs(attrs: VisualSceneObject): void {
    this.attrsByShapes.forEach(shape => this.setShapeAttr(shape.type, attrs));

    if ((this.whiteBoardShapeState as any).constructor.name === WhiteBoardLockedShapeState.name) {
      const { opacity } = attrs;
      this.beforeLockOpacity = opacity;
      this.konvajsShape.setOpacity(0.2);
      this.konvajsShape.draggable(false);
    }
    if ((this.shapeManager.whiteBoard.whiteBoardState as any).constructor.name === WhiteBoardMovedState.name) {
      this.konvajsShape.draggable(false);
    }
    this.konvajsShape.zIndex(attrs.z);
  }

  private setShapeAttr(type, attrs: VisualSceneObject): void {
    const shape = this.getShapeByType(this.konvajsShape, type);
    const pickedParams = this.pickAttrs(this.getAttrsList(type), attrs);
    shape.setAttrs(pickedParams);
  }
}

/*
 * Класс описывает форму - квадрат
 */
export class RectShape extends WhiteBoardShape {
  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();
  }
  public restoreHistorySnapshot(value: RectShapeSnapShot) {
    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());
    this.konvajsShape.setWidth(value.getW());
    this.konvajsShape.setHeight(value.getH());
    this.konvajsShape.setScaleX(value.getScaleX());
    this.konvajsShape.setScaleY(value.getScaleY());
    this.konvajsShape.setRotation(value.getRotation());
    this.konvajsShape.setSkewX(value.getSkewX());
    this.konvajsShape.setSkewY(value.getSkewY());

    this.konvajsShape.setFill(value.getFill());
    this.konvajsShape.setStroke(value.getStroke());
    this.konvajsShape.setOpacity(value.getOpacity());
    this.konvajsShape.setStrokeWidth(value.getStrokeWidth());
    this.konvajsShape.zIndex(this.konvajsShape.attrs.z);
    this.notifyAttrsChanged$.next(this.konvajsShape);
  }
  public createHistorySnapshot(): RectShapeSnapShot {
    const { x, y, width, height, scaleX, scaleY, rotation, skewX, skewY, fill, stroke, opacity, strokeWidth } =
      this.konvajsShape.attrs;
    const snapshot = new RectShapeSnapShot(
      x,
      y,
      width,
      height,
      scaleX,
      scaleY,
      rotation,
      skewX,
      skewY,
      fill,
      stroke,
      opacity,
      strokeWidth,
    );
    return snapshot;
  }
  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('click', e => {
      this.whiteBoardShapeState.handleClick(e);
    });
    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
  }
}

/*
 * Класс описывает форму - треугольник
 */
export class TriangleShape extends WhiteBoardShape {
  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();
  }
  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('click', e => {
      this.whiteBoardShapeState.handleClick(e);
    });
    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
  }
  public createHistorySnapshot(): IShapeSnapShot {
    const { x, y, sides, radius, scaleX, scaleY, rotation, skewX, skewY, fill, stroke, opacity, strokeWidth } =
      this.konvajsShape.attrs;
    const snapshot = new TriangleShapeSnapShot(
      x,
      y,
      sides,
      radius,
      scaleX,
      scaleY,
      rotation,
      skewX,
      skewY,
      fill,
      stroke,
      opacity,
      strokeWidth,
    );
    return snapshot;
  }
  public restoreHistorySnapshot(value: IShapeSnapShot): void {
    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());
    this.konvajsShape.setSides(value.getSides());
    this.konvajsShape.setRadius(value.getRadius());
    this.konvajsShape.setScaleX(value.getScaleX());
    this.konvajsShape.setScaleY(value.getScaleY());
    this.konvajsShape.setRotation(value.getRotation());
    this.konvajsShape.setSkewX(value.getSkewX());
    this.konvajsShape.setSkewY(value.getSkewY());
    this.konvajsShape.setFill(value.getFill());
    this.konvajsShape.setStroke(value.getStroke());
    this.konvajsShape.setOpacity(value.getOpacity());
    this.konvajsShape.setStrokeWidth(value.getStrokeWidth());
    this.konvajsShape.zIndex(this.konvajsShape.attrs.z);
    this.notifyAttrsChanged$.next(this.konvajsShape);
  }
}

/*
 * Класс описывает форму - Ellipse
 */
export class EllipseShape extends WhiteBoardShape {
  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('click', e => {
      this.whiteBoardShapeState.handleClick(e);
    });
    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
  }
  public createHistorySnapshot(): IShapeSnapShot {
    const {
      x,
      y,
      sides,
      scaleX,
      scaleY,
      radiusX,
      radiusY,
      rotation,
      skewX,
      skewY,
      fill,
      stroke,
      opacity,
      strokeWidth,
    } = this.konvajsShape.attrs;
    const snapshot = new EllipseShapeSnapShot(
      x,
      y,
      sides,
      radiusX,
      radiusY,
      scaleX,
      scaleY,
      rotation,
      skewX,
      skewY,
      fill,
      stroke,
      opacity,
      strokeWidth,
    );
    return snapshot;
  }
  public restoreHistorySnapshot(value: IShapeSnapShot): void {
    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());
    this.konvajsShape.setRadiusX(value.getRadiusX());
    this.konvajsShape.setRadiusY(value.getRadiusY());
    this.konvajsShape.setScaleX(value.getScaleX());
    this.konvajsShape.setScaleY(value.getScaleY());
    this.konvajsShape.setRotation(value.getRotation());
    this.konvajsShape.setSkewX(value.getSkewX());
    this.konvajsShape.setSkewY(value.getSkewY());
    this.konvajsShape.setFill(value.getFill());
    this.konvajsShape.setStroke(value.getStroke());
    this.konvajsShape.setOpacity(value.getOpacity());
    this.konvajsShape.setStrokeWidth(value.getStrokeWidth());
    this.konvajsShape.zIndex(this.konvajsShape.attrs.z);
    this.notifyAttrsChanged$.next(this.konvajsShape);
  }
  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();
  }
}

/*
 * Класс описывает линию из массива точек
 */
export class FreeLineShape extends WhiteBoardShape {
  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('click', e => {
      this.whiteBoardShapeState.handleClick(e);
    });
    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
  }
  public createHistorySnapshot(): IShapeSnapShot {
    const { points, lineCap, stroke, strokeWidth, globalCompositeOperation, scaleX, scaleY, opacity, x, y, rotation } =
      this.konvajsShape.attrs;
    const snapshot = new FreeLineShapeSnapShot(
      points,
      lineCap,
      stroke,
      strokeWidth,
      globalCompositeOperation,
      scaleX,
      scaleY,
      opacity,
      x,
      y,
      rotation,
    );
    return snapshot;
  }
  public restoreHistorySnapshot(value: IShapeSnapShot): void {
    this.konvajsShape.setPoints(value.getPoints());
    this.konvajsShape.setLineCap(value.getLineCap());
    this.konvajsShape.setGlobalCompositeOperation(value.getGlobalCompositeOperation());
    this.konvajsShape.setStroke(value.getStroke());
    this.konvajsShape.setStrokeWidth(value.getStrokeWidth());
    this.konvajsShape.zIndex(this.konvajsShape.attrs.z);
    this.konvajsShape.scaleX(value.getScaleX());
    this.konvajsShape.scaleY(value.getScaleY());
    this.konvajsShape.opacity(value.getOpacity());
    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());
    this.konvajsShape.setRotation(value.getRotation());
    this.notifyAttrsChanged$.next(this.konvajsShape);
  }
  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();
  }
}

export class TextFieldShape extends WhiteBoardShape {
  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();
  }
  public restoreHistorySnapshot(value: TextFieldShapeSnapshot) {
    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());
    this.konvajsShape.setWidth(value.getW());
    this.konvajsShape.setHeight(value.getH());
    this.konvajsShape.setScaleX(value.getScaleX());
    this.konvajsShape.setScaleY(value.getScaleY());
    this.konvajsShape.setRotation(value.getRotation());
    this.konvajsShape.setSkewX(value.getSkewX());
    this.konvajsShape.setSkewY(value.getSkewY());

    this.konvajsShape.setFill(value.getFill());
    this.konvajsShape.setStroke(value.getStroke());
    this.konvajsShape.setOpacity(value.getOpacity());
    this.konvajsShape.setStrokeWidth(value.getStrokeWidth());
    this.konvajsShape.setText(value.getText());

    const textAttrs: ShapeTextAttrsConfig = {
      fontFamily: value.getFontFamily(),
      fontSize: value.getFontSize(),
      fontStyle: value.getFontStyle(),
      textDecoration: value.getTextDecoration(),
      textAlign: value.getAlign(),
      fontWeight: value.getFontVariant(),
    };
    this.setTextAttrs(textAttrs, false);

    this.konvajsShape.zIndex(this.konvajsShape.attrs.z);

    this.notifyAttrsChanged$.next(this.konvajsShape);
  }
  public createHistorySnapshot(): TextFieldShapeSnapshot {
    const {
      x,
      y,
      width,
      height,
      scaleX,
      scaleY,
      rotation,
      skewX,
      skewY,
      fill,
      stroke,
      opacity,
      strokeWidth,
      text,
      textDecoration,
      fontFamily,
      fontSize,
      fontStyle,
      fontVariant,
      align,
    } = this.konvajsShape.attrs;
    const snapshot = new TextFieldShapeSnapshot(
      x,
      y,
      width,
      height,
      scaleX,
      scaleY,
      rotation,
      skewX,
      skewY,
      fill,
      stroke,
      opacity,
      strokeWidth,
      text,
      textDecoration,
      fontFamily,
      fontSize,
      fontStyle,
      fontVariant,
      align,
    );
    return snapshot;
  }
  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('click', e => { });

    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('dblclick', e => { });

    this.konvajsShape.on('transform', e => {
      this.konvajsShape.setAttrs({
        width: this.konvajsShape.width() * this.konvajsShape.scaleX(),
        height: this.konvajsShape.height() * this.konvajsShape.scaleY(),
        scaleX: 1,
        scaleY: 1,
      });
      this.shapeManager.updateTextAriaWidth();
      this.shapeManager.updateTextAriaHeight();
      this.shapeManager.updateTextAriaPosition();
      this.shapeManager.updateTextAriaRotation();
    });

    const click$ = fromEvent(this.konvajsShape, 'click').pipe(
      map(x => {
        return { e: x, type: 'click' };
      }),
    );
    const dbClick$ = fromEvent(this.konvajsShape, 'dblclick').pipe(
      map(x => {
        return { e: x, type: 'dblclick' };
      }),
    );

    const clicker$ = merge(click$, dbClick$)
      .pipe(
        debounceTime(150),
        tap(x => {
          if (
            (this.shapeManager.whiteBoard.whiteBoardMode === WhiteBoardMode.self ||
              this.shapeManager.whiteBoard.isOwner) &&
            this.shapeManager.whiteBoard.whiteBoardState instanceof WhiteBoardMouseState
          ) {
            if (x.type === 'click') {
              this.shapeManager.forceSelect(this.konvajsShape.attrs.x, this.konvajsShape.attrs.y);
            }
            if (x.type === 'dblclick') {
              this.shapeManager.editTextProperties(this);
            }
          }
        }),
      )
      .subscribe();

    this.subscriptions.add(clicker$);
  }

  // переопределяем базовый метод, потому что в konvajsShape у нас Group
  setFillColor(color: ShapeColor, notify: boolean = true) {
    this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    this.konvajsShape.attrs.fill = color.code;
    if (notify) {
      this.notifyAttrsChanged$.next(this.konvajsShape);
    }
  }

  setText(text: string) {
    this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    this.konvajsShape.text(text);
    this.notifyAttrsChanged$.next(this.konvajsShape);
  }

  setHeight(height: number) {
    this.konvajsShape.setHeight(height);
  }
}

export class StickerShape extends WhiteBoardShapeGroup {
  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();

    this.attrsByShapes = [
      { type: Konva.Group.name, attrs: ['x', 'y', 'scaleX', 'scaleY', 'rotation', 'opacity'] },
      { type: Konva.Rect.name, attrs: ['width', 'height', 'fill', 'stroke', 'strokeWidth'] },
      { type: Konva.Text.name, attrs: ['text', 'width', 'height'] },
    ];
  }

  public restoreHistorySnapshot(value: StickerShapeSnapShot) {
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    const textShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Text.name);

    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());
    this.konvajsShape.setScaleX(value.getScaleX());
    this.konvajsShape.setScaleY(value.getScaleY());
    this.konvajsShape.setRotation(value.getRotation());

    if (rectShape) {
      rectShape.setWidth(value.getW());
      rectShape.setHeight(value.getH());
      rectShape.setFill(value.getFill());
    } else {
      this.konvajsShape.setAttrs({
        width: value.getW(),
        height: value.getH(),
        fill: value.getFill(),
      });
    }

    if (textShape) {
      textShape.setWidth(value.getW());
      textShape.setHeight(value.getH());
      textShape.setText(value.getText());
    } else {
      this.konvajsShape.setAttrs({
        width: value.getW(),
        height: value.getH(),
        text: value.getText(),
      });
    }

    this.notifyAttrsChanged$.next(this.konvajsShape);
  }
  public createHistorySnapshot(): StickerShapeSnapShot {
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    const textShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Text.name);

    const { x, y, scaleX, scaleY, rotation } = this.konvajsShape.attrs;
    const { width, height, fill } = rectShape ? rectShape.attrs : this.konvajsShape.attrs;
    const { text } = textShape ? textShape.attrs : this.konvajsShape.attrs;

    const snapshot = new StickerShapeSnapShot(
      x,
      y,
      width,
      height,
      scaleX,
      scaleY,
      rotation,
      undefined,
      undefined,
      fill,
      undefined,
      undefined,
      undefined,
      text,
    );
    return snapshot;
  }

  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('click', e => { });
    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);
      this.notifyAttrsChanged$.next(this.konvajsShape);
    });
    this.konvajsShape.on('transform', e => {
      this.konvajsShape.children.forEach(x => {
        this.konvajsShape.children
          .filter(x => [Konva.Rect.name, Konva.Text.name].includes(x.constructor.name))
          .forEach(x =>
            x.setAttrs({
              width: x.width() * this.konvajsShape.scaleX(),
              height: x.height() * this.konvajsShape.scaleY(),
              scaleX: 1,
              scaleY: 1,
            }),
          );
        this.getShapeManager().updateTextAriaWidth();
        this.getShapeManager().updateTextAriaHeight();
        this.konvajsShape.setAttrs({
          width: this.konvajsShape.width() * this.konvajsShape.scaleX(),
          height: this.konvajsShape.height() * this.konvajsShape.scaleY(),
          scaleX: 1,
          scaleY: 1,
        });
      });
    });
    this.konvajsShape.on('dblclick', e => { });

    const click$ = fromEvent(this.konvajsShape, 'click').pipe(
      map(x => {
        return { e: x, type: 'click' };
      }),
    );
    const dbClick$ = fromEvent(this.konvajsShape, 'dblclick').pipe(
      map(x => {
        return { e: x, type: 'dblclick' };
      }),
    );

    const clicker$ = merge(click$, dbClick$)
      .pipe(
        debounceTime(150),
        tap(x => {
          if (
            (this.shapeManager.whiteBoard.whiteBoardMode === WhiteBoardMode.self ||
              this.shapeManager.whiteBoard.isOwner) &&
            this.shapeManager.whiteBoard.whiteBoardState instanceof WhiteBoardMouseState
          ) {
            if (x.type === 'click') {
              this.shapeManager.forceSelect(this.konvajsShape.attrs.x, this.konvajsShape.attrs.y);
            }
            if (x.type === 'dblclick') {
              this.shapeManager.editTextProperties(this);
            }
          }
        }),
      )
      .subscribe();

    this.subscriptions.add(clicker$);
  }
  setHeight(height: number) {
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    rectShape.setHeight(height);
  }

  setText(text: string) {
    this.shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    const textShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Text.name);
    textShape.text(text);
    this.notifyAttrsChanged$.next(this.konvajsShape);
  }

  setStrokeWidth(strokeWidth: number, notify: boolean = true): void {
    const shapeManager = this.getShapeManager();
    shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    rectShape.setStrokeWidth(strokeWidth);
    if (notify) {
      this.notifyAttrsChanged$.next(this.konvajsShape);
    }
  }

  setStrokeColor(color: ShapeColor, notify: boolean = true): void {
    const shapeManager = this.getShapeManager();
    shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    rectShape.setStroke(color?.code);
    if (notify) {
      this.notifyAttrsChanged$.next(this.konvajsShape);
    }
  }

  setFillColor(color: ShapeColor, notify: boolean = true) {
    const shapeManager = this.getShapeManager();
    shapeManager.getMemoShaper().addSnapshot(this, this.createHistorySnapshot(), SnapshotActon.editShape);
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    rectShape.setFill(color?.code);
    if (notify) {
      this.notifyAttrsChanged$.next(this.konvajsShape);
    }
  }
}

/*
 * Класс описывает файл
 */
export class FileShape extends WhiteBoardShape {
  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      if (!this.shouldCancelEvents) {
        this.notifyAttrsChanged$.next(this.konvajsShape);
      }
    });
    this.konvajsShape.on('click', e => {
      this.whiteBoardShapeState.handleClick(e);
    });
    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);
      if (!this.shouldCancelEvents) {
        this.notifyAttrsChanged$.next(this.konvajsShape);
      }
    });
    this.konvajsShape.on('dragmove', e => {
      this.whiteBoardShapeState.handleDragMove(e);
    });
  }
  public createHistorySnapshot(): IShapeSnapShot {
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    // Если доска работает - удалить
    //const textShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Text.name);
    const { x, y } = this.konvajsShape.attrs;

    const { width, height } = rectShape ? rectShape.attrs : this.konvajsShape.attrs;
    // Если доска работает - удалить
    // const { text, fileId } = textShape ? textShape.attrs : this.konvajsShape.attrs;
    const { text, fileId, fileDisplayName } = this.konvajsShape.attrs;

    return new FileShapeSnapShot(x, y, width, height, text, text ?? fileId, fileDisplayName);
  }
  public restoreHistorySnapshot(value: IShapeSnapShot): void {
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());

    if (rectShape) {
      rectShape.setWidth(value.getW());
      rectShape.setHeight(value.getH());
    } else {
      this.konvajsShape.setAttrs({
        width: value.getW(),
        height: value.getH(),
      });
    }

    this.notifyAttrsChanged$.next(this.konvajsShape);
  }

  public setFillColor(color: ShapeColor): void { }
  public setStrokeColor(color: ShapeColor): void { }
  public setStrokeWidth(width: number): void { }
  public setOpacity(opacity: number): void { }

  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();
  }
}
/*
 * Класс описывает файл
 */
export class ImageShape extends WhiteBoardShape {
  public subscribeOnEvents(): void {
    this.konvajsShape.on('dragend', e => {
      this.whiteBoardShapeState.handleDragEnd(e);
      if (!this.shouldCancelEvents) {
        this.notifyAttrsChanged$.next(this.konvajsShape);
      }
    });
    this.konvajsShape.on('click', e => {
      this.whiteBoardShapeState.handleClick(e);
    });
    this.konvajsShape.on('mouseup', e => {
      this.whiteBoardShapeState.handleMouseup(e);
    });
    this.konvajsShape.on('mousedown', e => {
      this.whiteBoardShapeState.handleMousedown(e);
    });
    this.konvajsShape.on('dragstart', e => {
      this.whiteBoardShapeState.handleDragStart(e);
    });
    this.konvajsShape.on('transformstart', e => {
      this.whiteBoardShapeState.handleTransformstart(e);
    });
    this.konvajsShape.on('transformend', e => {
      this.whiteBoardShapeState.handleTransformend(e);

      const newWidth = Math.round(this.konvajsShape.width() * this.konvajsShape.scaleX());
      const newHeight = Math.round(this.konvajsShape.height() * this.konvajsShape.scaleY());
      this.updateImageSizes(newWidth, newHeight, true);
      this.shapeManager.forceSelect(this.konvajsShape.attrs.x, this.konvajsShape.attrs.y);

      if (!this.shouldCancelEvents) {
        this.notifyAttrsChanged$.next(this.konvajsShape);
      }
    });
    this.konvajsShape.on('dragmove', e => {
      this.whiteBoardShapeState.handleDragMove(e);
    });
  }

  /*
   * Если в процессе трансформации или при получении по сокетам меняются размеры картинки
   * нужно обновить размеры и запросить картинку с новым разрешением
   */
  public updateImageSizes(newWidth: number, newHeight: number, isLoadPreview: boolean) {
    if (this.konvajsShape.width() !== newWidth || this.konvajsShape.height() !== newHeight) {
      this.konvajsShape.setAttrs({
        width: newWidth,
        height: newHeight,
        scaleX: 1,
        scaleY: 1,
      });

      const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
      const imageShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Image.name);
      const diffHeight = imageShape.attrs.height - rectShape.attrs.height;

      (rectShape ?? this.konvajsShape).setAttrs({
        width: newWidth,
        height: newHeight,
        scaleX: 1,
        scaleY: 1,
      });
      (imageShape ?? this.konvajsShape).setAttrs({
        width: newWidth,
        height: newHeight + diffHeight,
        scaleX: 1,
        scaleY: 1,
      });

      if (isLoadPreview) {
        this.shapeManager.whiteBoard.loadPreview$.next({
          shapeId: this.konvajsShape.getAttr('ezId'),
          fileId: this.konvajsShape.attrs.fileId,
          width: this.konvajsShape.attrs.width,
        });
      }
    }
  }

  public createHistorySnapshot(): IShapeSnapShot {
    const rectShape = this.konvajsShape.children.find(x => x.constructor.name === Konva.Rect.name);
    const { x, y, fileId } = this.konvajsShape.attrs;
    const { width, height } = rectShape ? rectShape.attrs : this.konvajsShape.attrs;
    return new FileShapeSnapShot(x, y, width, height, '', fileId, undefined);
  }
  public restoreHistorySnapshot(value: IShapeSnapShot): void {
    this.konvajsShape.setX(value.getX());
    this.konvajsShape.setY(value.getY());

    this.updateImageSizes(value.getW(), value.getH(), false);

    this.notifyAttrsChanged$.next(this.konvajsShape);
  }

  public setFillColor(color: ShapeColor): void { }
  public setStrokeColor(color: ShapeColor): void { }
  public setStrokeWidth(width: number): void { }
  public setOpacity(opacity: number): void { }

  constructor(shapeManager: ShapeManager) {
    super(shapeManager);
    this.whiteBoardShapeState = new WhiteBoardShapeReadonlyState();
  }
}

export interface IShapeSnapShot {
  getShape(): ShapeTypes;
  getDate(): Date;
  getX(): number;
  getY(): number;
  getW(): number;
  getH(): number;
  getScaleX(): number;
  getScaleY(): number;
  getRadius(): number;
  getSides(): number;
  getRadiusX(): number;
  getRadiusY(): number;
  getRotation(): number;
  getSkewX(): number;
  getSkewY(): number;
  getFill(): string;
  getStroke(): string;
  getOpacity(): number;
  getStrokeWidth(): number;
  getPoints(): number[];
  getLineCap(): string;
  getGlobalCompositeOperation(): string;
  getText(): string;
  getFileId(): string;
  getAlign(): string;
  getTextDecoration(): string;
  getFontFamily(): string;
  getFontSize(): number;
  getFontStyle(): string;
  getFontVariant(): string;
  getFileDisplayName(): string;
}

export class RectShapeSnapShot implements IShapeSnapShot {
  date: Date;
  _x: number;
  _y: number;
  _w: number;
  _h: number;
  _scaleX: number;
  _scaleY: number;
  _rotation: number;
  _skewX: any;
  _skewY: any;
  _fill: string;
  _stroke: string;
  _opacity: number;
  _strokeWidth: number;
  constructor(x, y, w, h, scaleX, scaleY, rotation, skewX, skewY, fill, stroke, opacity, strokeWidth) {
    this._x = x;
    this._y = y;
    this._w = w;
    this._h = h;
    this._scaleX = scaleX;
    this._scaleY = scaleY;
    this._rotation = rotation;
    this._skewX = skewX;
    this._skewY = skewY;
    this.date = new Date();
    this._fill = fill;
    this._stroke = stroke;
    this._opacity = opacity;
    this._strokeWidth = strokeWidth;
  }
  getFileId(): string {
    return null;
  }
  getFileDisplayName(): string {
    return null;
  }
  getPoints(): number[] {
    return [];
  }
  getLineCap(): string {
    return '';
  }
  getGlobalCompositeOperation(): string {
    return '';
  }
  getStrokeWidth(): number {
    return this._strokeWidth;
  }
  getOpacity(): number {
    return this._opacity;
  }
  getFill(): string {
    return this._fill;
  }
  getStroke(): string {
    return this._stroke;
  }
  getRotation(): number {
    return this._rotation;
  }
  getSkewX(): number {
    return this._skewX;
  }
  getSkewY(): number {
    return this._skewY;
  }
  getRadiusX(): number {
    return 0;
  }
  getRadiusY(): number {
    return 0;
  }
  getSides(): number {
    return 0;
  }
  getRadius(): number {
    return 0;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getW() {
    return this._w;
  }
  getH() {
    return this._h;
  }
  getScaleX() {
    return this._scaleX;
  }
  getScaleY() {
    return this._scaleY;
  }
  getShape(): ShapeTypes {
    return ShapeTypes.rect;
  }
  getDate(): Date {
    return this.date;
  }
  getText() {
    return '';
  }
  getAlign(): string {
    return null;
  }
  getTextDecoration(): string {
    return null;
  }
  getFontFamily(): string {
    return null;
  }
  getFontSize(): number {
    return null;
  }
  getFontStyle(): string {
    return null;
  }
  getFontVariant(): string {
    return null;
  }
}

export class TriangleShapeSnapShot implements IShapeSnapShot {
  date: Date;
  _x: number;
  _y: number;
  _radius: number;
  _sides: number;
  _scaleX: number;
  _scaleY: number;
  _rotation: number;
  _skewX: any;
  _skewY: any;
  _fill: string;
  _stroke: string;
  _opacity: number;
  _strokeWidth: number;
  constructor(x, y, sides, radius, scaleX, scaleY, rotation, skewX, skewY, fill, stroke, opacity, strokeWidth) {
    this.date = new Date();
    this._x = x;
    this._y = y;
    this._sides = sides;
    this._radius = radius;
    this._scaleX = scaleX;
    this._scaleY = scaleY;
    this._rotation = rotation;
    this._skewX = skewX;
    this._skewY = skewY;
    this._rotation = rotation;
    this._skewX = skewX;
    this._skewY = skewY;
    this._fill = fill;
    this._stroke = stroke;
    this._opacity = opacity;
    this._strokeWidth = strokeWidth;
  }
  getFileId(): string {
    return null;
  }
  getFileDisplayName(): string {
    return null;
  }
  getStrokeWidth(): number {
    return this._strokeWidth;
  }
  getOpacity(): number {
    return this._opacity;
  }
  getFill(): string {
    return this._fill;
  }
  getStroke(): string {
    return this._stroke;
  }
  getRotation(): number {
    return this._rotation;
  }
  getSkewX(): number {
    return this._skewX;
  }
  getSkewY(): number {
    return this._skewY;
  }
  getRadiusX(): number {
    return 0;
  }
  getRadiusY(): number {
    return 0;
  }
  getSides(): number {
    return this._sides;
  }
  getScaleX(): number {
    return this._scaleX;
  }
  getScaleY(): number {
    return this._scaleY;
  }
  getW(): number {
    return 0;
  }
  getH(): number {
    return 0;
  }
  getShape(): ShapeTypes {
    return ShapeTypes.triangle;
  }
  getDate(): Date {
    return this.date;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getRadius() {
    return this._radius;
  }
  getPoints(): number[] {
    return [];
  }
  getLineCap(): string {
    return '';
  }
  getGlobalCompositeOperation(): string {
    return '';
  }
  getText() {
    return '';
  }
  getAlign(): string {
    return null;
  }
  getTextDecoration(): string {
    return null;
  }
  getFontFamily(): string {
    return null;
  }
  getFontSize(): number {
    return null;
  }
  getFontStyle(): string {
    return null;
  }
  getFontVariant(): string {
    return null;
  }
}

export class EllipseShapeSnapShot implements IShapeSnapShot {
  date: Date;
  _x: number;
  _y: number;
  _radius: number;
  _radiusX: number;
  _radiusY: number;
  _sides: number;
  _scaleX: number;
  _scaleY: number;
  _rotation: number;
  _skewX: any;
  _skewY: any;
  _fill: string;
  _stroke: string;
  _opacity: number;
  _strokeWidth: number;
  constructor(
    x,
    y,
    sides,
    radiusX,
    radiusY,
    scaleX,
    scaleY,
    rotation,
    skewX,
    skewY,
    fill,
    stroke,
    opacity,
    strokeWidth,
  ) {
    this.date = new Date();
    this._x = x;
    this._y = y;
    this._sides = sides;
    this._scaleX = scaleX;
    this._scaleY = scaleY;
    this._radiusX = radiusX;
    this._radiusY = radiusY;
    this._rotation = rotation;
    this._skewX = skewX;
    this._skewY = skewY;
    this._fill = fill;
    this._stroke = stroke;
    this._opacity = opacity;
    this._strokeWidth = strokeWidth;
  }
  getFileId(): string {
    return null;
  }
  getFileDisplayName(): string {
    return null;
  }
  getStrokeWidth(): number {
    return this._strokeWidth;
  }
  getOpacity(): number {
    return this._opacity;
  }
  getFill(): string {
    return this._fill;
  }
  getStroke(): string {
    return this._stroke;
  }
  getRotation(): number {
    return this._rotation;
  }
  getSkewX(): number {
    return this._skewX;
  }
  getSkewY(): number {
    return this._skewY;
  }
  getSides(): number {
    return this._sides;
  }
  getScaleX(): number {
    return this._scaleX;
  }
  getScaleY(): number {
    return this._scaleY;
  }
  getW(): number {
    return 0;
  }
  getH(): number {
    return 0;
  }
  getShape(): ShapeTypes {
    return ShapeTypes.ellipse;
  }
  getDate(): Date {
    return this.date;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getRadius() {
    return this._radius;
  }
  getRadiusX() {
    return this._radiusX;
  }
  getRadiusY() {
    return this._radiusY;
  }
  getPoints(): number[] {
    return [];
  }
  getLineCap(): string {
    return '';
  }
  getGlobalCompositeOperation(): string {
    return '';
  }
  getText() {
    return '';
  }
  getAlign(): string {
    return null;
  }
  getTextDecoration(): string {
    return null;
  }
  getFontFamily(): string {
    return null;
  }
  getFontSize(): number {
    return null;
  }
  getFontStyle(): string {
    return null;
  }
  getFontVariant(): string {
    return null;
  }
}

export class FreeLineShapeSnapShot implements IShapeSnapShot {
  date: Date;
  _points: number[];
  _lineCap: string;
  _strokeWidth: number;
  _globalCompositeOperation: string;
  _stroke: string;
  _scaleX: number;
  _scaleY: number;
  _opacity: number;
  _x: number;
  _y: number;
  _rotation: number;

  constructor(
    points: number[],
    lineCap: string,
    stroke: string,
    strokeWidth: number,
    globalCompositeOperation: string,
    scaleX: number,
    scaleY: number,
    opacity: number,
    x: number,
    y: number,
    rotation: number,
  ) {
    this.date = new Date();
    this._points = points;
    this._lineCap = lineCap;
    this._stroke = stroke;
    this._strokeWidth = strokeWidth;
    this._globalCompositeOperation = globalCompositeOperation;
    this._scaleX = scaleX;
    this._scaleY = scaleY;
    this._opacity = opacity;
    this._x = x;
    this._y = y;
    this._rotation = rotation;
  }
  getFileId(): string {
    return null;
  }
  getFileDisplayName(): string {
    return null;
  }
  getPoints() {
    return this._points;
  }
  getLineCap() {
    return this._lineCap;
  }
  getGlobalCompositeOperation() {
    return this._globalCompositeOperation;
  }
  getShape(): ShapeTypes {
    return ShapeTypes.freeline;
  }
  getDate(): Date {
    return this.date;
  }
  getX(): number {
    return this._x;
  }
  getY(): number {
    return this._y;
  }
  getW(): number {
    return 0;
  }
  getH(): number {
    return 0;
  }
  getScaleX(): number {
    return this._scaleX;
  }
  getScaleY(): number {
    return this._scaleY;
  }
  getRadius(): number {
    return 0;
  }
  getSides(): number {
    return 0;
  }
  getRadiusX(): number {
    return 0;
  }
  getRadiusY(): number {
    return 0;
  }
  getRotation(): number {
    return this._rotation;
  }
  getSkewX(): number {
    return 0;
  }
  getSkewY(): number {
    return 0;
  }
  getFill(): string {
    return '';
  }
  getStroke(): string {
    return this._stroke;
  }
  getOpacity(): number {
    return this._opacity;
  }
  getStrokeWidth(): number {
    return this._strokeWidth;
  }
  getText() {
    return '';
  }
  getAlign(): string {
    return null;
  }
  getTextDecoration(): string {
    return null;
  }
  getFontFamily(): string {
    return null;
  }
  getFontSize(): number {
    return null;
  }
  getFontStyle(): string {
    return null;
  }
  getFontVariant(): string {
    return null;
  }
}

export class TextFieldShapeSnapshot implements IShapeSnapShot {
  date: Date;
  _x: number;
  _y: number;
  _w: number;
  _h: number;
  _scaleX: number;
  _scaleY: number;
  _rotation: number;
  _skewX: any;
  _skewY: any;
  _fill: string;
  _stroke: string;
  _opacity: number;
  _strokeWidth: number;
  _text: string;
  _textDecoration: string;
  _fontFamily: string;
  _fontSize: number;
  _fontStyle: string;
  _fontVariant: string;
  _align: string;
  constructor(
    x,
    y,
    w,
    h,
    scaleX,
    scaleY,
    rotation,
    skewX,
    skewY,
    fill,
    stroke,
    opacity,
    strokeWidth,
    text,
    textDecoration,
    fontFamily,
    fontSize,
    fontStyle,
    fontVariant,
    align,
  ) {
    this._x = x;
    this._y = y;
    this._w = w;
    this._h = h;
    this._scaleX = scaleX;
    this._scaleY = scaleY;
    this._rotation = rotation;
    this._skewX = skewX;
    this._skewY = skewY;
    this.date = new Date();
    this._fill = fill;
    this._stroke = stroke;
    this._opacity = opacity;
    this._strokeWidth = strokeWidth;
    this._text = text;
    this._align = align;
    this._textDecoration = textDecoration;
    this._fontFamily = fontFamily;
    this._fontSize = fontSize;
    this._fontStyle = fontStyle;
    this._fontVariant = fontVariant;
  }
  getFileId(): string {
    return null;
  }
  getFileDisplayName(): string {
    return null;
  }
  getPoints(): number[] {
    return [];
  }
  getLineCap(): string {
    return '';
  }
  getGlobalCompositeOperation(): string {
    return '';
  }
  getStrokeWidth(): number {
    return this._strokeWidth;
  }
  getOpacity(): number {
    return this._opacity;
  }
  getFill(): string {
    return this._fill;
  }
  getStroke(): string {
    return this._stroke;
  }
  getRotation(): number {
    return this._rotation;
  }
  getSkewX(): number {
    return this._skewX;
  }
  getSkewY(): number {
    return this._skewY;
  }
  getRadiusX(): number {
    return 0;
  }
  getRadiusY(): number {
    return 0;
  }
  getSides(): number {
    return 0;
  }
  getRadius(): number {
    return 0;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getW() {
    return this._w;
  }
  getH() {
    return this._h;
  }
  getScaleX() {
    return this._scaleX;
  }
  getScaleY() {
    return this._scaleY;
  }
  getShape(): ShapeTypes {
    return ShapeTypes.rect;
  }
  getDate(): Date {
    return this.date;
  }
  getText(): string {
    return this._text;
  }
  getAlign(): string {
    return this._align;
  }
  getTextDecoration(): string {
    return this._textDecoration;
  }
  getFontFamily(): string {
    return this._fontFamily;
  }
  getFontSize(): number {
    return this._fontSize;
  }
  getFontStyle(): string {
    return this._fontStyle;
  }
  getFontVariant(): string {
    return this._fontVariant;
  }
}

export class StickerShapeSnapShot implements IShapeSnapShot {
  date: Date;
  _x: number;
  _y: number;
  _w: number;
  _h: number;
  _scaleX: number;
  _scaleY: number;
  _rotation: number;
  _skewX: any;
  _skewY: any;
  _fill: string;
  _stroke: string;
  _opacity: number;
  _strokeWidth: number;
  _text: string;
  constructor(x, y, w, h, scaleX, scaleY, rotation, skewX, skewY, fill, stroke, opacity, strokeWidth, text) {
    this._x = x;
    this._y = y;
    this._w = w;
    this._h = h;
    this._scaleX = scaleX;
    this._scaleY = scaleY;
    this._rotation = rotation;
    this._skewX = skewX;
    this._skewY = skewY;
    this.date = new Date();
    this._fill = fill;
    this._stroke = stroke;
    this._opacity = opacity;
    this._strokeWidth = strokeWidth;
    this._text = text;
  }
  getFileId(): string {
    return null;
  }
  getFileDisplayName(): string {
    return null;
  }
  getPoints(): number[] {
    return [];
  }
  getLineCap(): string {
    return '';
  }
  getGlobalCompositeOperation(): string {
    return '';
  }
  getStrokeWidth(): number {
    return this._strokeWidth;
  }
  getOpacity(): number {
    return this._opacity;
  }
  getFill(): string {
    return this._fill;
  }
  getStroke(): string {
    return this._stroke;
  }
  getRotation(): number {
    return this._rotation;
  }
  getSkewX(): number {
    return this._skewX;
  }
  getSkewY(): number {
    return this._skewY;
  }
  getRadiusX(): number {
    return 0;
  }
  getRadiusY(): number {
    return 0;
  }
  getSides(): number {
    return 0;
  }
  getRadius(): number {
    return 0;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getW() {
    return this._w;
  }
  getH() {
    return this._h;
  }
  getScaleX() {
    return this._scaleX;
  }
  getScaleY() {
    return this._scaleY;
  }
  getShape(): ShapeTypes {
    return ShapeTypes.sticker;
  }
  getDate(): Date {
    return this.date;
  }
  getText(): string {
    return this._text;
  }
  getAlign(): string {
    return null;
  }
  getTextDecoration(): string {
    return null;
  }
  getFontFamily(): string {
    return null;
  }
  getFontSize(): number {
    return null;
  }
  getFontStyle(): string {
    return null;
  }
  getFontVariant(): string {
    return null;
  }
}

export class FileShapeSnapShot implements IShapeSnapShot {
  date: Date;
  _x: number;
  _y: number;
  _w: number;
  _h: number;
  _scaleX: number;
  _scaleY: number;
  _rotation: number;
  _skewX: any;
  _skewY: any;
  _fill: string;
  _stroke: string;
  _opacity: number;
  _strokeWidth: number;
  _text: string;
  _fileId: string;
  _fileDisplayName: string;
  constructor(x, y, w, h, text, fileId, fileDisplayName) {
    this._x = x;
    this._y = y;
    this._w = w;
    this._h = h;
    this._text = text;
    this._fileId = fileId;
    this._fileDisplayName = fileDisplayName;
  }
  getFileId(): string {
    return this._fileId;
  }
  getFileDisplayName(): string {
    return this._fileDisplayName;
  }
  getPoints(): number[] {
    return [];
  }
  getLineCap(): string {
    return '';
  }
  getGlobalCompositeOperation(): string {
    return '';
  }
  getStrokeWidth(): number {
    return this._strokeWidth;
  }
  getOpacity(): number {
    return this._opacity;
  }
  getFill(): string {
    return this._fill;
  }
  getStroke(): string {
    return this._stroke;
  }
  getRotation(): number {
    return this._rotation;
  }
  getSkewX(): number {
    return this._skewX;
  }
  getSkewY(): number {
    return this._skewY;
  }
  getRadiusX(): number {
    return 0;
  }
  getRadiusY(): number {
    return 0;
  }
  getSides(): number {
    return 0;
  }
  getRadius(): number {
    return 0;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getW() {
    return this._w;
  }
  getH() {
    return this._h;
  }
  getScaleX() {
    return this._scaleX;
  }
  getScaleY() {
    return this._scaleY;
  }
  getShape(): ShapeTypes {
    return ShapeTypes.file;
  }
  getDate(): Date {
    return this.date;
  }
  getText(): string {
    return this._text;
  }
  getAlign(): string {
    return null;
  }
  getTextDecoration(): string {
    return null;
  }
  getFontFamily(): string {
    return null;
  }
  getFontSize(): number {
    return null;
  }
  getFontStyle(): string {
    return null;
  }
  getFontVariant(): string {
    return null;
  }
}
