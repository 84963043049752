<div class="modal__header">
  <div class="modal__icon">
    <img src="/assets/img/svg/icon-smile-bad.svg" />
  </div>
</div>
<div class="modal__content modal__content--icon">
  <div class="modal__title">{{ 'Вы уверены, что хотите завершить занятие' | transloco }} ?</div>
  <div style="font-size: 16px; text-align: center;">
    <span>{{ 'До конца занятия осталось еще' | transloco }} </span>
    <ng-container *ngIf="hasHours && hasMinutes && hasSeconds">
      <span style="color: #678aff; font-weight: 700">{{ timeDuration | date: 'HH:mm' }}
      </span>
    </ng-container>
    <ng-container *ngIf="!hasHours && hasMinutes && hasSeconds">
      <span style="color: #678aff; font-weight: 700">{{ timeDuration | date: 'HH:mm' }}
      </span>
    </ng-container>
    <ng-container *ngIf="!hasHours && !hasMinutes && hasSeconds">
      <span style="color: #678aff; font-weight: 700">{{ timeDuration | date: 'HH:mm' }}</span>
    </ng-container>
  </div>
  <div class="modal__actions">
    <a
      class="btn btn--blue"
      style="width: 70%"
      (click)="close()"
    >
      <span class="btn__label">{{ 'Продолжить занятие' | transloco }} </span>
    </a>
    <a
      class="btn btn--red"
      style="width: 70%; margin: 10px 0"
      (click)="finish()"
    >
      <span class="btn__label">{{ 'Завершить занятие' | transloco }}</span>
    </a>
  </div>
  <button
    class="modal__close"
    (click)="close()"
  >
    <img src="/assets/img/svg/icon-close.svg" />
  </button>
</div>