import * as ProfileStepsComponents from '@ezteach/tutorial/components/profile';
import { ITutorialStepConfig, ProfileSteps, StepType } from '@ezteach/tutorial/models';
import { PROFILE_DETAILS_MISSING_STEP_VALIDATOR } from '../validators';

export const PROFILE_STEPS_LIST: readonly ITutorialStepConfig<ProfileSteps>[] = [
  {
    name: ProfileSteps.DETAILS_MISSING_WARNING,
    type: StepType.DIALOG,
    component: ProfileStepsComponents.ProfileDetailsMissingDialogComponent,
    isLast: true,
    withValidator: PROFILE_DETAILS_MISSING_STEP_VALIDATOR,
  },
];
