import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper
      stepProgressText="4 из 6"
      [isSkippedToLastStep]="false"
    >
      <h1 class="ezteach-tutorial-tooltip__heading">Уведомления</h1>
      <p class="ezteach-tutorial-tooltip__info">
        Когда придет время начать занятие, вам придет уведомление. Не забудьте настроить уведомления в профиле, чтобы
        получать напоминания!
      </p>
      <img
        class="ezteach-tutorial-tooltip__image"
        [attr.src]="'/assets/img/tutorial/common/lesson.svg' | cachedImage"
      />
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step4TooltipComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
