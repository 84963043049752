<div
  class="footer"
  *ngIf="!isMobileFooter; else mobileContainer"
>
  <div
    class="footer__bottom-text"
    [ngClass]="{
    'footer__bottom-text-no-collapsed': !menuCollapsed,
    'footer__bottom-text-collapsed': menuCollapsed
  }"
  >
    <div class="footer__bottom-text-start">
      <div class="footer__bottom-text-item">
        <a href="">© {{ 'Легко учиться' | transloco }}, 2019-{{year}}</a>
      </div>
    </div>
    <div class="footer__bottom-text-item">
      <a
        href="https://450203.selcdn.ru/documents/confidentiality.pdf"
        target="_blank"
      >{{ 'Соглашение о конфиденциальности' | transloco }}</a>
    </div>
    <div class="footer__bottom-text-item">
      <a
        href="https://450203.selcdn.ru/documents/agreement.pdf"
        target="_blank"
      >{{ 'Условия использования ' | transloco }}</a>
    </div>
    <div class="footer__bottom-text-item">
      <a
        routerLink="faq"
      > {{ 'Помощь ' | transloco }}</a>
    </div>
    <div
      *ngIf="isTutor"
      class="footer__bottom-text-item"
    >
      <a
        href="https://450203.selcdn.ru/documents/tutor_offer.pdf"
        target="_blank"
      > {{ 'Оферта для преподавателей' | transloco }}</a>
    </div>
  </div>
</div>

<ng-template #mobileContainer>
  <div class="footer">
    <div class="footer__bottom-text">
      <div class="footer__bottom-text-item">
        <a href="">© {{ 'Легко учиться' | transloco }}, 2019-{{year}}</a>
      </div>
      <div class="footer__bottom-text-item">
        <a
          href="https://450203.selcdn.ru/documents/confidentiality.pdf"
          target="_blank"
        > {{ 'Соглашение о конфиденциальности' | transloco }} </a>
      </div>
      <div class="footer__bottom-text-item">
        <a
          href="https://450203.selcdn.ru/documents/agreement.pdf"
          target="_blank"
        >{{ 'Условия использования' | transloco }}</a>
      </div>
      <div class="footer__bottom-text-item">
        <a
          routerLink="faq"
        >{{ 'Помощь' | transloco }}</a>
      </div>
      <div
        *ngIf="isTutor"
        class="footer__bottom-text-item"
      >
        <a
          href="https://450203.selcdn.ru/documents/tutor_offer.pdf"
          target="_blank"
        >{{ 'Оферта для преподавателей' | transloco }}</a>
      </div>
    </div>
  </div>
</ng-template>
