import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorUserCodes } from '@ezteach/api/constants/api-error-user-codes.constants';
import { ChatRequest, User } from '@ezteach/api/models';
import { ModalSimpleError } from '@ezteach/modals/modal-simple-error/modal-simple-error.component';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../environments/environment';
import { LessonPaymentTypeEnum } from '../../api/models/lesson-payment-type-enum';
import { LessonsService } from '../../api/services/lessons.service';
import { ActiveService } from '../../_services/active.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { UserService } from "@ezteach/_services/user.service";

@UntilDestroy()
@Component({
  selector: 'requests-tutor-item',
  templateUrl: './requests-tutor-item.component.html',
})
export class RequestsTutorItemComponent implements OnInit {
  @Input() request: ChatRequest;
  countdown = '';
  userdata: User;

  LESSON_PAYMENT_PAYABLE = LessonPaymentTypeEnum.Payable;
  LESSON_PAYMENT_FREE = LessonPaymentTypeEnum.Free;

  constructor(
    private _cdr: ChangeDetectorRef,
    private lessonsService: LessonsService,
    private localStorageService: LocalStorageService,
    private activeService: ActiveService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const countDownDate = new Date(this.request.createDate).getTime();

    const now = new Date().getTime();
    const distance = countDownDate + this.request.expiresIn * 1000 - now;
    const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((distance / 1000 / 60) % 60);
    const seconds = Math.floor((distance / 1000) % 60);

    if (distance < 0) {
      this.countdown = '';
    } else {
      this.countdown = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    }

    this._cdr.detectChanges();

    setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate + this.request.expiresIn * 1000 - now;
      const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((distance / 1000 / 60) % 60);
      const seconds = Math.floor((distance / 1000) % 60);

      if (distance < 0) {
        this.countdown = '';
      } else {
        this.countdown = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
      }

      this._cdr.detectChanges();
    }, 1000);

    this.userdata = this.userService.userData$.value;

  }

  cancelRequest(item: ChatRequest) {
    this.lessonsService
      .apiV1LessonsInstantRequestsRequestIdCancelPatch({
        requestId: item.id as number,
      })
      .subscribe(() => {
        this.localStorageService.remove('requests');
      });
  }

  image(request: ChatRequest) {
    if (request.initiator?.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        request.initiator.id +
        '/user-avatar?redirect=true&file=' +
        request.initiator.avatarFileName
      );
    } else {
      return null;
    }
  }

  initials(request: ChatRequest): string {
    return (
      (request.initiator?.firstName?.charAt(0)?.toUpperCase() ?? '') +
      (request.initiator?.lastName?.charAt(0)?.toUpperCase() ?? '')
    );
  }

  acceptRequest(item: ChatRequest, paymentType: LessonPaymentTypeEnum) {
    this.lessonsService
      .apiV1LessonsInstantRequestsRequestIdAcceptPatch({
        requestId: item.id as number,
        paymentType,
      })
      .subscribe(
        response => {
          const id = item.joinIdentity;
          this.activeService.update();
          this.localStorageService.set('requests', []);

          this.router.navigate(['/wait'], {
            queryParams: { id },
            relativeTo: this.route,
          });
        },
        error => {
          const errorApiResponse = error.error.Error;
          this.dialog.open(ModalSimpleError, {
            panelClass: 'modal-simple-error',
            data: {
              title: this.translocoService.translate(`Запрос на занятие не принят`),
              text: ApiErrorUserCodes[errorApiResponse.Code] ?? errorApiResponse.Description,
            },
          });
        },
      );
  }
}
