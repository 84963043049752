import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UserService } from "@ezteach/_services/user.service";
import {
  OnboardingStudentSteps,
  OnboardingTeacherSteps,
  TutorialRegistryService,
  TutorialTypes,
  TutorialWithStepTuple,
} from '@ezteach/tutorial';
import { UsersService } from '../api/services/users.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  widgets;
  financeWidgets = [0, 1];
  discipline: string;
  isTutor = false;
  isStudent = false;
  isMobile = false;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');

  private tutorialData: readonly Tuple<TutorialWithStepTuple, string>[] = [
    [[TutorialTypes.ONBOARDING_TEACHER, OnboardingTeacherSteps.STEP1_1], 'calendar'],
    [[TutorialTypes.ONBOARDING_TEACHER, OnboardingTeacherSteps.STEP1_2], 'calendar-setup-slots-button'],
    [[TutorialTypes.ONBOARDING_TEACHER, OnboardingTeacherSteps.STEP3], 'online-widget'],
    [[TutorialTypes.ONBOARDING_STUDENT, OnboardingStudentSteps.STEP5], 'calendar'],
    [[TutorialTypes.ONBOARDING_STUDENT, OnboardingStudentSteps.STEP6], 'form-search-instant'],
  ];

  constructor(
    private usersService: UsersService,
    private cdr: ChangeDetectorRef,
    private readonly tutorialRegistryService: TutorialRegistryService,
    private userService: UserService
  ) { }

  ngOnInit() {
    const userdata = this.userService.userData$.value;

    if (userdata.isStudent) {
      this.isStudent = true;
      this.widgets = [1, 3, 4];
    }

    if (userdata.isTutor) {
      this.isTutor = true;
      this.widgets = [12, 4];
    }
    this.cdr.detectChanges();

    this.tutorialData.forEach(([data, elemName]) =>
      this.tutorialRegistryService.registerElementNameForStepData(data, elemName),
    );

    this.mediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
    });
  }

  ngOnDestroy() {
    this.tutorialData.forEach(([data]) => this.tutorialRegistryService.unregisterElementNameForStepData(data));
  }

  setDefault() {
    const userdata = this.userService.userData$.value;

    if (userdata.isStudent) {
      this.widgets = [1, 3];
    }

    if (userdata.isTutor) {
      this.widgets = [12];
    }

    this.usersService
      .apiV1UsersUserIdWidgetsLayoutResetPatch({
        userId: userdata.id,
      })
      .subscribe();
  }

  drop(event: CdkDragDrop<number[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }
}
