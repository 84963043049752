import { Injectable } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class ProfilePersonalFormService {
  createForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
    return formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(20)]],
      lastName: ['', [Validators.required, Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.email]],
      // ^(?!7|8)\d{10}$ — соответствует номерам, состоящим ровно из 10 цифр, но не начинающимся с 7 или 8
      phone: ['', Validators.required],// Validators.pattern(/^(?!7|8)\d{10}$/)]],
      birthDate: ['', [Validators.required, this.birthdateValidator]],
      educationalInstitutionName: ['', Validators.maxLength(30)],
    });
  }

  private birthdateValidator(control: FormControl) {
    const birthdate = new Date(control.value);
    const error = { invalidDate: true };

    if (isNaN(birthdate.getTime())) {
      return error;
    }

    const birthYear = birthdate.getFullYear();
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 100;
    const maxYear = currentYear - 5;

    if (minYear > birthYear || birthYear > maxYear) {
      return error;
    }

    return null;
  }
}
