import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BaseImagePreview, QualityImagePreviewRate } from 'projects/ng-konva/src/lib/whiteboard/utils/constants';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import {
  UploadedFile,
  UploadedFileApiResponse,
  WhiteboardApiResponse,
  WhiteboardConfiguration, WhiteboardsApiResponse
} from '../models/whiteboard/whiteboard-api.model';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class WhiteboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient, private readonly oauthService: OAuthService,) {
    super(config, http);
  }

  create(request: WhiteboardConfiguration): Observable<StrictHttpResponse<WhiteboardApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/create', 'post');
    rb.body(request, 'multipart/form-data');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardApiResponse>) => r as StrictHttpResponse<WhiteboardApiResponse>),
      );
  }

  join(wid: string) {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/{wid}/join', 'patch');
    rb.path('wid', wid, {});
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardApiResponse>) => r as StrictHttpResponse<WhiteboardApiResponse>),
      );
  }

  leave(wid: string) {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/{wid}/leave', 'patch');
    rb.path('wid', wid, {});
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardApiResponse>) => r as StrictHttpResponse<WhiteboardApiResponse>),
      );
  }

  archive(wid: string) {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/{wid}/archive', 'patch');
    rb.path('wid', wid, {});
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardApiResponse>) => r as StrictHttpResponse<WhiteboardApiResponse>),
      );
  }

  getWhiteboards(params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string;
    sortByDesc?: string;
  }): Observable<StrictHttpResponse<WhiteboardsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard', 'get');
    if (params) {
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortByDesc', params.sortByDesc, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardsApiResponse>) => r as StrictHttpResponse<WhiteboardsApiResponse>),
      );
  }

  getArchivedWhiteboards(params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string;
    sortByDesc?: string;
  }): Observable<StrictHttpResponse<WhiteboardsApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/history', 'get');
    if (params) {
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('sortByDesc', params.sortByDesc, {});
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardsApiResponse>) => r as StrictHttpResponse<WhiteboardsApiResponse>),
      );
  }

  getWhiteboardById(wid: string): Observable<StrictHttpResponse<WhiteboardApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/{wid}', 'get');
    rb.path('wid', wid, {});
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardApiResponse>) => r as StrictHttpResponse<WhiteboardApiResponse>),
      );
  }

  fileUpload(wid: string, file: File): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/file', 'post');
    const body = {
      file,
      Wid: wid
    };

    rb.body(body, 'multipart/form-data');

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r as StrictHttpResponse<any>),
        mergeMap((r: StrictHttpResponse<any>) => new Promise<StrictHttpResponse<any>>(resolve => {
          const result = r;
          result.body.file = file;
          resolve(result);
        }))
      );
  }

  fileGet(wid: string, fileId: string) {
    window.open(this.rootUrl + '/api/v1/whiteboard/file/' + wid + '?fileId=' + fileId + '&access_token=' + this.oauthService.getAccessToken(), '_blank');
  }

  getPreviewUrl(wid: string, fileId: string, width?: number): Observable<UploadedFile> {
    const widthRequest = Math.round(width ? width * QualityImagePreviewRate : BaseImagePreview);
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/file/{wid}/preview?fileId=' + fileId + '&width=' + widthRequest, 'get');
    rb.path('wid', wid, {});
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<UploadedFileApiResponse>) => {
          const data = r.body.data;
          data.src = data.url + '&access_token=' + this.oauthService.getAccessToken();
          data.fileId = fileId;
          return data;
        }),
      );
  }

  rename(params): Observable<StrictHttpResponse<WhiteboardApiResponse>> {
    const rb = new RequestBuilder(this.rootUrl, '/api/v1/whiteboard/{wid}/change', 'patch');
    rb.path('wid', params.wId, {});
    rb.body(params.body, 'multipart/form-data');
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<WhiteboardApiResponse>) => r as StrictHttpResponse<WhiteboardApiResponse>),
      );
  }
}
