import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { UserService } from '@ezteach/_services/user.service';
import {
  ChatLesson,
  ChatLessonPagedApiResponse,
  LessonFormatEnum,
  ScheduledLessonStatusEnum,
  Tutor,
  User,
} from '@ezteach/api/models';
import { LessonsService } from '@ezteach/api/services';
import { BlockApiService, BlockExerciseTypeEnum } from '@ezteach/blocks/services/block-api.service';
import { SelectedBlockService } from '@ezteach/blocks/services/selected-block.service';
import {
  CalendarPopupService,
  CalendarService,
  GroupLessonOptions,
  ICreateCalendarMeetingPayload,
  IMeetingPopupData,
  PopupMode,
  PopupState,
} from '@ezteach/calendar';
import { CalendarEventTypeEnum } from '@ezteach/calendar/models';
import { CalendarApiService } from '@ezteach/calendar/services/calendar-api.service';
import {
  AccessToArchiveLesson,
  ChatLessonPrivacy,
  ChatLessonPublisherPolicy,
} from '@ezteach/group-lesson/services/group-lesson.service';
import { ButtonComponent } from '@ezteach/shared/components/button/button.component';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { uniq } from 'lodash';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable, Subject, forkJoin, from, fromEvent, of } from 'rxjs';
import { concatMap, filter, map, switchMap, tap } from 'rxjs/operators';

type NavItemId = 'active' | 'blocks' | 'history' | 'deleted';
interface NavItem {
  id: NavItemId;
  name: string;
  isActive: boolean;
}

interface LoadInfo {
  id: NavItemId;
  concat: boolean;
}
const NAV_ITEMS: NavItem[] = [
  {
    id: 'active',
    name: 'Актуальное',
    isActive: true,
  },
  {
    id: 'blocks',
    name: 'Блоки',
    isActive: false,
  },
  {
    id: 'history',
    name: 'Архив',
    isActive: false,
  },
  {
    id: 'deleted',
    name: 'Корзина',
    isActive: false,
  },
];

@UntilDestroy()
@Component({
  selector: 'ezteach-my-lessons-widget',
  templateUrl: './my-lessons-widget.component.html',
  styleUrls: ['./my-lessons-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyLessonsWidgetComponent implements OnDestroy {
  @ViewChild(ButtonComponent, { read: ElementRef }) createMeetingButton: ElementRef;
  @Input() isHalfView = false;
  private readonly load$ = new BehaviorSubject<LoadInfo>({ id: NAV_ITEMS.find(n => n.isActive).id, concat: false });
  readonly userdata: User = this.userService.userData$.value;
  readonly loading$ = new BehaviorSubject<boolean>(true);
  readonly delete$ = new Subject<number[]>();
  readonly scrollY$ = new Subject<void>();
  readonly pageSize = 20;
  navItems = NAV_ITEMS;
  lessons: ChatLesson[] = [];
  currentScrollY = 0;
  totalRecords = 0;
  totalPages = 0;
  pageNumber = 1;
  activeTab: NavItem = null;
  isSelectMode = false;
  selectedLessons: ChatLesson[] = [];
  PageRoutes = ['mylessons'];
  private currentPath: string = undefined;

  readonly trackByChatLesson = (_index: number, item: ChatLesson) => item.id;
  readonly trackByNavItem = (_index: number, item: NavItem) => `${item.id}:${item.isActive}`;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly lessonsService: LessonsService,
    private readonly calendarPopupService: CalendarPopupService,
    @Inject(WINDOW) private readonly windowRef: Window,
    private userService: UserService,
    private calendarService: CalendarService,
    private calendarApiService: CalendarApiService,
    private signalrService: SignalrService,
    private route: ActivatedRoute,
    private blockApiService: BlockApiService,
    public selectedBlockService: SelectedBlockService,
    private router: Router,
  ) {
    this.subscribeLoadLessons();

    const currentUrl = this.router.url;
    this.currentPath = this.PageRoutes.find(v => currentUrl.includes(v));

    if (!!this.route.snapshot.params?.id) {
      this.activateNavItem(this.navItems.find(x => x.id === this.route.snapshot.params?.id));
    } else {
      this.changeNavItem(NAV_ITEMS.find(n => n.isActive));
    }

    this.subscribeScroll();
    this.subscribeDeleteLessons();
    this.calendarApiService.refetchCalendarEvents$
      .pipe(
        untilDestroyed(this),
        tap(_ => this.updateLessons()),
      )
      .subscribe();

    this.signalrService.onUserNotificationCreated
      .pipe(
        untilDestroyed(this),
        tap(_ => this.updateLessons()),
      )
      .subscribe();
  }

  changeNavItem(navItem: NavItem) {
    if (this.load$.value.id === navItem.id && !!this.route.snapshot.params?.id) {
      return;
    }

    if (!!this.currentPath?.length) {
      this.router.navigate([`/${this.currentPath}/${navItem?.id}`]);
    }

    this.activateNavItem(navItem);
  }

  activateNavItem(navItem: NavItem) {
    if (this.load$.value.id === navItem.id && !!this.route.snapshot.params?.id) {
      this.changeSelectMode(false);
      return;
    }

    this.navItems = this.navItems.map(x => ({ ...x, isActive: x.id === navItem.id }));

    if (!this.load$.observers?.length) {
      this.subscribeLoadLessons();
    }
    this.activeTab = navItem;
    this.load$.next({ id: navItem.id, concat: false });
    this.changeSelectMode(false);
  }

  getMore(id: NavItemId): void {
    this.load$.next({ id, concat: true });
  }

  changeSelectMode(isSelectMode: boolean) {
    this.isSelectMode = isSelectMode;
    if (!isSelectMode) {
      this.selectedLessons = [];
    }
  }

  toggleSelectionLesson(lesson: ChatLesson): void {
    const index = this.selectedLessons.findIndex(l => l.id === lesson.id);
    if (index >= 0) {
      this.selectedLessons.splice(index, 1);
    } else {
      this.selectedLessons.push(lesson);
    }
  }

  deleteLessons(): void {
    if (this.selectedLessons.length === 0) {
      return;
    }
    const deletedIds = this.selectedLessons.map(l => l.id as number);
    this.delete$.next(deletedIds);
  }

  private getApiLessonResponse(id: NavItemId, pageNumber: number): Observable<ChatLessonPagedApiResponse> {
    switch (id) {
      case 'active':
        return this.getActive();
      case 'history':
        return this.lessonsService.apiV1LessonsHistoryGet({ PageNumber: pageNumber, PageSize: this.pageSize });
      case 'deleted':
        return this.lessonsService.apiV1LessonsRemovedGet({ PageNumber: pageNumber, PageSize: this.pageSize });
      default:
        return of({ data: [] } as ChatLessonPagedApiResponse);
    }
  }
  private convertToChatLessonPagedApiResponse(response: any): ChatLessonPagedApiResponse {
    response.data = response.data
      .filter(
        event =>
          event?.calendarEventType === CalendarEventTypeEnum.ScheduledLesson &&
          ((event?.statusId === ScheduledLessonStatusEnum.Initiated &&
            event?.lessonFormat === LessonFormatEnum.Group) ||
            event?.statusId === ScheduledLessonStatusEnum.Accepted) &&
          moment(event.endDate) > moment(),
      )
      .map(item => {
        item.tutorUser.isTutor = true;
        item.tutorUser.user = item.tutorUser;
        return {
          id: item.id,
          discipline: { name: item.title },
          lessonStatusId: item.statusId,
          lessonStartDate: item.startDate,
          lessonFinishDate: item.endDate,
          lessonPaymentType: item.lessonPaymentType,
          members: [item.tutorUser],
          lessonFormatId: item?.lessonFormat,
          disciplineId: item.disciplineId,
          joinIdentity: item.joinIdentity,
          inviteIdentity: item.inviteIdentity,
          subject: item.subject,
          publishingPolicy: item.publishingPolicy,
          homeWorkTemplateId: item?.homeWorkTemplateId,
          homeWorkTemplateName: item?.homeWorkTemplateName,
          invitedUsers: item?.invitedUsersInfo
        };
      });
    return response as ChatLessonPagedApiResponse;
  }

  private getActive(): Observable<ChatLessonPagedApiResponse> {
    const now = new Date();
    const start = moment().startOf('day').format('DD.MM.YYYY HH:mm');
    const end = moment(now).utc().add(14, 'days').format('DD.MM.YYYY HH:mm');
    const params = {
      LinkedMemberId: this.userdata.id,
      StartDate: start,
      EndDate: end,
    };

    const calendarEvents$ = this.calendarService
      .apiV1CalendarEventsGet(params)
      .pipe(map(response => this.convertToChatLessonPagedApiResponse(this.sortEventByDate(response))));

    const lessonEvents$ = this.lessonsService
      .apiV1LessonsGet()
      .pipe(map(response => ({ ...response } as ChatLessonPagedApiResponse)));

    const eventObservables = [calendarEvents$, lessonEvents$];
    return forkJoin(eventObservables).pipe(
      map(([calendarEvents, lessonEvents]) => {
        const resp = [...lessonEvents?.data, ...calendarEvents?.data];
        return { data: resp } as ChatLessonPagedApiResponse;
      }),
    );
  }

  private sortEventByDate(response): any {
    response.data = response.data.sort((a, b) => {
      if (a.startDate < b.startDate) return -1;
      return 0;
    });
    return response;
  }

  private getClosestHourWithOffset(offset: number): Date {
    const date = new Date();
    date.setHours(date.getHours() + offset);
    date.setMinutes(0, 0, 0);
    return date;
  }

  private subscribeScroll(): void {
    fromEvent(this.windowRef, 'scroll')
      .pipe(
        tap(() => {
          const update = Math.abs(this.windowRef.scrollY - this.currentScrollY);
          if (update > 50) {
            this.currentScrollY = this.windowRef.scrollY;
            this.scrollY$.next();
            this.cdr.detectChanges();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeLoadLessons(): void {
    if (!!this.load$.observers?.length) {
      return;
    }

    this.load$
      .pipe(
        tap(() => this.loading$.next(true)),
        map(v => ({ ...v, pageNumber: v.concat ? this.pageNumber + 1 : 1 })),
        switchMap(({ id, concat, pageNumber }) =>
          this.getApiLessonResponse(id, pageNumber).pipe(map(resp => ({ ...resp, concat }))),
        ),
        tap(() => this.loading$.next(false)),
        untilDestroyed(this),
      )
      .subscribe(({ concat, data, totalRecords, totalPages, pageNumber }) => {
        this.lessons = concat ? [...this.lessons, ...data] : data;
        this.totalRecords = totalRecords ?? 0;
        this.totalPages = totalPages ?? 0;
        this.pageNumber = pageNumber ?? 1;
        this.cdr.detectChanges();
      });
  }

  private updateLessons() {
    if (this.navItems.find(item => item.isActive)?.id === NAV_ITEMS[0].id) {
      this.load$.next({ id: NAV_ITEMS[0].id, concat: false });
    }
  }

  private subscribeDeleteLessons(): void {
    this.delete$
      .pipe(
        switchMap(ids => forkJoin(ids.map(lessonId => this.lessonsService.apiV1LessonsRemovedDelete({ lessonId })))),
        map(responses => responses.map(r => r.data)),
        map(datas => uniq([].concat(...datas)) as number[]),
        untilDestroyed(this),
      )
      .subscribe(deletedIds => {
        this.lessons = this.lessons.filter(l => !deletedIds.includes(l.id));
        this.changeSelectMode(false);
        this.cdr.detectChanges();
      });
  }

  openCreateMeetingPopup(): void {
    const nativeElement = this.createMeetingButton.nativeElement;

    const start = this.getClosestHourWithOffset(1);
    const end = this.getClosestHourWithOffset(2);

    const meetingTimeChange$: Observable<ICreateCalendarMeetingPayload> = of({
      start,
      end,
      id: '',
    });

    const updatePosition$ = this.scrollY$.asObservable();
    const tutor = this.userdata.tutor as Tutor;
    const disciplines = tutor.disciplineSpecializations.map(d => d.discipline);

    const groupLessonSettings: GroupLessonOptions = {
      selectedDisciplineId: disciplines.length > 0 ? disciplines[0].id : 0,
      disciplines: tutor.disciplineSpecializations.map(d => d.discipline),
      selectedPublishingPolicy: {
        publisher: ChatLessonPublisherPolicy.Any,
        lessonPrivacy: ChatLessonPrivacy.Public,
        accessToArchiveLesson: AccessToArchiveLesson.MembersOnly,
      },
    };

    let data: IMeetingPopupData = {
      meetingTimeChange$,
      updatePosition$,
      groupLessonOptions: groupLessonSettings,
      canEditTime: true,
      isCreateFromWidget: true,
      popupMode: PopupMode.Create,
    };
    this.calendarPopupService.onlyGroupLessonTab$.next(true);
    if (this.navItems.find(n => n.isActive).id === NAV_ITEMS[1].id) {
      data.popupState = PopupState.block;
    } else {
      data.popupState = PopupState.groupLesson;
      this.calendarPopupService.isGroupLesson$.next(true);
    }

    const ref = this.calendarPopupService.openCreateMeetingPopup(nativeElement, data);
    ref
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(x => {
        this.calendarApiService.refetchCalendarEvents$.next();
      });
  }

  addLesson($event: { elementRef: any; blockId: number }) {
    const start = this.getClosestHourWithOffset(1);
    const end = this.getClosestHourWithOffset(2);

    const meetingTimeChange$: Observable<ICreateCalendarMeetingPayload> = of({
      start,
      end,
      id: '',
    });

    const updatePosition$ = this.scrollY$.asObservable();
    const tutor = this.userdata.tutor as Tutor;
    const disciplines = tutor.disciplineSpecializations.map(d => d.discipline);

    const groupLessonSettings: GroupLessonOptions = {
      selectedDisciplineId: disciplines.length > 0 ? disciplines[0].id : 0,
      disciplines: tutor.disciplineSpecializations.map(d => d.discipline),
      selectedPublishingPolicy: {
        publisher: ChatLessonPublisherPolicy.Any,
        lessonPrivacy: ChatLessonPrivacy.Public,
        accessToArchiveLesson: AccessToArchiveLesson.MembersOnly,
      },
    };

    let data: IMeetingPopupData = {
      meetingTimeChange$,
      updatePosition$,
      groupLessonOptions: groupLessonSettings,
      canEditTime: true,
      isCreateFromWidget: true,
      popupMode: PopupMode.Create,
    };
    this.calendarPopupService.onlyGroupLessonTab$.next(true);
    this.calendarPopupService.showSelectLessonTab$.next(true);
    data.popupState = PopupState.groupLesson;
    this.calendarPopupService.isGroupLesson$.next(true);

    const ref = this.calendarPopupService.openCreateMeetingPopup($event.elementRef.nativeElement, data);
    ref
      .afterClosed()
      .pipe(
        filter(x => !!x.lessonId || !!x.historyLessons),
        tap(x => { }),
        switchMap(x => {
          if (x?.historyLessons) {
            return from(x.historyLessons).pipe(
              concatMap(h =>
                this.blockApiService.addBlockExercise({
                  blockId: $event.blockId,
                  scheduledLessonId: h.id,
                  title: h.name,
                  type: BlockExerciseTypeEnum.ScheduledLesson,
                }),
              ),
            );
          } else {
            return this.blockApiService.addBlockExercise({
              blockId: $event.blockId,
              scheduledLessonId: x.lessonId,
              title: x.subject,
              type: BlockExerciseTypeEnum.ScheduledLesson,
            });
          }
        }),
        untilDestroyed(this),
      )
      .subscribe(x => {
        this.selectedBlockService.refreshBlock$.next(+$event.blockId);
        this.calendarPopupService;
      });
  }

  openCreateBlockPopup() { }

  ngOnDestroy(): void {
    this.calendarPopupService.hideCurrentPopup();
  }
}
