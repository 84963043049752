import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { environment } from '@ezteach/enviroments';
import { TranslocoService } from '@ngneat/transloco';
import { getParamValueQueryString } from './utils';

@Component({
  selector: 'shorten-link',
  templateUrl: './shorten-link.component.html',
  styleUrls: ['./shorten-link.component.scss'],
})
export class ShortenLinkComponent {
  @Input() isInvite: boolean;
  @Input() isGuestInvite: boolean;
  @Input() prefix: string;
  @Input() link;
  @Input() height: string;
  @Input() tooltipContent = 'В открытом занятии, доступ по ссылке доступен всем, в закрытом—списку приглашенных';
  linkText: string;
  tooltipPosition: TooltipPosition = 'before';

  constructor(private clipboard: Clipboard, private translocoService: TranslocoService) {}

  copyLink() {
    if (this.isGuestInvite) {
      const waitId = getParamValueQueryString(this.link, 'id');
      const guestInviteId = getParamValueQueryString(this.link, 'guestInvite');
      const guestInviteLink = `${environment.authUrl}/Auth/RegisterGuest?ReturnUrl=` + encodeURIComponent(`${window.location.origin}/wait?id=${waitId}&invite=${guestInviteId}`);
      this.clipboard.copy(guestInviteLink);
    } else {
      this.clipboard.copy(this.link);
    }
    this.linkText = 'Ссылка скопирована';
    setTimeout(() => (this.linkText = undefined), 2000);
  }

  get shortLink() {
    if (this.linkText) {
      return this.translocoService.translate(this.linkText);
    }

    if (this.link) {
      if (this.prefix) {
        return this.translocoService.translate(this.prefix) + ' ' + this.link;
      }
      return this.link;
    }
    return '';
  }

  onMoreClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
