<div
  *ngIf="!showCheckoutForm"
  class="modal_header"
>
  <div class="modal_header__title">{{ 'Внимание' | transloco }}!</div>
  <div class="modal_header__hint">
    <p>{{ 'До начала занятия мы зарезервируем полную его стоимость на счете вашей карты' | transloco }}.</p>
  </div>
</div>
<div class="modal__content">
  <div class="lesson_item lesson_item--unhover">
    <div
      *ngIf="!loading"
      class="lesson_item__image"
    >
      <img
        *ngIf="image"
        src="{{ image }}"
      />
      <span *ngIf="!image">{{ initials }}</span>
    </div>
    <div
      *ngIf="!loading"
      class="lesson_item__content"
    >
      <div class="lesson_item__name">{{ name }}</div>
      <div class="lesson_item__list">
        <div class="lesson_item__info">
          <div class="lesson_item__info_icon">
            <svg-icon-sprite
              [src]="'icon-time'"
              [width]="'20px'"
              [height]="'20px'"
              [viewBox]="'0 0 20 20'"
            ></svg-icon-sprite>
          </div>
          <div class="lesson_item__info_name">{{ duration }}</div>
        </div>
        <div class="lesson_item__info">
          <div class="lesson_item__info_icon">
            <svg-icon-sprite
              [src]="'icon-card'"
              [width]="'20px'"
              [height]="'20px'"
              [viewBox]="'0 0 20 20'"
            ></svg-icon-sprite>
          </div>
          <div class="lesson_item__info_name">{{ data.item.price }} ₽</div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!confirmWidgetSrc"
      class="lesson_item__checkout"
    >
      <div id="checkout-form"></div>
    </div>
    <div
      *ngIf="confirmWidgetSrc"
      class="lesson_item__checkout"
    >
      <iframe
        [src]="confirmWidgetSrc"
        width="100%"
        height="350px"
        frameborder="0"
      ></iframe>
    </div>
  </div>

  <div class="modal__actions">
    <mat-spinner
      diameter="30"
      class="loader"
      *ngIf="loading && !isPaymant"
    ></mat-spinner>
    <a
      class="btn btn--red btn--middle"
      (click)="cancel(data.item.id, data.item.joinIdentity)"
      *ngIf="isPaymant"
    >
      <span class="btn__label">{{ 'Отменить занятие' | transloco }}</span>
    </a>
    <a
      class="btn btn--blue btn--middle"
      (click)="pay(data.item.id, data.item.joinIdentity)"
      *ngIf="!loading"
    >
      <span class="btn__label">{{ 'Оплатить занятие' | transloco }}</span>
    </a>
    <a
      class="btn btn--red btn--middle"
      (click)="cancel(data.item.id, data.item.joinIdentity)"
      *ngIf="!loading"
    >
      <span class="btn__label">{{ 'Отменить занятие' | transloco }}</span>
    </a>
  </div>
</div>