import { ObjectData, ObjectDescription } from "./objects-args";

export interface Scene {
  objects: ObjectDescription[];
}

export interface SceneObject {
  type: SceneObjectTypeEnum;
}

export enum SceneObjectTypeEnum {
  Triangle = 'triangle',
  Rectangle = 'rectangle',
  Ellipse = 'ellipse',
  Pencil = 'pencil',
  Text = 'text',
  Note = 'note',
  Document = 'document',
  Image = 'image'
}

export interface SceneObjectDescription {
  oId: string;
  sceneObject: VisualSceneObject;
  data: ObjectData;
  locked: boolean;
}

export interface VisualSceneObject extends SceneObject {
  draggable: boolean;
  ezId: string;
  fileId: string | undefined;
  fileDisplayName: string | undefined;
  fill: string;
  height: number;
  lineCap: string;
  lineJoin: string;
  locked: boolean;
  offsetX: number
  offsetY: number;
  opacity: number;
  points: [] | undefined;
  radius: number;
  radiusX: number;
  radiusY: number;
  rotation: number;
  scaleX: number;
  scaleY: number;
  sides: any
  stroke: string;
  strokeWidth: number;
  text: string | undefined;
  width: number;
  x: number;
  y: number;
  z: number;
}
