import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LessonProlongData } from '@ezteach/api/models/prolong-data';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';

@Component({
  selector: 'prolong',
  templateUrl: 'prolong.component.html',
  styleUrls: ['prolong.component.scss'],
})
export class ProlongComponent implements OnInit {
  @Input() data: LessonProlongData;
  @Input() chatOpen: boolean;
  @Input() narrowScreen: boolean;
  @Input() isMobile: boolean;
  @Output() onLeaveSession = new EventEmitter();
  @Output() onMinimize = new EventEmitter();
  @Output() onExtended = new EventEmitter<number>();

  mainHeaderText = 'Заканчивается время';
  titleText = 'Занятие завершается';
  totalSecondsLeft: number;
  buttonType = ButtonType;
  timeDuration: number;

  constructor() {}

  ngOnInit() {
    this.totalSecondsLeft = this.data.secondsLeft; 
  }

  leaveSession() {
    this.onLeaveSession.emit();
  }

  minimize() {
    this.onMinimize.emit();
  }

  extended(prolongTime: number) {
    this.onExtended.emit(prolongTime);
  }
}
