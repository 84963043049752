import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BankCardAddApiResponse } from '@ezteach/api/models/bank-card-add-api-reponse';
import { YookassaLoaderService } from '@ezteach/shared/services/yookassa-loader.service';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { isProfileSaving, saveProfile, selectProfile, setProfileTitle, updateBankCardBindings } from '../../+state';
import { environment } from '../../../../environments/environment';
import { UserProfile } from '../../../api/models';
import { UsersService } from '../../../api/services';
import { ModalBankcardsRemove } from '../../../modals/bankcards-remove/modal-bankcards-remove.component';
import { ProfileLocalStorageService } from '../../services/profile-localstorage.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-cards',
  templateUrl: './profile-cards.component.html',
  styleUrls: ['./profile-cards.component.scss'],
})
export class ProfileCardsComponent implements OnInit, AfterViewInit {
  user: any;
  form: UntypedFormGroup;
  title = $localize`Финансовая информация`;
  profile: UserProfile;
  subscriptions: Subscription = new Subscription();
  saving$: Observable<boolean>;
  financialsBankLoading: boolean;
  payoutsData: any;
  payoutsDataAdding = false;
  notificationText = $localize`К сожалению, вам нельзя привязать карту до тех пор, пока вы не начнете занятие. Вы сразу же сможете привязать её во время начала любого занятия.`;

  @ViewChild('addPayoutTemplate')
  private addPayoutTemplate: TemplateRef<any>;
  addPayoutDialog: MatDialogRef<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    public dialog: MatDialog,
    private store: Store,
    private profileLocalStorageService: ProfileLocalStorageService,
    private yookassaLoaderService: YookassaLoaderService,
    @Inject(WINDOW) private windowRef: Window,
  ) {}

  ngOnInit() {
    this.createForm();
    this.subscribeStore();
    this.store.dispatch(setProfileTitle({ title: this.title }));
  }

  ngAfterViewInit(): void {
    this.initYookassa();
  }

  subscribeStore() {
    this.store
      .pipe(
        untilDestroyed(this),
        select(selectProfile),
        filter((profile: UserProfile) => !!profile),
        tap((profile: UserProfile) => {
          this.profile = profile;
          if (profile.bankCardBindings) {
            this.updateForm(profile.bankCardBindings.defaultBankCardId ?? profile.bankCardBindings.bankCards[0]?.id);
          }
        }),
      )
      .subscribe();

    this.profileLocalStorageService.localStorageUser$
      .pipe(
        untilDestroyed(this),
        filter(user => user),
        take(1),
        tap(user => {
          this.user = user;
        }),
      )
      .subscribe();

    this.saving$ = this.store.pipe(untilDestroyed(this), select(isProfileSaving));
  }

  get isBankCardAdded() {
    return this.profile?.bankCardBindings?.bankCards.length !== 0;
  }

  initYookassa() {
    if (!(this.windowRef as any).PayoutsData) {
      this.yookassaLoaderService.renderYookassaScript().onload = () => {
        console.log('yoooo loaded');
      };
    } else {
      console.log('already yoooo loaded');
    }

    if (!(this.windowRef as any).YooMoneyCheckoutWidget) {
      this.yookassaLoaderService.renderСheckoutScript().onload = () => {
        console.log('checkout loaded');
      };
    }
  }

  createForm() {
    this.form = this.formBuilder.group({
      defaultBankCardId: [''],
    });
  }

  updateForm(defaultBankCardId: number) {
    this.form.patchValue({ defaultBankCardId });
  }

  addYookassaCard() {
    this.payoutsDataAdding = true;
    this.addPayoutDialog = this.dialog.open(this.addPayoutTemplate, {
      height: '350px',
      width: '490px',
    });

    if (this.user?.isStudent) {
      this.addCheckOutWidget();
    } else {
      setTimeout(x => {
        const windowPayout = this.windowRef as any;
        this.payoutsData = new windowPayout.PayoutsData({
          successCallback: this.successCallback.bind(this),
          errorCallback: this.errorCallback.bind(this),
        });
        this.payoutsData.render('payout-form');
      }, 100);
    }
  }

  successCallback(data) {
    console.log(data);
    this.addPayoutDialog.close();
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsSynonimPost({
        userId: this.user.id,
        body: {
          BankName: data.bankName ?? '',
          Panmask: data.panmask,
          Synonim: data.synonim,
          Type: data.type,
        },
      })
      .subscribe((response: BankCardAddApiResponse) => {
        console.log(response.data);
        delete this.payoutsData;
        this.payoutsDataAdding = false;
        const bankCards = {
          ...this.profile.bankCardBindings,
          bankCards: response.data,
        };
        this.store.dispatch(updateBankCardBindings(bankCards));
      });
  }

  errorCallback(e) {
    console.log(e);
    this.addPayoutDialog.close();
  }

  addCheckOutWidget() {
    const windowPayout = this.windowRef as any;
    const checkout = new windowPayout.YooMoneyCheckoutWidget({
      confirmation_token: '', // Токен, который перед проведением оплаты нужно получить от ЮKassa
      return_url: 'https://merchant.site', // Ссылка на страницу завершения оплаты
      error_callback(error) {
        // Обработка ошибок инициализации
      },
    });
    checkout.render('checkout-form');
  }

  // Не удалял если нужно будет вернуть сбер
  addCard() {
    this.financialsBankLoading = true;
    this.usersService
      .apiV1UsersUserIdFinancialsBankcardsPost({
        userId: this.user.id,
        body: {
          ReturnUrl: environment.appUrl + '/profile/card-add',
          FailUrl: environment.appUrl + '/profile/card-add',
        },
      })
      .subscribe(response => {
        this.financialsBankLoading = false;
        if (response.data) {
          window.location.href = response.data;
        }
      });
  }

  removeCard(id: number) {
    this.dialog.open(ModalBankcardsRemove, {
      panelClass: 'modal-bankcards-remove',
      data: { id },
    });
  }

  setProfile() {
    const bankCardBindings = {
      ...this.profile.bankCardBindings,
      defaultBankCardId: this.form.getRawValue().defaultBankCardId,
    };

    console.log(bankCardBindings);

    const profile = {
      ...this.profile,
      bankCardBindings: {
        ...bankCardBindings,
      },
    };

    this.store.dispatch(saveProfile({ profile }));
  }
}
