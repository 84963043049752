import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { OrientationService } from '@ezteach/_services/orientation.service';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { SortMemberTypes } from '@ezteach/group-lesson/models/sort-member-types.enum';
import { GroupLessonLayoutMergeService } from '@ezteach/group-lesson/services/group-lesson-layout.service';
import { GroupLessonMemberManagerService } from '@ezteach/group-lesson/services/group-lesson-member-manager.service';
import { GroupLessonNotificationsService } from '@ezteach/group-lesson/services/group-lesson-notifications/group-lesson-notifications.service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { LabelValue } from '@ezteach/shared/models';
import { FullViewPortGroupLessonService } from '@ezteach/shared/services/full-viewport-group-lesson.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrientationType } from 'ngx-device-detector';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import tippy, { Instance, Props } from 'tippy.js';

export enum VideoViewType {
  all = 'all',
  horizont = 'horizont',
  conf = 'conf',
  full = 'full',
  vertical = 'vertical',
  fullMode = 'fullMode'
}
export enum SortMembers {
  alphabetically = 'alphabetically',
  bySpeakers = 'bySpeakers'
}

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-header-merge',
  templateUrl: './group-lesson-header.component.html',
  styleUrls: ['./group-lesson-header.component.scss']
})
export class GroupLessonHeaderComponent implements OnInit {
  viewType = VideoViewType;
  @Input() lessonLink: string;
  @Input() inviteLink: string;
  @Input() isOwner: boolean;
  @Input() isMobile: boolean;
  @Input() view: VideoViewType;
  @Input() pinnedMode: boolean;
  @Input() ownerClient: ChatLessonMemberClient;
  @Input() isSpeech: boolean;
  @Input() isChatOpen: boolean;
  @Input() memberSortType: SortMemberTypes;
  @Input() narrowScreen: boolean;
  @Input() subject: string;
  @Output() onMemberSort = new EventEmitter<SortMemberTypes>();
  @HostBinding('class') className = 'widget-call-header';
  notificationGranted: boolean;
  notificationEnabled: boolean;
  notificationAllowed: boolean;
  tooltipContentNotification: string;
  readonly tooltipContentSort: string = this.translocoService.translate('Сортировка');
  tooltipPosition: TooltipPosition = 'above';
  tooltipAlreadyCreated = false;
  currentTooltip?: Instance<Props>;

  timerDate: number = Date.now();
  timeDuration: number;
  showHideMembersBtnState = false;
  showHideMembersBtnIsShown = false;
  fullTransitionMode = false;
  smallLandscape = false;
  readonly sortOptions: LabelValue<SortMemberTypes>[] = [
    { label: this.translocoService.translate('По алфавиту'), value: SortMemberTypes.AlphabeticallyAsc },
    { label: this.translocoService.translate('По говорящим'), value: SortMemberTypes.SpeakingAsc },
  ];

  allViewTypes = VideoViewType;
  currentView = VideoViewType.all;

  allSortMembers = SortMemberTypes;

  @ViewChild('tooltipBtnNotification') tooltipBtnNotification: ElementRef;
  @ViewChild('tooltipElementSort') tooltipElementSort: ElementRef;
  @ViewChild('mobileSort') mobileSort: ElementRef;

  matchSmallLandscapeMaxWidth = '(max-width: 770px)';
  private mediaHeightQueryService = new MediaQueryService(this.matchSmallLandscapeMaxWidth);

  constructor(
    private groupLessonService: GroupLessonService,
    private groupLessonMemberManagerService: GroupLessonMemberManagerService,
    private groupLessonLayoutService: GroupLessonLayoutMergeService,
    // private groupLessonPinService: GroupLessonPinService,
    private groupLessonNotationService: GroupLessonNotificationsService,
    private fullViewPortGroupLessonService: FullViewPortGroupLessonService,
    private translocoService: TranslocoService,
    private orientationService: OrientationService,
  ) { }

  ngOnInit(): void {
    this.groupLessonService.timeDuration$.subscribe({
      next: v => (this.timeDuration = v),
    });

    this.groupLessonLayoutService.view$.subscribe(view => {
      this.currentView = view;
    });

    this.groupLessonMemberManagerService.memberClients$
      .pipe(
        untilDestroyed(this),
        tap(members => {
          this.showHideMembersBtnIsShown = members.length > 0;
        }),
      )
      .subscribe();
    this.groupLessonService.memberContainerIsChanged$
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.showHideMembersBtnState = !this.showHideMembersBtnState;
        }),
      )
      .subscribe();

    this.groupLessonNotationService.askNotificationPermission();
    this.groupLessonNotationService.notificationGranted$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.notificationGranted = value;
          this.setTooltipContentNotification();
        }),
      )
      .subscribe();

    this.groupLessonNotationService.notificationEnabled$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.notificationEnabled = value;
          this.setTooltipContentNotification();
        }),
      )
      .subscribe();

    this.groupLessonNotationService.notificationAllowed$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.notificationAllowed = value;
          this.setTooltipContentNotification();
        }),
      )
      .subscribe();

    this.layoutSubscribe();
  }

  hideSortPopup() {
    if (this.mobileSort?.nativeElement) {
      this.mobileSort.nativeElement.classList.remove('is-open')
    }
  }

  toggleSortPopup() {
    if (this.mobileSort?.nativeElement) {
      this.mobileSort.nativeElement.classList.toggle('is-open')
    }
  }

  createTooltip(tooltipContent: string, el: Element) {
    if (!el) {
      return;
    }
    if (this.tooltipAlreadyCreated) {
      return;
    }
    this.currentTooltip = tippy(el, this.getTooltipOptions('<div style="word-break: break-word" >' + tooltipContent + '</div>'));
    this.tooltipAlreadyCreated = true;
  }

  removeTooltip() {
    if (!this.currentTooltip) {
      return;
    }
    this.currentTooltip.destroy();
    this.currentTooltip = undefined;
    this.tooltipAlreadyCreated = false;
  }

  getTooltipOptions(tooltipContent: string) {
    return {
      content: tooltipContent,
      allowHTML: true,
      theme: 'tooltip',
    };
  }

  showHideMembers() {
    this.groupLessonService.memberContainerIsChanged$.next();
  }

  changeView(view: VideoViewType) {
    // if (!this.pinnedMode) {
    //   this.groupLessonPinService.pinnedMember$.next(this.ownerClient);
    //   this.groupLessonPinService.pinnedMode$.next(true);
    // }
    // this.currentView = view;
    this.groupLessonLayoutService.view$.next(view);
  }

  toggleNotificationActivity() {
    this.removeTooltip();
    this.groupLessonNotationService.toggleNotificationActivity();
    this.createTooltip(this.tooltipContentNotification, this.tooltipBtnNotification.nativeElement);
  }

  changeSort(sortMember: string): void {
    this.onMemberSort.emit(SortMemberTypes[sortMember]);
  }

  setTooltipContentNotification() {
    if (this.notificationAllowed) {
      this.tooltipContentNotification = this.translocoService.translate('Отключить уведомления');
      return;
    }
    if (!this.notificationGranted) {
      const turnOnNotification = this.translocoService.translate('Включите уведомления в настройках браузера');
      this.tooltipContentNotification = `
        <div class="tooltip-notification__denied">
          <div class="icon-block">
            <span class="icon-wrapper">
              <img src="assets/img/svg/group-lesson/icon-danger.svg">
            </span>
          </div>
          <div>${turnOnNotification}</div>
        </div>
        `;
      return;
    }
    if (this.notificationEnabled) {
      this.tooltipContentNotification = this.translocoService.translate('Включить уведомления');
    }
  }

  changeFullScreenClick() {
    if (!this.fullTransitionMode) {
      this.fullViewPortGroupLessonService.startFullTransition();
      this.fullTransitionMode = true;
    } else {
      this.fullViewPortGroupLessonService.stopFullTransition();
      this.fullTransitionMode = false;
    }
  }

  layoutSubscribe() {
    const orientation$ = this.orientationService.orientation$;
    const isMatch$ = this.mediaHeightQueryService.match$;

    combineLatest([orientation$, isMatch$])
      .pipe(untilDestroyed(this))
      .subscribe(([orientation, isMatch]) => {
        if (isMatch && orientation === OrientationType.Landscape) {
          this.smallLandscape = true;
        } else {
          this.smallLandscape = false;
        }
      });
  }
}
