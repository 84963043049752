<!-- 		<button (click)="setDefault()" class="btn btn--white">
			<span class="btn__icon">
				<svg-icon-sprite [src]="'icon-default'" [width]="'16px'" [height]="'14px'" [viewBox]="'0 0 16 14'"></svg-icon-sprite>
			</span>
			<span class="btn__label">Вид по-умолчанию</span>
		</button> -->
<div
  class="requests"
  *ngIf="isTutor"
>
  <requests-tutor></requests-tutor>
</div>
<div
  class="requests2"
  *ngIf="isStudent"
>
  <requests-student></requests-student>
</div>
<div
  class="dashboard"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
  [cdkDropListData]="widgets"
>
  <ng-container *ngFor="let widget of widgets">
    <div
      class="widget"
      [class]="{ half: widget === 6, white: widget === 1 || widget === 3, calendarWidget: widget === 4 }"
      cdkDrag
      cdkDragLockAxis="y"
    >
      <div
        class="widget-drag"
        cdkDragHandle
      >
        <svg-icon-sprite
          [src]="'icon-drag'"
          [width]="'8px'"
          [height]="'13px'"
          [viewBox]="'0 0 8 13'"
        ></svg-icon-sprite>
      </div>
      <div
        class="widget-placeholder"
        *cdkDragPlaceholder
      ></div>
      <advertisement *ngIf="widget === 13"></advertisement>
      <form-search-instant *ngIf="widget === 1"></form-search-instant>
      <form-search-regular *ngIf="widget === 3"></form-search-regular>
      <calendar
        [type]="'widget'"
        *ngIf="widget === 4"
      ></calendar>
      <online *ngIf="widget === 12 && isMobile"></online>
      <widget-bookmarks *ngIf="widget === 6"></widget-bookmarks>
    </div>
  </ng-container>

  <div
    class="dashboard__row"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    [cdkDropListData]="financeWidgets"
    cdkDropListOrientation="horizontal"
  >
    <div
      class="dashboard__row-item"
      *ngFor="let item of financeWidgets"
    >

      <div
        class="widget"
        cdkDrag
        cdkDragLockAxis="x"
        [cdkDragData]="item"
      >
        <div
          class="widget-drag"
          cdkDragHandle
        >
          <svg-icon-sprite
            [src]="'icon-drag'"
            [width]="'8px'"
            [height]="'13px'"
            [viewBox]="'0 0 8 13'"
          ></svg-icon-sprite>
        </div>
        <div
          class="widget-placeholder"
          *cdkDragPlaceholder
        ></div>
        <div class="widget-my-lessons__icon">
          <svg-icon-sprite
            [src]="'icon-header-history'"
            [width]="'46px'"
            [height]="'61px'"
            [viewBox]="'0 0 46 61'"
            *ngIf="item==0"
          ></svg-icon-sprite>
        </div>
        <ezteach-my-lessons
          [isHalfView]="true"
          *ngIf="item==0"
        ></ezteach-my-lessons>
        <ezteach-finance-general *ngIf="item==1"></ezteach-finance-general>
      </div>
    </div>
  </div>
</div>