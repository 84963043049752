import { StreamManager } from 'openvidu-browser';
import { decodeFromBase64, isBase64 } from 'src/utils/base64';
import { StreamUserData } from '../models/stream-user-data';

const OPENVIDU_DATA_SPLITTER = '%/%';

function decodedNameFromBase64(name: string): string {
  if (isBase64(name)) {
    return decodeFromBase64(name);
  }

  return name;
}

export function getStreamData(subscriber: StreamManager): StreamUserData | null {
  if (subscriber.stream?.connection?.data?.includes(OPENVIDU_DATA_SPLITTER)) {
    const data = subscriber.stream.connection.data.split(OPENVIDU_DATA_SPLITTER)[0];
    const dataObj = JSON.parse(data)?.clientData;

    if (!!dataObj.userName?.toString()?.length) {
      dataObj.userName = decodedNameFromBase64(dataObj.userName);
    }
    return dataObj;
  }

  if (subscriber.stream?.session?.connection?.data?.includes(OPENVIDU_DATA_SPLITTER)) {
    const data = subscriber.stream.session.connection.data.split(OPENVIDU_DATA_SPLITTER)[0];
    const dataObj = JSON.parse(data)?.clientData;

    if (!!dataObj.userName?.toString()?.length) {
      dataObj.userName = decodedNameFromBase64(dataObj.userName);
    }
    return dataObj;
  }
  return null;
}
