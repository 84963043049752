import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, interval } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[ezteachEzCalendarLegend]',
})
export class EzCalendarLegendDirective implements OnDestroy {
  interval = interval(50);
  intervalSubscription: Subscription;
  calendarCreated = false;
  legend: any;
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private translocoService: TranslocoService,
  ) {
    this.intervalSubscription = this.interval
      .pipe(
        tap(x => {
          this.createHeaderLegend();
        }),
      )
      .subscribe();

    this.translocoService.langChanges$.pipe(debounceTime(100), untilDestroyed(this)).subscribe(x => {
      this.reCreateLegend();
    });
  }

  ngOnDestroy(): void { }

  reCreateLegend() {
    if (this.legend) {
      const titleElement = this.getTargetElement();
      this.renderer.removeChild(titleElement, this.legend);
    }
    this.createHeaderLegend();
  }

  getTargetElement() {
    return this.document.getElementsByClassName('fc-toolbar-title')[0];
  }

  createHeaderLegend() {
    this.legend = this.createLegendGroup();
    const titleElement = this.getTargetElement();
    if (titleElement) {
      this.intervalSubscription.unsubscribe();
      this.calendarCreated = true;
      const targetElement = titleElement.parentElement;
      if (targetElement.getElementsByClassName("calendar-legend").length == 0) {
        this.renderer.appendChild(targetElement, this.legend);
      }

    }
  }

  createLegendGroup() {
    const legend = this.renderer.createElement('div');
    legend.style.gap = '22px';
    legend.style.marginLeft = '0px';
    legend.style.marginTop = '16px';

    this.renderer.addClass(legend, 'calendar-legend');

    const itemSelectTime = this.createLegendItem('#678AFF', this.translocoService.translate('Поле выбора времени'), '');
    // console.log('itemSelectTime', itemSelectTime);
    this.renderer.appendChild(legend, itemSelectTime);

    const itemFreeSlot = this.createLegendItem(
      '#3DD598',
      this.translocoService.translate('Свободный слот для индивидуальных занятий'),
      '122px',
    );
    this.renderer.appendChild(legend, itemFreeSlot);

    const itemGroup = this.createLegendItem('#D0ADFC', this.translocoService.translate('Занятие'), '');
    this.renderer.appendChild(legend, itemGroup);

    return legend;
  }

  createLegendItem(color: string, text: string, width: string) {
    const container = this.renderer.createElement('div');
    container.style.display = 'flex';
    container.style.alignItems = 'center';
    container.style.gap = '6px';

    const box = this.renderer.createElement('div');
    const txt = this.renderer.createElement('div');

    box.style.width = '9px';
    box.style.height = '14px';
    box.style.backgroundColor = color;

    txt.style.color = '#696974';
    txt.style.fontSize = '10px';
    txt.innerText = text;
    if (width) {
      txt.style.width = width;
    }

    this.renderer.appendChild(container, box);
    this.renderer.appendChild(container, txt);

    return container;
  }
}
