<div
  [formGroup]="form"
  class="profile_common"
>
  <div class="profile_content__title">{{ 'Редактирование профиля' | transloco }}</div>
  <div class="profile_content__subtitle">{{ 'Персональные данные' | transloco }}:</div>
  <div class="profile_content__cols">
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Имя' | transloco }}</mat-label>
        <input
          matInput
          i18n-placeholder
          [placeholder]="'Имя' | transloco"
          maxlength="20"
          formControlName="firstName"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Фамилия' | transloco }}</mat-label>
        <input
          matInput
          i18n-placeholder
          [placeholder]="'Фамилия' | transloco"
          maxlength="20"
          formControlName="lastName"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="profile_content__cols">
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input">
        <mat-label>E-mail</mat-label>
        <input
          matInput
          i18n-placeholder
          placeholder="E-mail"
          formControlName="email"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input mat-form-field-should-float">
        <mat-label>{{ 'Телефон' | transloco }}</mat-label>
        <div class="phone-input-container">
          <input
            value="+"
            readonly
            class="phone-prefix"
            readonly="{{ isReadonlyFields }}"
          />
          <input
            matInput
            (input)="setMask(this.form.value.phone)"
            i18n-placeholder
            [placeholder]="phoneMask"
            mask="phoneMask"
            formControlName="phone"
            class="phone-number"
            readonly="{{ isReadonlyFields }}"
          />
        </div>
      </mat-form-field>
    </div>
  </div>
  <div
    class="profile_content__cols"
    *ngIf="form.get('birthDate').value"
  >
    <div class="profile_content__col">
      <app-date-picker
        formControlName="birthDate"
        i18n-label
        label="Дата рождения"
        cssClass="profile_content__input"
      >
      </app-date-picker>
      <span
        class="error"
        *ngIf="form.get('birthDate').errors?.invalidDate"
        >Указана некорректная дата рождения</span
      >
    </div>
    <div
      class="profile_content__col"
      *ngIf="localStorageUser?.isStudent"
    >
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Учебное заведение' | transloco }}</mat-label>
        <input
          matInput
          maxlength="30"
          i18n-placeholder
          placeholder="Учебное заведение"
          formControlName="educationalInstitutionName"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="saveProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>
