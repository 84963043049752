<div class="requests2">
  <requests-student></requests-student>
</div>
<form-search-instant></form-search-instant>
<div *ngIf="loaded">
  <div class="filter">
    <div class="filter__title">{{ 'Найдено преподавателей' | transloco }}: {{ results.totalRecords }}</div>
  </div>
  <div
    class="results_none"
    *ngIf="results.totalRecords == 0"
  >
    <div class="global__icon">
      <svg-icon-sprite
        [src]="'icon-smile-bad'"
        [width]="'78px'"
        [height]="'78px'"
        [viewBox]="'0 0 78 78'"
      ></svg-icon-sprite>
    </div>
    <div class="global__title">{{ 'К сожалению, по вашему запросу нет свободных преподавателей' | transloco }}</div>
    <div class="global__text">{{ 'Попробуйте изменить критерии поиска' | transloco }}</div>
  </div>
  <div
    class="search__list"
    *ngIf="results.totalRecords > 0"
  >
    <tutor-user-search-result
      [item]="item"
      [message]="Message"
      [duration]="InstantLessonDuration"
      [discipline]="DisciplineName"
      [type]="'instant'"
      *ngFor="let item of teachers"
    ></tutor-user-search-result>
  </div>
  <div
    class="search__action"
    *ngIf="results.totalPages > results.pageNumber"
  >
    <button class="btn btn--blue">
      <span
        class="btn__label"
        (click)="getMore()"
      >{{ 'Показать еще' | transloco }} </span>
    </button>
  </div>
</div>