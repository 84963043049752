import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from "@angular/router";
import { ButtonType } from "@ezteach/_components/buttons/button/button.component";
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { UserService } from "@ezteach/_services/user.service";
import {
  ChatLesson,
  ChatLessonStatusEnum,
  LessonFormatEnum,
  ScheduledLessonStatusEnum,
  Tutor
} from '@ezteach/api/models';
import {
  CalendarPopupService,
  CalendarService,
  GroupLessonOptions,
  ICreateCalendarMeetingPayload,
  PopupMode
} from "@ezteach/calendar";
import { CalendarApiService } from "@ezteach/calendar/services/calendar-api.service";
import { environment } from '@ezteach/enviroments';
import { WINDOW } from "@ng-web-apis/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject, fromEvent, of } from "rxjs";
import { tap } from "rxjs/operators";
@UntilDestroy()
@Component({
  selector: 'ezteach-active-lesson-item',
  templateUrl: './active-lesson-item.component.html',
  styleUrls: ['./active-lesson-item.component.scss'],
  providers: [CurrencyPipe, DatePipe],
})
export class ActiveLessonItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  lesson: ChatLesson;
  @ViewChild('editBtn') editBtn: ElementRef;

  linkLesson = '';
  disciplineName = '';
  disciplineNameShort = '';
  chatLessonStatusEnum = ChatLessonStatusEnum;
  lessonFormatEnum = LessonFormatEnum;
  buttonType = ButtonType;
  isMobile = false;
  isScheduled = false;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  constructor(
    private router: Router,
    private calendarPopupService: CalendarPopupService,
    private cdr: ChangeDetectorRef,
    @Inject(WINDOW) private readonly windowRef: Window,
    private userService: UserService,
    private calendarService: CalendarService,
    private calendarApiService: CalendarApiService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    const { lesson } = changes;
    if (lesson && lesson.currentValue) {
      const chatLesson = lesson.currentValue as ChatLesson;
      this.linkLesson = environment.appUrl + '/wait?id=' + chatLesson.joinIdentity;

      this.disciplineName = chatLesson.discipline?.name ?? $localize`Занятие №${chatLesson.id}`;
      this.disciplineNameShort = this.disciplineName?.charAt(0) ?? chatLesson.id.toString().charAt[0];
    }
  }

  ngOnInit() {
    this.subscribeScroll()
    this.mediaQueryService.match$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.isMobile = x;
        }),
      )
      .subscribe();
    this.isScheduled = this.lesson.lessonStatusId === ScheduledLessonStatusEnum.Accepted;
  }

  initiateLesson() {
    this.router.navigate(['/', 'wait'], { queryParams: { 'id': this.lesson.joinIdentity } })
  }

  readonly scrollY$ = new Subject<void>();
  private currentScrollY = 0;

  openEditMeetingPopup(): void {
    const nativeElement = this.editBtn.nativeElement;

    const start = new Date(this.lesson.lessonStartDate);
    const end = new Date(this.lesson.lessonFinishDate);

    const meetingTimeChange$: Observable<ICreateCalendarMeetingPayload> = of({
      start,
      end,
      id: '',
    });

    const updatePosition$ = this.scrollY$.asObservable();
    const tutor = this.userService.userData$.value.tutor as Tutor;

    const groupLessonSettings: GroupLessonOptions = {
      lessonId: this.lesson.id,
      //@ts-ignore
      selectedDisciplineId: this.lesson.disciplineId,
      disciplines: tutor.disciplineSpecializations.map(d => d.discipline),
      selectedPublishingPolicy: this.lesson.publishingPolicy,
      meetingIsCreated: true,
      joinIdentity: this.lesson.joinIdentity,
      inviteIdentity: this.lesson.inviteIdentity,
      subject: this.lesson.subject,
      homeWorkTemplateId: this.lesson?.homeWorkTemplateId,
      homeWorkTemplateName: this.lesson?.homeWorkTemplateName,
      invitedUsers: this.lesson?.invitedUsers,
    };

    const data = {
      meetingTimeChange$,
      updatePosition$,
      groupLessonOptions: groupLessonSettings,
      canEditTime: true,
      isCreateFromWidget: true,
      popupMode: PopupMode.Edit,
    };

    this.calendarPopupService.onlyGroupLessonTab$.next(true);
    this.calendarPopupService.isGroupLesson$.next(true);
    const popupRef = this.calendarPopupService.openCreateMeetingPopup(nativeElement, data);
    popupRef.afterClosed().toPromise().then(
      (closeData) => {
        if (closeData === null) {
          this.cancelLesson(this.lesson.id);
        }
        this.calendarApiService.refetchCalendarEvents$.next();
      }
    );
  }

  public cancelLesson(id) {
    this.calendarService
      .apiV1CalendarLessonsLessonIdCancelPatch({
        lessonId: id,
      })
      .subscribe(_ => {
        this.calendarApiService.refetchCalendarEvents$.next()
      });
  }

  private subscribeScroll(): void {
    fromEvent(this.windowRef, 'scroll')
      .pipe(
        tap(() => {
          const update = Math.abs(this.windowRef.scrollY - this.currentScrollY);
          if (update > 50) {
            this.currentScrollY = this.windowRef.scrollY;
            this.scrollY$.next();
            this.cdr.detectChanges();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.calendarPopupService.hideCurrentPopup();
  }
}
