import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonType } from '@ezteach/_components/buttons/button/button.component';
import { SmileyType } from '@ezteach/_components/popups/popup-with-bg/popup-with-bg.component';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { UserService } from '@ezteach/_services/user.service';
import { ChatLesson, ChatLessonStatusEnum, ProcessLessonPaymentModeEnum } from '@ezteach/api/models';
import { ChatLessonMemberPublishingStateValue } from '@ezteach/api/models/chat-lesson-member';
import { ChatLessonMemberPublishingStateEnum } from '@ezteach/api/models/chat-lesson-member-permisson';
import { LessonPaymentTypeEnum } from '@ezteach/api/models/lesson-format-enum';
import { ScheduledLessonStatusEnum } from '@ezteach/api/models/scheduled-lesson-status-enum';
import { CheduledLessonActivateResponse } from '@ezteach/api/models/waitroom/cheduled-lesson-activate-response';
import { LessonsService, UsersService } from '@ezteach/api/services';
import { WaitingroomService } from '@ezteach/api/services/waitingroom.service';
import { CalendarEvent } from '@ezteach/calendar/models';
import {
  UserRole,
  UserRoles,
  convertChatLessonMemberRoleToUserRoles,
} from '@ezteach/group-lesson/group-lesson.component';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonWaitSignalrService } from '@ezteach/group-lesson/services/group-lesson-wait-signalr-service/group-lesson-wait-signalr-service';
import { GroupLessonWaitService } from '@ezteach/group-lesson/services/group-lesson-wait/group-lesson-wait.service';
import { ChatLessonPublisherPolicy, GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { ModalBook } from '@ezteach/modals/book/modal-book.component';
import { ModalSimpleError } from '@ezteach/modals/modal-simple-error/modal-simple-error.component';
import { ModalUnavailableCamera } from '@ezteach/modals/unavailable-camera/unavailable-camera.component';
import { enterBottonMenuMotion, enterMotion, exitBottonMenuMotion, exitMotion } from '@ezteach/shared/animation/motion';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TUI_IS_IOS } from '@taiga-ui/cdk';
import { Device } from 'openvidu-browser';
import { BehaviorSubject, EMPTY, Subscription, combineLatest } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { GroupLessonSettingsComponent } from '../group-lesson-settings/group-lesson-settings.component';

@UntilDestroy()
@Component({
  selector: 'app-group-lesson-wait-merge',
  templateUrl: './group-lesson-wait.component.html',
  styleUrls: ['./group-lesson-wait.component.scss'],
  animations: [
    enterMotion,
    exitMotion,
    enterBottonMenuMotion,
    exitBottonMenuMotion,
    trigger('rotatedState', [
      transition(
        'rotated => default',
        animate(
          '400ms ease-out',
          keyframes([
            style({ transform: 'rotateY(180deg)', offset: 0.5 }),
            style({ transform: 'rotateY(0deg)', offset: 1 }),
          ]),
        ),
      ),
      transition(
        'default => rotated',
        animate(
          '400ms ease-in',
          keyframes([
            style({ transform: 'rotateY(-180deg)', offset: 0.5 }),
            style({ transform: 'rotateY(0deg)', offset: 1 }),
          ]),
        ),
      ),
    ]),
  ],
})
export class GroupLessonWaitComponent implements OnInit, OnDestroy {
  @Input() userRole: UserRole;
  user: any;
  lesson: ChatLesson;
  calendarEvent: CalendarEvent;
  chatLessonStatusEnum = ChatLessonStatusEnum;
  subscription: Subscription = new Subscription();
  numberOfMembersExceeded: boolean;
  wasBanned: boolean;
  lessonFinished: boolean;
  canAudio = false;
  canVideo = false;
  audioEnabled = false;
  videoEnabled = false;
  mediaDevicesAccess = false;
  selectedMicrophone = 'none';
  selectedCamera = 'none';
  selectedAudioOutput = 'none';
  microphones: Device[] = [];
  cameras: Device[] = [];
  audioOutputs: MediaDeviceInfo[] = [];
  publishingState: ChatLessonMemberPublishingStateValue[] = [];
  mediaTracks: MediaStreamTrack[] = [];
  audioDeviceId: string;
  videoDeviceId: string;
  audioOutputDeviceId: string;
  devicesIsLoaded = false;
  notJoined: boolean;
  isOwner = false;
  myLessons: ChatLesson[];
  isSpeech: boolean;
  isNotAvailableWaitId = false;
  isWrongTutor = false;
  isMobile = false;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  buttonType = ButtonType;
  smileyType = SmileyType;
  isCameraAvailable: boolean;
  scheduledLessonStatus = ScheduledLessonStatusEnum;

  audioStatusChanging = false;
  videoStatusChanging = false;

  @ViewChild('videoPreview') videoPreview: ElementRef;
  showSettings = false;
  state = 'default';
  switchCameraRotated = false;

  inviteIdentity = '';
  deviceLoadingHasError = false;
  allDeviceIsLoaded = false;
  loadedCountOfDeviceType = new BehaviorSubject<number>(0);
  joinLessonCalled = false;

  joinIdentity: string;

  lessonPaymentTypeEnum = LessonPaymentTypeEnum;
  cheduledLessonActivateResponse: CheduledLessonActivateResponse = null;
  activeLesson: ChatLesson = null;
  callBooked: boolean = false;
  overlayRef: OverlayRef;

  constructor(
    private localStorageService: LocalStorageService,
    private lessonsService: LessonsService,
    private route: ActivatedRoute,
    public groupLessonService: GroupLessonService,
    private router: Router,
    private groupLessonWaitSignalrService: GroupLessonWaitSignalrService,
    private openViduService: OpenViduService,
    private groupLessonPermissionService: GroupLessonPermissionService,
    private groupLessonWaitService: GroupLessonWaitService,
    private dialog: MatDialog,
    @Inject(TUI_IS_IOS) readonly isIOS: boolean,
    private waitingroomService: WaitingroomService,
    private signalrService: SignalrService,
    private usersService: UsersService,
    private userService: UserService,
    private overlay: Overlay
  ) { }

  ngOnInit() {
    this.mediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
    });

    this.loadedCountOfDeviceType
      .pipe(
        untilDestroyed(this),
        tap(count => {
          console.log('loadedCountOfDeviceType count', count)
          if (count === 3 && this.deviceLoadingHasError) {
            this.allDeviceIsLoaded = true;
            this.openViduService.showMediaConstraintsModal();
            this.loadedCountOfDeviceType.next(0);
          } else if (count === 3) {
            this.allDeviceIsLoaded = true;
            this.loadedCountOfDeviceType.next(0);
          }
        }),
      )
      .subscribe();
    this.route.queryParams.subscribe(params => {
      this.numberOfMembersExceeded = false;
      this.lessonFinished = params.lessonIsFinished ? JSON.parse(params.lessonIsFinished) : false;
      this.notJoined = params.notJoined ? JSON.parse(params.notJoined) : false;
      if (!this.lessonFinished) {
        this.user = this.userService.userData$.value;

        //мы не можем получать здесь свою роль, т.к. у нас нет инфы о занятии.
        this.userRole = UserRoles.Member;
        this.isOwner = false;

        this.joinIdentity = this.route.snapshot.queryParams.id;
        this.callBooked = this.route.snapshot.queryParams.booked == 'true';
        const inviteId = this.route.snapshot.queryParams.invite;
        const lessonId = this.route.snapshot.queryParams.lessonId;

        this.inviteIdentity = inviteId;

        combineLatest([
          this.groupLessonPermissionService.audioPermission$,
          this.groupLessonPermissionService.videoPermission$,
          this.openViduService.mediaDevicesConstraints$])
          .pipe(
            untilDestroyed(this),
          )
          .subscribe(([audioPermission, videoPermission, mediaDevicesConstraints]) => {
            this.canAudio = audioPermission;
            this.canVideo = videoPermission;
            if (this.audioEnabled) {
              this.audioEnabled = mediaDevicesConstraints.audio;
            }
            if (this.videoEnabled) {
              this.videoEnabled = mediaDevicesConstraints.video;
            }

            if (!!this.canAudio && !this.audioEnabled) {
              this.audioClicked();
            }
          });

        if (lessonId) {
          this.lesson = {
            id: +lessonId,
            lessonStatusId: this.chatLessonStatusEnum.ReadyToStart,
          };
        } else {
          this.lessonsService
            .calendarJoinIdentity({
              joinIdentity: this.joinIdentity,
              inviteIdentity: inviteId,
            })
            .pipe(
              untilDestroyed(this),
              tap(({ body }) => {
                this.userRole = convertChatLessonMemberRoleToUserRoles(body.me?.role);
                this.isOwner = this.userRole === UserRoles.Owner;

                this.isNotAvailableWaitId = false;
                this.calendarEvent = body;
                this.isSpeech = body.publishingPolicy.publisher === ChatLessonPublisherPolicy.Owner;
                this.wasBanned = body.wasBanned;
                this.numberOfMembersExceeded = body.numberOfMembersExceeded;
                this.lessonFinished = body.lessonFinished;
                this.notJoined = body.statusId === 'CanceledByTutor' || body.statusId === 'CanceledByStudent';
                this.isWrongTutor = body.tutorUser.id !== this.user.id && this.isOwner && !this.notJoined;
                !this.isNotAvailableWaitId && !this.wasBanned && !this.lessonFinished;

                if (this.callBooked) {
                  this.financialsLessonsBookForce();
                }

                if (
                  this.calendarEvent.lessonPaymentType === this.lessonPaymentTypeEnum.Payable &&
                  this.calendarEvent?.statusId === this.scheduledLessonStatus.Accepted &&
                  this.userRole === UserRoles.Owner
                ) {
                  this.scheduledLessonActivate();
                }
              }),
              catchError(
                // @ts-ignore
                err => {
                  if (err.status === 404) {
                    this.isNotAvailableWaitId = true;
                  }
                  if (err.status === 403) {
                    this.isNotAvailableWaitId = true;
                  }
                },
              ),
            )
            .subscribe();
        }

        this.subscribeSignalr();
      }
    });

    this.groupLessonService.chatLessonStatus$
      .pipe(
        untilDestroyed(this),
        tap((x: ChatLessonStatusEnum) => {
          console.log('ChatLessonStatusEnum ', x)
        }),
      )
      .subscribe();

    this.updateActiveLesson();
  }

  updateActiveLesson() {
    this.lessonsService
      .apiV1LessonsActiveGet()
      .pipe(delay(1000))
      .subscribe(response => {
        if (!response?.data) return;

        if (!response?.data?.id) {
          this.updateActiveLesson();
        }

        if (response?.data?.joinIdentity === this.calendarEvent.joinIdentity) {
          this.activeLesson = response.data;
          if (
            this.calendarEvent.lessonPaymentType === this.lessonPaymentTypeEnum.Payable &&
            response?.data?.lessonStatusId === ChatLessonStatusEnum.Initiated &&
            this.userRole === UserRoles.Member
          ) {
            this.showPaymentDialog();
          }
        }
      });
  }

  openMenu() {
    const overlayGlobalStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: overlayGlobalStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const settingsPortal = new ComponentPortal(GroupLessonSettingsComponent);
    const settingsRef = this.overlayRef.attach(settingsPortal);

    settingsRef.instance.onCloseBtnClick
      .pipe(
        tap(() => {
          this.overlayRef.dispose();
        }),
      )
      .subscribe();
    this.overlayRef
      .backdropClick()
      .pipe(
        tap(() => {
          this.overlayRef.dispose();
        }),
      )
      .subscribe();
  }

  showPaymentDialog() {
    const closed = this.dialog.open(ModalBook, {
      panelClass: 'modal-book',
      disableClose: true,
      data: {
        item: {
          joinIdentity: this.calendarEvent.joinIdentity,
          price: this.calendarEvent.price,
        },
      },
    });

    closed.afterClosed().subscribe(x => {
      if (x === 'cancel') {
        this.router.navigate(['/']);
      }
    });
  }

  getMediaDevicesInfo() {
    if (
      this.calendarEvent.publishingPolicy.publisher === ChatLessonPublisherPolicy.Any ||
      this.userRole === UserRoles.Owner
    ) {
      this.microphones = [];
      this.audioOutputs = [];
      this.cameras = [];
      this.allDeviceIsLoaded = false;
      this.deviceLoadingHasError = false;
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(stream => {
          this.openViduService
            .getDevices()
            .pipe(
              untilDestroyed(this),
              tap(devices => {
                if (devices && devices.length > 0) {
                  this.microphones = devices.filter(device => device.kind === 'audioinput');
                  if (this.microphones.length === 0 || this.microphones[0].deviceId === '') {
                    this.microphones.push(this.openViduService.getNotFoundDevice());
                  }
                } else {
                  this.microphones.push(this.openViduService.getNotFoundDevice());
                }
                if (
                  this.localStorageService.get('audioDeviceId') &&
                  this.localStorageService.get('audioDeviceId') !== 'none'
                ) {
                  this.selectedMicrophone = this.localStorageService.get('audioDeviceId');
                } else {
                  this.selectedMicrophone =
                    this.localStorageService.get('audioDeviceId') &&
                      this.localStorageService.get('audioDeviceId') !== 'none'
                      ? this.localStorageService.get('audioDeviceId')
                      : this.microphones[0].deviceId;
                }
                this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 1);
              }),
            )
            .subscribe();

          this.openViduService
            .getAudioOutputDevices()
            .pipe(
              untilDestroyed(this),
              tap(devices => {
                if (devices && devices.length > 0 && devices?.[0].deviceId !== '') {
                  this.audioOutputs = devices;
                } else {
                  this.audioOutputs.push(this.openViduService.getNotFoundMediaDevice());
                }
                if (
                  this.localStorageService.get('audioOutputDeviceId') &&
                  this.localStorageService.get('audioOutputDeviceId') !== 'none'
                ) {
                  this.selectedAudioOutput = this.localStorageService.get('audioOutputDeviceId');
                } else {
                  this.selectedAudioOutput =
                    this.localStorageService.get('audioOutputDeviceId') &&
                      this.localStorageService.get('audioOutputDeviceId') !== 'none'
                      ? this.localStorageService.get('audioOutputDeviceId')
                      : this.audioOutputs[0].deviceId;
                }
                this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 1);
              }),
            )
            .subscribe();
        })
        .catch(e => {
          this.deviceLoadingHasError = true;
          this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 2);
          this.microphones.push(this.openViduService.getNotFoundDevice());
          this.audioOutputs.push(this.openViduService.getNotFoundMediaDevice());
          this.selectedMicrophone = this.microphones[0].deviceId;
          this.selectedAudioOutput = this.audioOutputs[0].deviceId;
        });

      navigator.mediaDevices
        .getUserMedia({ audio: false, video: true })
        .then(stream => {
          this.openViduService
            .getDevices()
            .pipe(
              untilDestroyed(this),
              tap(devices => {
                if (devices && devices.length > 0) {
                  this.cameras = devices.filter(device => device.kind === 'videoinput');
                  if (this.cameras.length === 0 || this.cameras[0].deviceId === '') {
                    this.cameras.push(this.openViduService.getNotFoundDevice());
                  }
                } else {
                  this.cameras.push(this.openViduService.getNotFoundDevice());
                }

                if (
                  this.localStorageService.get('videoDeviceId') &&
                  this.localStorageService.get('videoDeviceId') !== 'none'
                ) {
                  this.selectedCamera = this.localStorageService.get('videoDeviceId');
                } else {
                  this.selectedCamera =
                    this.localStorageService.get('videoDeviceId') &&
                      this.localStorageService.get('videoDeviceId') !== 'none'
                      ? this.localStorageService.get('videoDeviceId')
                      : this.cameras[0].deviceId;
                }
                this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 1);
              }),
            )
            .subscribe();
        })
        .catch(e => {
          this.deviceLoadingHasError = true;
          this.loadedCountOfDeviceType.next(this.loadedCountOfDeviceType.value + 1);
          this.cameras.push(this.openViduService.getNotFoundDevice());
          this.selectedCamera = this.cameras[0].deviceId;
        });
    }
  }

  navigateToGrouLesson() {
    this.switchOffCameraIndicator();
    this.groupLessonWaitService.publishingState = this.publishingState;
    this.localStorageService.set(
      'audioDeviceId',
      this.localStorageService.get('audioDeviceId') ?? this.selectedMicrophone,
    );
    this.localStorageService.set('videoDeviceId', this.localStorageService.get('videoDeviceId') ?? this.selectedCamera);
    this.groupLessonWaitService.videoEnabled = this.videoEnabled;
    this.groupLessonWaitService.audioEnabled = this.audioEnabled;
    this.localStorageService.set(
      'audioOutputDeviceId',
      this.localStorageService.get('audioOutputDeviceId') ?? this.selectedAudioOutput,
    );
    this.router.navigate(['group-lesson'], {
      queryParams: { id: this.lesson?.id },
    });
  }

  /* ѝтарт занятия длѝ овнера занятия */
  startLesson() {
    this.navigateToGrouLesson();
  }

  financialsLessonsBookForce() {
    const userdata = this.userService.userData$.value;
    this.usersService
      .apiV1UsersUserIdFinancialsLessonsBookPost({
        userId: userdata.id,
        body: {
          JoinIdentity: this.calendarEvent.joinIdentity,
          Mode: ProcessLessonPaymentModeEnum.AddPayment,
        },
      })
      .subscribe(x => { });
  }

  /* Старт/присоединение к групповому занятию */
  joinLesson() {
    if (this.joinLessonCalled) return;
    this.joinLessonCalled = true;
    this.lessonsService
      .joinLesson({
        joinIdentity: this.calendarEvent.joinIdentity,
        inviteIdentity: this.inviteIdentity,
      })
      .pipe(
        tap(x => {
          this.lesson = x.data;
          this.navigateToGrouLesson();
        }),
        catchError(e => {
          this.joinLessonCalled = false;
          this.showAlreadyRunningLessonModal(e?.error?.Error?.Description?.toString());
          return EMPTY;
        }),
      )
      .subscribe();
  }

  /* Платный путь присоединения к уроку */
  scheduledLessonActivate() {
    this.waitingroomService.scheduledlessonactivated(this.calendarEvent.joinIdentity).pipe().subscribe();
  }

  subscribeSignalr() {
    this.groupLessonWaitSignalrService.connect(this.joinIdentity);
    const signalSubscription = this.groupLessonWaitSignalrService.onLessonStatusChanged
      .pipe(
        untilDestroyed(this),
        tap(x => {
          console.log('onLessonStatusChanged ', x)
          if (x.status === ChatLessonStatusEnum.Started && !this.joinLessonCalled) {
            this.calendarEvent.statusId = ScheduledLessonStatusEnum.LessonStarted;
          }
          if (x.status === ChatLessonStatusEnum.Finalized) {
            this.calendarEvent.statusId = ScheduledLessonStatusEnum.LessonFinished;
            this.lessonFinished = true;
          }
          if (x.status === ChatLessonStatusEnum.ReadyToStart) {
            this.updateCalendarLessonData();
          }
          if (x.status === ChatLessonStatusEnum.Started && !this.isOwner) {
            this.updateCalendarLessonData();
          }
          if (
            x.status === ChatLessonStatusEnum.CanceledByStudent ||
            x.status === ChatLessonStatusEnum.CanceledByTutor
          ) {
            this.router.navigate(['/']);
            if (this.dialog) {
              this.dialog.closeAll();
            }
          }
        }),
      )
      .subscribe();
    this.subscription.add(signalSubscription);

    const signalCancelSubscription = this.groupLessonWaitSignalrService.onLessonCanceled
      .pipe(
        untilDestroyed(this),
        tap(x => {
          console.log('onLessonCanceled ', x)
          this.router.navigate(['/']);
          if (this.dialog) {
            this.dialog.closeAll();
          }
        }),
      )
      .subscribe();
    this.subscription.add(signalCancelSubscription);

    this.signalrService.onScheduledLessonActivated.pipe(untilDestroyed(this)).subscribe(x => {
      this.cheduledLessonActivateResponse = x;
      if (
        this.calendarEvent.lessonPaymentType === this.lessonPaymentTypeEnum.Payable &&
        this.calendarEvent?.statusId === this.scheduledLessonStatus.Accepted &&
        this.userRole === UserRoles.Member
      ) {
        this.showPaymentDialog();
      }
    });
  }

  updateCalendarLessonData() {
    this.lessonsService
      .calendarJoinIdentity({
        joinIdentity: this.joinIdentity,
        inviteIdentity: this.inviteIdentity,
      })
      .pipe(
        untilDestroyed(this),
        tap(({ body }) => {
          this.userRole = convertChatLessonMemberRoleToUserRoles(body.me?.role);
          this.isOwner = this.userRole === UserRoles.Owner;

          this.isNotAvailableWaitId = false;
          this.calendarEvent = body;
        }),
      )
      .subscribe();

    this.updateActiveLesson();
  }

  checkLessonStatus() {
    /*this.lessonsService.apiV1LessonsLessonIdGet({ lessonId: this.lesson.id }).pipe(
      repeatWhen(x => x),
      filter((data: ChatLessonPagedApiResponse) => data.data?.),
      take(1)
      ).subscribe(result => console.log(result));
    */
  }

  ngOnDestroy(): void {
    this.joinLessonCalled = false;
    this.subscription.unsubscribe();
    this.groupLessonWaitSignalrService.closeHubConnection();
  }

  back() {
    this.router.navigate(['/']);
  }

  audioClicked() {
    this.checkUserMediaAndSetpublishState(ChatLessonMemberPublishingStateEnum.Audio);
  }

  videoClicked() {
    this.checkUserMediaAndSetpublishState(ChatLessonMemberPublishingStateEnum.Video);
  }

  checkUserMediaAndSetpublishState(stateType: ChatLessonMemberPublishingStateEnum) {
    if (stateType === ChatLessonMemberPublishingStateEnum.Audio) {
      this.audioStatusChanging = true;
    }
    if (stateType === ChatLessonMemberPublishingStateEnum.Video) {
      this.videoStatusChanging = true;
    }
    const avalable = {
      video: stateType === ChatLessonMemberPublishingStateEnum.Video ? !this.videoEnabled : this.videoEnabled,
      audio: stateType === ChatLessonMemberPublishingStateEnum.Audio ? !this.audioEnabled : this.audioEnabled,
    };


    console.log('checkUserMediaAndSetpublishState avalable', avalable)

    // нельзя запросить getUserMedia с двумя false
    if (avalable.video || avalable.audio) {
      navigator.mediaDevices
        .getUserMedia(avalable)
        .then(x => {
          this.groupLessonWaitService.setPublisherVideoResolutionByStream(x);
          this.updateMediaState(stateType);
        })
        .catch(e => {
          if (stateType === ChatLessonMemberPublishingStateEnum.Video) {
            this.videoStatusChanging = false;
          }
          if (stateType === ChatLessonMemberPublishingStateEnum.Audio) {
            this.audioStatusChanging = false;
          }
          this.openViduService.showMediaConstraintsModal();
        })
        .finally(() => { });
    } else {
      this.updateMediaState(stateType);
    }
  }

  updateMediaState(stateType: ChatLessonMemberPublishingStateEnum) {
    if (stateType === ChatLessonMemberPublishingStateEnum.Audio) {
      this.audioStatusChanging = false;
      this.audioEnabled = !this.audioEnabled;
      if (this.audioEnabled) {
        this.publishingState.push({ name: stateType, arg: '' });
      } else {
        this.publishingState = this.publishingState.filter(state => state.name !== stateType);
      }
    }
    if (stateType === ChatLessonMemberPublishingStateEnum.Video) {
      this.videoStatusChanging = false;
      this.videoEnabled = !this.videoEnabled;

      if (this.videoEnabled) {
        this.publishingState.push({
          name: ChatLessonMemberPublishingStateEnum.Video,
          arg: '',
        });
      } else {
        this.publishingState = this.publishingState.filter(
          state => state.name !== ChatLessonMemberPublishingStateEnum.Video,
        );
      }

      if (this.videoEnabled) {
        let constraints: MediaStreamConstraints = { video: true };
        if ((!this.selectedCamera || this.selectedCamera === 'none') && this.localStorageService.get('videoDeviceId')) {
          this.selectedCamera = this.localStorageService.get('videoDeviceId');
        }
        if (this.selectedCamera) {
          constraints = { video: { deviceId: this.selectedCamera } };
        }
        navigator.mediaDevices.getUserMedia(constraints).then(stream => {
          setTimeout(x => {
            this.groupLessonWaitService.setPublisherVideoResolutionByStream(stream);
            this.videoPreview.nativeElement.srcObject = stream;
            this.videoPreview.nativeElement.play();
          }, 50);
        });
      } else {
        this.switchOffCameraIndicator();
      }
    }

    this.groupLessonWaitService.publishingState = this.publishingState;
    if (
      !this.selectedCamera ||
      this.selectedCamera === 'none' ||
      !this.selectedMicrophone ||
      this.selectedMicrophone === 'none'
    ) {
      this.openViduService
        .getDevices()
        .pipe(
          untilDestroyed(this),
          tap(devices => {
            if (devices && devices.length > 0) {
              this.microphones = devices.filter(device => device.kind === 'audioinput');
              this.cameras = devices.filter(device => device.kind === 'videoinput');
            }
            this.selectedMicrophone = this.localStorageService.get('audioDeviceId') ?? this.microphones[0].deviceId;
            this.selectedCamera = this.localStorageService.get('videoDeviceId') ?? this.cameras[0].deviceId;
            this.localStorageService.set(
              'videoDeviceLabel',
              this.localStorageService.get('videoDeviceLabel') ?? this.cameras[0].label,
            );
          }),
        )
        .subscribe();
    }
  }

  switchOffCameraIndicator() {
    const mediaStream = this.videoPreview?.nativeElement?.srcObject;
    if (mediaStream) {
      const tracks = mediaStream.getTracks();
      tracks?.forEach(track => track.stop());
    }
  }

  showCameraUnavailableModal() {
    this.dialog.open(ModalUnavailableCamera, {
      panelClass: 'modal-unavailable-camera',
    });
  }

  showSettingsClick() {
    if (this.showSettings) {
      return;
    }
    this.showSettings = true;
    this.getMediaDevicesInfo();
  }

  hideSettingsClick() {
    this.showSettings = false;
  }

  onSelectCamera(value: any) {
    this.localStorageService.set('videoDeviceId', value);
    this.localStorageService.set('videoDeviceLabel', this.cameras.find(camera => camera.deviceId === value).label);
    if (this.videoEnabled) {
      navigator.mediaDevices.getUserMedia({ video: { deviceId: value } }).then(stream => {
        setTimeout(x => {
          this.groupLessonWaitService.setPublisherVideoResolutionByStream(stream);
          this.videoPreview.nativeElement.srcObject = stream;
          this.videoPreview.nativeElement.play();
        }, 50);
      });
    }
  }

  onSelectAudioOutput(value: any) {
    this.localStorageService.set('audioOutputDeviceId', value);
  }

  onSelectMicrophone(value: any) {
    this.localStorageService.set('audioDeviceId', value);
  }

  switchCameraMobile() {
    const nextCamera = this.cameras.filter(x => x.deviceId !== this.selectedCamera);
    if (nextCamera && nextCamera.length > 0) {
      this.state = this.state === 'default' ? 'rotated' : 'default';
      this.switchCameraRotated = true;
      setTimeout(x => {
        this.switchCameraRotated = false;
      }, 400);
      this.selectedCamera = nextCamera[0].deviceId;
      this.onSelectCamera(nextCamera[0].deviceId);
    }
  }

  cancelLesson() {
    this.lessonsService
      .apiV1LessonsCancelPatchPath$Response({
        joinIdentity: this.joinIdentity,
      })
      .subscribe(response => {
        this.router.navigate(['/']);
      });
  }

  showAlreadyRunningLessonModal(errorMessage: string) {
    const title = 'Пока вы не можете начать новое занятие';
    const text = !!errorMessage?.length
      ? errorMessage
      : "Необходимо завершить или дождаться завершения текущего занятия прежде, чем начать новое. Чтобы увидеть все свои активные занятия, перейдите в раздел 'Мои занятия'.";

    this.dialog.open(ModalSimpleError, {
      panelClass: 'modal-simple-error',
      data: { title, text },
    });
  }
}
