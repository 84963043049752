<mat-tab-group class="notifications-container">
  <mat-tab [label]="'Новые' | transloco">
    <ng-container *ngIf="notes | notificationsFilter: statusesNew:newIds as items">
      <div
        *ngIf="response && items.length === 0"
        class="ezteach-notifications-not-found-wrapper"
      >
        <ezteach-notifications-not-found
          i18n-title
          title="Нет новых уведомлений"
          i18n-content
          content="Здесь будут видны уведомления о занятиях, новые сообщения и прочие уведомления"
        ></ezteach-notifications-not-found>
      </div>

      <div
        *ngIf="items.length > 0"
        class="notification-lessons-wrapper"
      >
        <ezteach-notification-lesson
          *ngFor="let item of items"
          [item]="item"
          (onDelete)="archive(item.id)"
          (onAccept)="acceptLessonByType(item, $event)"
          (onReject)="cancelLessonByType(item, $event)"
        >
        </ezteach-notification-lesson>
      </div>
    </ng-container>
  </mat-tab>
  <mat-tab [label]="'Архив' | transloco">
    <ng-container *ngIf="notes | notificationsFilter: statusesArchive as items">
      <div
        *ngIf="response && items.length === 0"
        class="ezteach-notifications-not-found-wrapper"
      >
        <ezteach-notifications-not-found
          title="Нет старых уведомлений"
          content="Здесь отображаются уведомления, которые уже просмотрены или не актуальны"
        ></ezteach-notifications-not-found>
      </div>

      <div
        *ngIf="items.length > 0"
        class="notification-lessons-wrapper"
      >
        <ezteach-notification-lesson
          *ngFor="let item of items"
          [item]="item"
          (onDelete)="delete(item.id, items.length)"
        >
        </ezteach-notification-lesson>
      </div>
    </ng-container>
  </mat-tab>
</mat-tab-group>
