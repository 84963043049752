<tui-scrollbar class="nav">
  <div *ngIf="nav">
    <div
      class="nav_item"
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-dashboard'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
        <span
          class="badge collapsed"
          *ngIf="dashboard > 0 && asideIsCollapsed"
        ></span>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Моя доска' | transloco }}
        <span
          class="badge"
          *ngIf="dashboard > 0"
        >{{ dashboard }}</span>
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/mylessons"
      routerLinkActive="active"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div
        class="nav_item__icon"
        [ngClass]="{ collapsed: asideIsCollapsed }"
      >
        <svg-icon-sprite
          [src]="'icon-person-lesson'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
        <span
          class="active-lessons-collapsed"
          style="
            display: inline-flex;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            vertical-align: top;
            background: #ed3434;
            margin-left: -3px;
            margin-top: -2px;
          "
          *ngIf="isHaveActiveLessons && asideIsCollapsed"
        ></span>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Мои занятия' | transloco }}
        <span
          style="
            display: inline-flex;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            vertical-align: top;
            background: #ed3434;
            margin-left: -3px;
            margin-top: -2px;
          "
          *ngIf="isHaveActiveLessons"
        ></span>
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/homeworks"
      routerLinkActive="active"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-homework'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Домашнее задание' | transloco }}
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/messages"
      routerLinkActive="active"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-nav-messages'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
        <span
          class="unread-messages-collapsed"
          style="
            display: inline-flex;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            vertical-align: top;
            background: #d7db00;
            margin-left: -3px;
            margin-top: -2px;
          "
          *ngIf="isHaveUnreadMessages && asideIsCollapsed"
        ></span>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Мои сообщения' | transloco }}
        <span
          style="
            display: inline-flex;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            vertical-align: top;
            background: #d7db00;
            margin-left: -3px;
            margin-top: -2px;
          "
          *ngIf="isHaveUnreadMessages"
        ></span>
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/file-storage"
      routerLinkActive="active"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-file-storage'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Мои файлы' | transloco }}
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/whiteboards"
      routerLinkActive="active"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-whiteboards'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Интерактивная доска' | transloco }}
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/finances"
      routerLinkActive="active"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-finances'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Мои финансы' | transloco }}
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/calendar"
      routerLinkActive="active"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-calendar'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Календарь' | transloco }}
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/teachers"
      routerLinkActive="active"
      *ngIf="isTutor"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-teacher-room'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Учительская' | transloco }}
      </div>
    </div>

    <div
      ezteachTutorialStep
      [tutorialElementName]="$any('quick-lesson-link')"
      class="nav_item"
      routerLink="/search/instant"
      routerLinkActive="active"
      *ngIf="isStudent"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-instant'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
        <span
          class="badge collapsed"
          *ngIf="instant > 0 && asideIsCollapsed"
        ></span>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Быстрое занятие' | transloco }}
        <span
          class="badge"
          *ngIf="instant > 0"
        >{{ instant }}</span>
      </div>
    </div>
    <div
      ezteachTutorialStep
      [tutorialElementName]="$any('teacher-search-link')"
      class="nav_item"
      routerLink="/search/regular"
      routerLinkActive="active"
      *ngIf="isStudent"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-teacher-room'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Поиск преподавателя' | transloco }}
        <span
          class="badge"
          *ngIf="regular > 0"
        >{{ regular }}</span>
      </div>
    </div>

    <div
      class="nav_item"
      routerLink="/bookmarks"
      routerLinkActive="active"
      *ngIf="isStudent"
      [class.isDisabled]="activeTemporarySolution"
    >
      <div class="nav_item__icon">
        <svg-icon-sprite
          [src]="'icon-bookmarks'"
          [width]="'28px'"
          [height]="'28px'"
          [viewBox]="'0 0 28 28'"
        ></svg-icon-sprite>
      </div>
      <div
        *ngIf="!asideIsCollapsed"
        class="nav_item__name"
      >
        {{ 'Закладки' | transloco }}
      </div>
    </div>
    <!-- 	<div class="nav_item" routerLink="/finances" routerLinkActive="active">
			<div class="nav_item__icon">
				<svg-icon-sprite [src]="'icon-finances'" [width]="'28px'" [height]="'28px'" [viewBox]="'0 0 28 28'"></svg-icon-sprite>
			</div>
			<div class="nav_item__name">Мои финансы</div>
		</div> -->
  </div>
</tui-scrollbar>