import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper
      stepProgressText="1 из 6"
      [isSkippedToLastStep]="false"
    >
      <h1 class="ezteach-tutorial-tooltip__heading">Быстрое занятие (5–30 мин)</h1>
      <p class="ezteach-tutorial-tooltip__info">
        Кроме большого виджета сверху страницы, поиск преподавателей онлайн находится здесь.
      </p>
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step1TooltipComponent {}
