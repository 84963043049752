export enum ChatLessonMemberRole {
  //Не имеет права участвовать в занятии
  None = 'None',
  //Владелец
  Owner = 'Owner',
  //Участник
  Member = 'Member',
  //Модератор
  Moderator = 'Moderator',
}

export enum ChatLessonMemberPublishingPermission {
  //Участника будут слышать
  Audio = 'Audio',
  //Участника будут видеть
  Video = 'Video',
  //Участник может расшарить экран
  Screen = 'Screen',
  //Участник может чатиться
  Chat = 'Chat',
}

export enum ChatLessonMemberPublishingPermissionSocket {
  /// <summary>
  /// Участника будут слышать
  /// </summary>
  Audio = 1,

  /// <summary>
  /// Участника будут видеть
  /// </summary>
  Video = 2,

  /// <summary>
  /// Участник может расшарить экран
  /// </summary>
  Screen = 4,

  /// <summary>
  /// Участник может чатиться
  /// </summary>
  Chat = 8,
}

export enum ChatLessonMemberPublishingStateEnum {
  // Пусто
  None = 'None',
  // У участника включено аудио
  Audio = 'Audio',
  // У участника включено видео
  Video = 'Video',
  // Участник расшарил экран
  Screen = 'Screen',
  // Участник работает на доске
  Whiteboard = 'Whiteboard',
}

export enum ChatLessonMemberPublishingStateSocketEnum {
  // У участника включено аудио
  Audio = 1,
  // У участника включено видео
  Video = 2,
  //Участник расшарил экран
  Screen = 4,
  // Участник работает на доске
  Whiteboard = 8,
}
