<div class="profile-menu">
  <div
    class="profile-menu-item user-info"
    ezteachTutorialStep
    tutorialElementName="personal-cabinet-link"
    routerLink="/profile"
    (click)="openProfile()"
  >
    <div class="profile-menu-item__avatar">
      <img
        *ngIf="userdata?.avatarFileName; else avatar"
        [src]="userdata | userAvatar"
        alt="avatar"
      />
      <ng-template #avatar>
        <div>{{ userdata | userInitials }}</div>
      </ng-template>
    </div>
    <div class="profile-menu-item__info">
      <div class="profile-menu-item__info-uName">{{ userdata?.name }}</div>
      <div class="profile-menu-item__info-uId">{{ userdata?.uId }}</div>
    </div>
    <div class="profile-menu-item__arrow">
      <img
        src="assets/img/svg/profile-menu-arrow.svg"
        alt="profile-menu-arrow"
      />
    </div>
  </div>
  <div class="switch-wrap">
    <lang-switcher>
    </lang-switcher>
  </div>
  <div
    class="profile-menu-item"
    (click)="openFeedback()"
  >
    <div class="profile-menu-item__icon">
      <img src="assets/img/svg/feedback-icon.svg" />
    </div>
    <div class="profile-menu-item__name">{{ 'Обратная связь' | transloco }}</div>
  </div>
  <div class="profile-menu-item">
    <a
    href="{{ environment.authUrl }}/auth/changepassword?returnUrl={{ environment.appUrl }}/profile&ui_locales={{lang}}">
    {{ 'Смена пароля' | transloco }}</a>
  </div>

  <div
    class="profile-menu-item"
    (click)="logout()"
  >
    <div class="profile-menu-item__icon">
      <img src="assets/img/svg/logout-icon.svg" />
    </div>
    <div class="profile-menu-item__name">{{ 'Выйти' | transloco }}</div>
  </div>
</div>