import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'ezteach-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NotificationItemComponent {
  @Input() color: 'transparent' | 'red' | 'green' = 'transparent';
  @Input() title: string;
  @Input() subtitle: string;
  @Input() needUserAction: boolean;
  @Output() onClose = new EventEmitter<void>();
  @HostBinding('class.ezteach-notification-item') className = true;
}
