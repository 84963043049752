<div class="form_search">
  <form
    method="get"
    (ngSubmit)="onSubmit()"
  >
    <div class="form_search__header">
      <div class="form_search__title">{{ 'Преподаватель' | transloco }} —</div>
      <div class="form_search__subtitle">{{ 'Всерьёз и надолго' | transloco }}</div>
      <div class="form_search__icon">
        <img src="/assets/img/content/icon_search_regular.svg" />
      </div>
    </div>
    <div class="form_search__content">
      <div class="form_search__line">
        <div class="form_search__col">
          <mat-form-field>
            <input
              type="text"
              name="discipline"
              [value]="discipline | transloco"
              [(ngModel)]="discipline"
              [placeholder]="'Предмет' | transloco"
              matInput
              [formControl]="disciplinesControl"
              [matAutocomplete]="auto"
              (change)="onDisciplineChange()"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="onDisciplineChange()"
            >
              <mat-option
                *ngFor="let discipline of disciplinesFiltered | async"
                [value]="discipline | transloco"
              >
                {{ discipline | transloco}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="form_search__col">
          <mat-form-field>
            <mat-label>{{ 'Любая цель' | transloco }}</mat-label>
            <mat-select
              name="specialization"
              [(value)]="specialization"
              [(ngModel)]="specialization"
              [formControl]="specializationsControl"
            >
              <mat-option>{{ specDefault | transloco}}</mat-option>
              <mat-option
                *ngFor="let specialization of specializationsNames | filterSpecializationNames"
                [value]="specialization.id"
              >
                {{ specialization.name | transloco}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form_search__line">
        <div class="form_search__col">
          <mat-form-field>
            <input
              type="text"
              name="name"
              [value]="name"
              [(ngModel)]="name"
              [placeholder]="'Имя преподавателя' | transloco"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="form_search__col full"></div>
        <div class="form_search__col form_search__button">
          <button
            type="submit"
            class="btn btn--red"
            (click)="onSubmit()"
          >
            <span class="btn__label">{{ 'Найти' | transloco }}</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
