import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedBlockService {
  public readonly selectedBlock$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  public readonly refreshBlock$: Subject<number> = new Subject();

  constructor() {}

  getBlock(id: number) {
    return {
      id: 26812,
      iconSrc: 'https://collegerealitycheck.com/wp-content/uploads/physics-major-1988419205-1024x768.jpg',
      name: 'Блок занятий №2 Тема: Физика 11 класс',
      count: 18,
      description: 'Учебный онлайн курс по предмету',
      author: 'Курчатов Игорь Васильевич',
    };
  }

  getBlockLessons(id: number) {
    return [
      {
        id: 1,
        oreder: 1,
        lessonFinishDate: '2022-11-13T17:27:56.278646+00:00',
        lessonStartDate: '2022-11-13T17:27:02.988147+00:00',
        subject: 'Кинематика',
        historyLink: 'https://450203.selcdn.ru/test.media.sessions/processed/TEST-01/5421.mp4',
      },
      {
        id: 2,
        oreder: 2,
        lessonFinishDate: '2022-10-14T17:27:56.278646+00:00',
        lessonStartDate: '2022-11-14T17:27:02.988147+00:00',
        subject: 'Закон Ома',
        historyLink: 'https://450203.selcdn.ru/test.media.sessions/processed/1/oma.mp4',
      },
      {
        id: 3,
        oreder: 3,
        lessonFinishDate: '2022-11-15T17:27:56.278646+00:00',
        lessonStartDate: '2022-11-15T17:27:02.988147+00:00',
        subject: 'Примеры и разборы задач',
        historyLink: 'https://450203.selcdn.ru/test.media.sessions/processed/2/raz.mp4',
      },
      {
        id: 4,
        oreder: 4,
        lessonFinishDate: '2022-11-19T17:27:56.278646+00:00',
        lessonStartDate: '2022-11-19T17:27:02.988147+00:00',
        subject: 'Сила гравитации',
        historyLink: 'https://450203.selcdn.ru/test.media.sessions/processed/3/planeta.mp4',
      },
    ];
  }

  getBlockLesson(id: number) {
    return this.getBlockLessons(1).find(x => x.id === id);
  }
}
