<div class="container">
  <mat-horizontal-stepper
    linear
    #stepper
    (selectionChange)="updateStepperState($event)"
    [class.hidden]="lessonSelected"
  >
    <mat-step
      i18n-label
      label="Предмет"
      [completed]="disciplineSelected"
    >
      <mat-form-field appearance="outline">
        <mat-select
          [(ngModel)]="selectedDiscipline"
          name="discipline"
          i18n-placeholder
          [placeholder]="'Выберите предмет' | transloco"
          (selectionChange)="chooseDiscipline($event)"
        >
          <mat-option
            *ngFor="let discipline of disciplines"
            [value]="discipline.id"
          >
            {{ discipline.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button
          mat-button
          matStepperNext
          [disabled]="!disciplineSelected"
          [class]="{ button: disciplineSelected }"
        >
          {{ 'Следующий шаг' | transloco }}
        </button>
      </div>
    </mat-step>

    <mat-step
      i18n-label
      label="Класс"
      [completed]="classSelected"
    >
      <mat-form-field appearance="outline">
        <mat-select
          i18n-placeholder
          [(ngModel)]="selectedClass"
          name="class"
          placeholder="Выберите класс"
          (selectionChange)="chooseClass($event)"
        >
          <mat-option
            *ngFor="let class of filteredClasses"
            [value]="class.id"
          >
            {{ class.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <button
          mat-button
          matStepperPrevious
          class="button"
        >
          {{ 'К предыдущему шагу' | transloco }}
        </button>
        <button
          mat-button
          matStepperNext
          [disabled]="!classSelected"
          [class]="{ button: classSelected }"
        >
          {{ 'Следующий шаг' | transloco }}
        </button>
      </div>
    </mat-step>

    <mat-step
      label="Список"
      [completed]="lessonSelected"
    >
      <button
        mat-button
        matStepperPrevious
        class="button"
      >
        {{ 'К предыдущему шагу' | transloco }}
      </button>
      <mat-selection-list
        [multiple]="false"
        (selectionChange)="selectLesson($event)"
        [(ngModel)]="selectedVideo"
      >
        <mat-list-option
          *ngFor="let video of filteredVideos"
          [value]="video.id"
        >
          {{ video.title }}
        </mat-list-option>
      </mat-selection-list>
    </mat-step>
  </mat-horizontal-stepper>

  <div
    *ngIf="lessonSelected"
    class="video-wrapper"
  >
    <button
      mat-button
      class="button video-button"
      (click)="changeStepperVisibility()"
    >
      {{ 'Вернуться к выбору занятия' | transloco }}
    </button>
    <div class="video-block">
      <video
        controls
        class="video"
        src="{{ selectedLink }}"
      ></video>
    </div>
  </div>
</div>
