const encodeAsUTF8 = (str: string): string => {
  const encoder = new TextEncoder();

  const utf8 = encoder.encode(str);

  var binaryString = '';
  for (let b = 0; b < utf8.length; ++b) {
    binaryString += String.fromCharCode(utf8[b]);
  }

  return binaryString;
};

const decodeUTF8 = (binary: string): string => {
  const bytes = new Uint8Array(binary.length);
  for (let b = 0; b < bytes.length; ++b) {
    bytes[b] = binary.charCodeAt(b);
  }

  const decoder = new TextDecoder('utf-8');

  return decoder.decode(bytes);
};

export const isBase64 = (str: string): boolean => {
  try {
    return encodeToBase64(decodeFromBase64(str)) === str;
  } catch (e) {
    return false;
  }
};


export const encodeToBase64 = (str: string): string => btoa(encodeAsUTF8(str));

export const decodeFromBase64 = (base64string: string): string => decodeUTF8(atob(base64string));
