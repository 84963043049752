<div class="container create-block" [formGroup]="form">
  <div class="container__title">
    <mat-form-field
      appearance="outline"
      class="float-field"
    >
      <mat-label>{{ 'Тема' | transloco }}</mat-label>
      <input
        tabindex="-1"
        class="ez-input"
        matInput
        type="text"
        formControlName="name"
        maxlength="50"
      >
      <mat-error *ngIf="form.controls['name'].invalid">Поле обязательно для заполнения</mat-error>
    </mat-form-field>
  </div>
  <div class="container__description">
    <mat-form-field
      appearance="outline"
      class="text-area float-field"
    >
      <mat-label>{{ 'Описание' | transloco }}</mat-label>
      <textarea
        tabindex="-1"
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        formControlName="description"
      ></textarea>
      <mat-error *ngIf="form.controls['description'].invalid">Поле обязательно для заполнения</mat-error>
    </mat-form-field>
  </div>
  <input
    #image
    class="hidden"
    type="file"
    accept="image/*"
    (change)="addPreviewImage($event)"
  />
  <ezteach-btn (click)="image.click()" [width]="'100%'">
    <div
      content
      class="add-image"
    >
      <img
        class="create-button__icon"
        src="assets/img/svg/icon-add.svg"
        i18n-alt
        alt="Создать занятие"
      />
      {{ 'Добавить обложку' | transloco}}
    </div>
  </ezteach-btn>
  <div
    class="container__img"
    *ngIf="previewImage"
  >
    <img
      [src]="imageSrc"
      alt=""
    >
  </div>
  <div class="container__participants">
    <app-select-autocomplete
      [displayWith]="displayWithUser"
      [label]="'Участники' | transloco"
      [suffix]="false"
    >
    </app-select-autocomplete>
  </div>
  <div class="container__lessons">
    <mat-select
      (selectionChange)="checkLesson($event)"
      class="select-custom"
      [placeholder]="'Занятия' | transloco"
      [value]="selectedLessonIds"
      multiple
    >
      <mat-option
        *ngFor="let lesson of lessons"
        [value]="lesson.id"
      >
        {{ lesson.name | transloco}}

      </mat-option>

    </mat-select>
  </div>
  <div class="show-search">

    <div class="show-search__label"><span>{{ 'Показывать в поиске' | transloco }} </span>
      <span
        class="info-icon-block"
        #tooltip="matTooltip"
        (click)="tooltip.toggle()"
        matTooltipPosition="above"
        [matTooltip]="showOnSearchTooltip | transloco"
        matTooltipClass="tooltip"
        [matTooltipPosition]="tooltipPosition"
      >
        <img src="assets/img/svg/notification_icon.svg" />
      </span>
    </div>


    <ezteach-custom-toggle
      [checked]="isShowSearch"
      (toggled)="toggleShowSearch()"
    ></ezteach-custom-toggle>
  </div>

</div>
