<div
  class="online"
  ezteachTutorialStep
  [tutorialElementName]="$any('online-widget')"
  *ngIf="isMobile; else desktopOnlineBar"
>
  <div class="online__left">
    <div class="online__title">{{ 'Готовы провести занятие прямо сейчас' | transloco }}?</div>
    <div class="online__text">{{ 'Переведите переключатель' | transloco }} <br />
      {{ 'в положение Онлайн' | transloco }} </div>
  </div>
  <div class="online__right">
    <div
      class="online__checker"
      [class]="{ isOnline: isAvailable }"
    >
      <div class="online__checker_title on">{{ 'Я онлайн' | transloco }}</div>
      <div class="online__checker_title off">{{ 'Я оффлайн' | transloco }}</div>
      <ezteach-custom-toggle
        [checked]="isAvailable"
        (toggled)="updateIsAvailableState($event)"
      ></ezteach-custom-toggle>
    </div>
    <div class="online__info">
      <div class="online__line">
        {{ 'Автоматически выключится через' | transloco }} —
        <span
          class="timer"
          *ngIf="isAvailable"
          >{{ countdown$ | async | duration }}
        </span>
        <mat-select [formControl]="minutesControl">
          <mat-option
            *ngFor="let item of turnOffList"
            [value]="item.value"
          >
            {{ item.name | transloco }}
          </mat-option>
        </mat-select>
      </div>

      <div
        class="online__line"
        *ngIf="durationGroup"
      >
        <div class="online__line_title">{{ 'Только занятия продолжительностью' | transloco }} :</div>
        <mat-checkbox
          [value]="duration.value"
          [formControl]="duration"
          *ngFor="let duration of durationGroup.controls.durations['controls']; let i = index"
        >
          {{ durationList[i].name | transloco }}
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

<ng-template #desktopOnlineBar>
  <div
    class="online-desktop"
    ezteachTutorialStep
    [tutorialElementName]="$any('online-widget')"
  >
    <span
      class="timer-desktop"
      *ngIf="isAvailable"
      >{{ countdown$ | async | duration }}
    </span>
    <div
      class="checker__title-desktop"
      i18n
    >
      {{ (isAvailable ? 'Я онлайн' : 'Я оффлайн') | transloco }}
    </div>
    <div
      class="online__checker-desktop"
      [class]="{ isOnline: isAvailable }"
    >
      <ezteach-custom-toggle
        [checked]="isAvailable"
        (toggled)="updateIsAvailableState($event)"
      ></ezteach-custom-toggle>
    </div>
    <img
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      class="icon-settings"
      src="assets/img/svg/icon-online__setting.svg"
      alt="online-settings"
      (click)="openSettings()"
    />
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
    (backdropClick)="closeSettings()"
  >
    <div class="popup__block">
      <div class="row">
        <h2 class="popup__title">{{ 'Настройки' | transloco }}</h2>
        <img
          (click)="closeSettings()"
          class="icon-close"
          src="assets/img/svg/icon-close_grey-thin.svg"
          alt="close icon"
        />
      </div>
      <div class="row">
        <p class="popup__text">{{ 'Выключение через' | transloco }}:</p>
        <mat-form-field>
          <mat-select [formControl]="minutesControl">
            <mat-option
              *ngFor="let item of turnOffList"
              [value]="item.value"
            >
              {{ item.name | transloco }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row row-duration">
        <p class="popup__text">{{ 'Только занятия продолжительностью' | transloco }}:</p>
        <div
          class="online__line online__line-popup"
          *ngIf="durationGroup"
        >
          <mat-checkbox
            [value]="duration.value"
            [formControl]="duration"
            *ngFor="let duration of durationGroup.controls.durations['controls']; let i = index"
          >
            {{ durationList[i].name | transloco }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
