<!-- Старая версия для мобилы -->
<div>
  <div class="toolbar-videocall_popup">
    <div
      class="call-link"
      *ngIf="lessonLink"
    >
      <shorten-link
        [height]="'32px'"
        [link]="lessonLink"
      ></shorten-link>
    </div>
    <div
      class="call-link guest-link"
      *ngIf="inviteLink"
    >
      <shorten-link
        prefix="Инвайт:"
        [link]="inviteLink"
      ></shorten-link>
    </div>
    <div
      class="toolbar-videocall-access"
      (click)="muteAllToggle()"
      *ngIf="isOwner && !isSpeech"
    >
      <img
        *ngIf="!isAudioEnabled()"
        src="assets/img/svg/group-lesson-next/mic-off.svg"
      />
      <img
        *ngIf="isAudioEnabled()"
        src="assets/img/svg/group-lesson-next/mic-on.svg"
      />
      <span>Включить/отключить аудио у всех кроме меня</span>
    </div>
    <div
      class="toolbar-videocall-access"
      *ngIf="isOwner && !isSpeech"
      (click)="onVideoChangedClick($event)"
    >
      <img
        *ngIf="isVideoEnabled()"
        src="assets/img/svg/group-lesson-next/cam-on.svg"
      />
      <img
        *ngIf="!isVideoEnabled()"
        src="assets/img/svg/group-lesson-next/cam-off.svg"
      />
      <span>Включить/отключить видео у всех кроме меня</span>
    </div>
    <div class="buttons">
      <div
        class="toolbar-btn toolbar-btn-change-camera"
        (click)="switchCamera()"
        *ngIf="canVideo && (!isSpeech || isOwner)"
      >
        <img src="assets/img/svg/group-lesson-next/toolbar-change-camera.svg" />
      </div>
      <div
        class="toolbar-btn toolbar-btn-settings"
        *ngIf="isMobile"
        (click)="openSettings()"
      >
        <img src="assets/img/svg/group-lesson-next/toolbar-setting.svg" />
      </div>
      <div
        class="toolbar-btn toolbar-btn-hand"
        [class.isActive]="groupLessonReactionService.currentUserHandState$.value"
        (click)="handToggle()"
      >
        <img
          *ngIf="!groupLessonReactionService.currentUserHandState$.value"
          src="assets/img/svg/group-lesson-next/toolbar-btn-hand.svg"
        />
        <img
          *ngIf="groupLessonReactionService.currentUserHandState$.value"
          src="assets/img/svg/group-lesson-next/toolbar-btn-hand-active.svg"
        />
      </div>
    </div>
  </div>
</div>