import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  template: `
    <ezteach-tutorial-tooltip-wrapper stepProgressText="1.1 из 3">
      <h1 class="ezteach-tutorial-tooltip__heading">Календарь</h1>
      <p class="ezteach-tutorial-tooltip__info">
        В календаре вы можете настроить свободные слоты для занятий, чтобы ученик мог отправить запрос на занятие.
      </p>
    </ezteach-tutorial-tooltip-wrapper>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step1_1TooltipComponent {}
